import React from 'react';
import { useTranslation } from 'react-i18next';
import TranslationHtml from '../../TranslationHtml/TranslationHtml';
import { Button } from '../../FormComponents/Buttons/Button';
import { BaseModal } from '../BaseModal/BaseModal';
import { UserTypes } from '../../../types/ProjectUser';

import './AcceptTermsModal.scss';

type Props = {
  isOpen: boolean;
  handleCloseModal: () => void;
  userType: UserTypes;
};

export const AcceptTermsModal = (props: Props) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      isOpen={props.isOpen}
      handleCloseModal={props.handleCloseModal}
      ariaHideApp={true}
      title="project-user.terms.title"
    >
      <div className="project-user-welcome">
        {props.userType === UserTypes.CAPTIONER ? (
          <p>{t('project-user.terms.captioner')}</p>
        ) : props.userType === UserTypes.SIGNER ? (
          <p> {t('project-user.terms.signer')}</p>
        ) : null}
        <TranslationHtml htmlString={t('project-user.terms.support-page')} />
      </div>
      <br /> <br />
      <p className="terms-text">{t('project-user.terms.terms')}</p>
      <Button
        translationKey="button.continue"
        variant="primary"
        color="green"
        onClick={props.handleCloseModal}
        type="button"
      />
    </BaseModal>
  );
};
