import React, { useEffect, useState } from 'react';

import './ProjectHeaderInfo.scss';

import { useTranslation } from 'react-i18next';
import Row from '../../Layout/Row/Row';
import {
  getDateDifferenceFromNow,
  getFriendlyDuration,
  getTimezoneName,
} from '../../../helpers/dateFormatHelper/dateFormatHelper';
import moment from 'moment';
import { ProjectDetail } from '../../../types/ProjectDetail';

type ProjectHeaderInfoProps = {
  projectDetails: ProjectDetail | null;
};

const ProjectHeaderInfo = ({ projectDetails }: ProjectHeaderInfoProps) => {
  const [friendlyStartsDuration, setFriendlyStartsDuration] = useState<string>('');
  const { t } = useTranslation();
  const friendlyDuration = getFriendlyDuration(projectDetails?.duration ?? 0);

  useEffect(() => {
    const updateFriendlyStartsDuration = () => {
      setFriendlyStartsDuration(
        getFriendlyDuration(
          moment.duration(getDateDifferenceFromNow(projectDetails?.startDateTimeUtc)).asMinutes(),
        ),
      );
    };
    const interval = setInterval(() => updateFriendlyStartsDuration(), 30000);
    updateFriendlyStartsDuration();
    return () => {
      clearInterval(interval);
    };
  }, [projectDetails]);

  return (
    <div className="project-header-info">
      <div className="project-header-info-block">
        <Row
          left={t('project.time-zone')}
          right={getTimezoneName(
            projectDetails?.startDateTimeLocal || new Date(),
            projectDetails?.startTimeLocal || '00:00:00',
            projectDetails?.timeZone,
          )}
          columnClass="project-header-info-column"
          leftClass="project-header-info-row-title"
          rightClass="project-header-info-row-content"
        />
        <Row
          left={t('project.start-time')}
          right={friendlyStartsDuration}
          columnClass="project-header-info-column"
          leftClass="project-header-info-row-title"
          rightClass="project-header-info-row-content"
        />
        <Row
          left={t('project.duration')}
          right={friendlyDuration}
          columnClass="project-header-info-column"
          leftClass="project-header-info-row-title"
          rightClass="project-header-info-row-content"
        />
      </div>
    </div>
  );
};

export default ProjectHeaderInfo;
