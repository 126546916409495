import React, { useState } from 'react';
import './CameraSelection.scss';
import { Dropdown } from '../../FormComponents/Dropdown/Dropdown';
import { Option } from '../../../common/interfaces';
import { BaseModal } from '../../Modals/BaseModal/BaseModal';
import { useTranslation } from 'react-i18next';
import { Button } from '../../FormComponents/Buttons/Button';

type CameraSelectionProps = {
  videoStreamsList: Option[];
  onChangeMedia: (data: string) => void;
  isOpen: boolean;
  handleCloseModal: () => void;
  errorInitCamera: boolean;
};

const CameraSelection = (props: CameraSelectionProps) => {
  const { t } = useTranslation();
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');

  return (
    <BaseModal
      isOpen={props.isOpen}
      handleCloseModal={props.handleCloseModal}
      ariaHideApp={true}
      title="camera-selection.title"
    >
      <div className="camera-selection-section">
        {props.errorInitCamera ? (
          <div className="camera-init-error-block">
            <p className="camera-init-error-title">{t('camera-selection.error-title')}</p>
            <p className="camera-init-error-instructions">
              {t('camera-selection.error-instructions')}
            </p>
          </div>
        ) : (
          <>
            <Dropdown
              value={selectedDeviceId}
              options={props.videoStreamsList}
              onChange={(data) => setSelectedDeviceId(data?.value)}
              placeholderKey="labels.select-camera"
              errorType={undefined}
              errorMessageKey=""
            />
            <br />
            <Button
              translationKey="camera-selection.ok"
              variant="primary"
              color="blue"
              onClick={() => props.onChangeMedia(selectedDeviceId)}
              type="button"
              additionalClassName="camera-selection-ok"
            />
            <div className="camera-info-block">
              <p>{t('camera-selection.instructions')}</p>
            </div>{' '}
          </>
        )}
      </div>
    </BaseModal>
  );
};

export default CameraSelection;
