import React from 'react';
import Logo from '../../../../images/eyes-white.png';
import history from '../../../../tools/history';
import './AppLogo.scss';

const navigateToHome = () => {
  history.push('/');
};

const AppLogo = () => (
  <div className="logo-container">
    <button className="logo-home-button" onClick={() => navigateToHome()}>
      <img className="logo" src={Logo} alt="AccessLoop Logo" />
    </button>
  </div>
);

export default AppLogo;
