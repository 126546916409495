import Peer, { MediaConnection } from 'peerjs';
import { useState, useEffect, useCallback, useRef } from 'react';
import { videoStreamConfig } from '../common/constants';
import env from '../Env';
import { IceServer } from '../types/IceServer';

export default function usePeer(
  localPeerId: string,
  addRemoteStream: (stream: MediaStream, peerId: string) => void,
  removeRemoteStream: (peerId: string) => void,
  iceServers: IceServer[],
  myConnectionIsLive: boolean,
) {
  const [myPeer, setPeer] = useState<Peer>();
  const [calls, setCalls] = useState<MediaConnection[]>([]);
  const [cameraDeviceId, setCameraDeviceId] = useState<string>();
  const [videoEnabled, setVideoEnabled] = useState<boolean>(!localPeerId.startsWith('Producer'));
  const videoEnabledRef = useRef(!localPeerId.startsWith('Producer'));

  const config = {
    iceServers: iceServers,
  };

  const localConfig = {
    host: env.peerServer,
    secure: true,
    path: '/hub',
    config,
    debug: 0, // from 0 up to 3
  };

  const cleanupCall = useCallback(
    (call: MediaConnection) => {
      removeRemoteStream(call.peer);
      call.close();
    },
    [removeRemoteStream],
  );

  const setupCallHandlers = useCallback(
    (call: MediaConnection) => {
      call.on('stream', (remoteStream) => {
        addRemoteStream(remoteStream, call.peer);
      });

      call.on('close', () => {
        cleanupCall(call);
      });

      call.on('error', () => {
        cleanupCall(call);
      });
    },
    [addRemoteStream, cleanupCall],
  );

  const createFakeStream = (): MediaStream => {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      throw new Error('Cannot create fake canvas stream');
    }

    context.canvas.width = videoStreamConfig.width;
    context.canvas.height = videoStreamConfig.height;

    const loop = () => {
      context.fillText('', 10, 10);
    };

    setTimeout(loop, 1000 / 30);

    return canvas.captureStream();
  };

  const connectPeerServer = () => {
    const peer = myPeer ? myPeer : new Peer(localPeerId, localConfig);

    peer.on('open', () => {
      setPeer(peer);
    });

    peer.on('call', (call) => {
      console.log('PEER CALL -> videoEnabled: ' + videoEnabled);

      if (!videoEnabledRef.current) {
        call.answer(createFakeStream());
        setupCallHandlers(call);
        setCalls((calls) => [...calls, call]);
        return;
      }

      if (cameraDeviceId) {
        const constraints = {
          audio: false,
          video: {
            width: videoStreamConfig.width,
            height: videoStreamConfig.height,
            deviceId: { exact: cameraDeviceId },
          },
        };
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            call.answer(stream);
            setupCallHandlers(call);
            setCalls((calls) => [...calls, call]);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    peer.on('error', (error) => {
      console.log(error);
    });
  };

  const replaceStream = (
    peerConnection: RTCPeerConnection | undefined,
    mediaStream: MediaStream,
  ) => {
    if (!peerConnection) {
      return;
    }

    for (const sender of peerConnection.getSenders()) {
      if (sender.track?.kind === 'audio') {
        if (mediaStream.getAudioTracks().length > 0) {
          sender.replaceTrack(mediaStream.getAudioTracks()[0]);
        }
      }
      if (sender.track?.kind === 'video') {
        if (mediaStream.getVideoTracks().length > 0) {
          sender.replaceTrack(mediaStream.getVideoTracks()[0]);
        }
      }
    }
  };

  const enableVideo = () => {
    setVideoEnabled(true);
    videoEnabledRef.current = true;

    const constraints = {
      audio: false,
      video: {
        width: videoStreamConfig.width,
        height: videoStreamConfig.height,
        deviceId: { exact: cameraDeviceId },
      },
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        calls.forEach((call) => {
          replaceStream(call.peerConnection, stream);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const disableVideo = () => {
    setVideoEnabled(false);
    videoEnabledRef.current = false;

    calls.forEach((call) => {
      replaceStream(call.peerConnection, createFakeStream());
    });
  };

  const removeRemoteCallsToOtherSigners = () => {
    // remove calls to all other signers, do not remove signer proxy.
    calls.forEach((call) => {
      if (!call.peer.startsWith('signerproxy-')) {
        cleanupCall(call);
      }
    });
  };

  const enforceBitrate = async (call: MediaConnection): Promise<boolean> => {
    // More details of WebRTC spec can be found here: https://www.w3.org/TR/webrtc/
    const maxBitrateInBitsPerSecond = 1000000; // For a 1 mbps stream
    let bitRateSetForAllSenders = false;

    // There should only be one video stream, but I have seen multiple during testing, so setting all to be sure
    for (const sender of call.peerConnection?.getSenders()) {
      if (sender && sender.track && sender.track.kind === 'video') {
        const params = sender.getParameters();
        if (!params.encodings) {
          // init encodings for earlier versions of firefox, encodings will exist for all other browsers once connection is established
          params.encodings = [{}];
        }
        bitRateSetForAllSenders = false;
        // if active connection then set maxBitrate, this is to avoid trying to set before connection is active
        if (params.encodings[0].active) {
          params.encodings[0].maxBitrate = maxBitrateInBitsPerSecond;
          try {
            await sender.setParameters(params);
            console.log('BSL video bandwidth success adjusted to 1 mbps');
            bitRateSetForAllSenders = true;
          } catch (err) {
            console.error('error setting BSL video bandwidth:' + err);
          }
        }
      }
    }

    return bitRateSetForAllSenders;
  };

  const enforceBitrateLoop = (call: MediaConnection, isPeerLive: boolean) => {
    if (isPeerLive) {
      return;
    }

    const maxAttempts = 10;
    let attempts = 0;
    let bitRateSetForAllSenders = false;

    const enforceBitrateloop = setInterval(
      async function (call) {
        attempts++;
        bitRateSetForAllSenders = await enforceBitrate(call);

        if (bitRateSetForAllSenders || attempts >= maxAttempts) {
          clearInterval(enforceBitrateloop);
        }
      },
      1000,
      call,
    );
  };

  useEffect(() => {
    connectPeerServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addRemoteStream,
    localPeerId,
    myPeer,
    removeRemoteStream,
    setupCallHandlers,
    cameraDeviceId,
    myConnectionIsLive,
  ]);

  const callPeer = (peerId: string, localStream: MediaStream) => {
    if (!myPeer) {
      return;
    }

    if (calls.find((x) => x.peer === peerId)) {
      replaceStream(calls.find((x) => x.peer === peerId)?.peerConnection, localStream);
      return;
    }

    const call = myPeer.call(peerId, localStream);

    setupCallHandlers(call);

    setCalls((calls) => [...calls, call]);

    enforceBitrateLoop(call, myConnectionIsLive);

    return call;
  };

  return {
    myPeer,
    callPeer,
    cleanupCall,
    removeRemoteCallsToOtherSigners,
    setCameraDeviceId,
    connectPeerServer,
    videoEnabled,
    setVideoEnabled,
    enableVideo,
    disableVideo,
    createFakeStream,
  };
}
