import React from 'react';
import Logo from '../../../images/logo-footer.png';

import './MainSectionComplete.scss';
import { useTranslation } from 'react-i18next';

const MainSectionComplete = () => {
  const { t } = useTranslation();

  return (
    <div className="main-section-complete">
      <div className="main-section-complete__source">
        <div>
          <div className="main-section-complete__message">{t('labels.loop-complete')}</div>
          <img className="main-section-complete__logo" src={Logo} alt="AccessLoop Logo" />
        </div>
      </div>
    </div>
  );
};

export default MainSectionComplete;
