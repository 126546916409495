import React from 'react';
import TitleBar from '../../components/Navbar/TitleBar/TitleBar';
import { SupportRequestForm } from '../../components/SupportRequestForm/SupportRequestForm';

const Help = () => {
  return (
    <div>
      <TitleBar pageTitleTranslationKey={'help.page-title'} />
      <SupportRequestForm />
    </div>
  );
};

export default Help;
