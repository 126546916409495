import { Option } from './interfaces';

export const durationInMinutes: Option[] = [
  {
    text: '15 minutes',
    value: '15',
  },
  {
    text: '30 minutes',
    value: '30',
  },
  {
    text: '45 minutes',
    value: '45',
  },
  {
    text: '1 hour',
    value: '60',
  },
  {
    text: '1.5 hours',
    value: '90',
  },
  {
    text: '2 hours',
    value: '120',
  },
  {
    text: '2.5 hours',
    value: '150',
  },
  {
    text: '3 hours',
    value: '180',
  },
  {
    text: '4 hours',
    value: '240',
  },
  {
    text: '5 hours',
    value: '300',
  },
  {
    text: '6 hours',
    value: '360',
  },
  {
    text: '7 hours',
    value: '420',
  },
  {
    text: '8 hours',
    value: '480',
  },
  {
    text: '9 hours',
    value: '540',
  },
  {
    text: '10 hours',
    value: '600',
  },
  {
    text: '11 hours',
    value: '660',
  },
  {
    text: '12 hours',
    value: '720',
  },
  {
    text: '13 hours',
    value: '780',
  },
  {
    text: '14 hours',
    value: '840',
  },
  {
    text: '15 hours',
    value: '900',
  },
  {
    text: '16 hours',
    value: '960',
  },
  {
    text: '17 hours',
    value: '1020',
  },
  {
    text: '18 hours',
    value: '1080',
  },
  {
    text: '19 hours',
    value: '1140',
  },
  {
    text: '20 hours',
    value: '1200',
  },
  {
    text: '21 hours',
    value: '1260',
  },
  {
    text: '22 hours',
    value: '1320',
  },
  {
    text: '23 hours',
    value: '1380',
  },
  {
    text: '24 hours',
    value: '1440',
  },
];

export const timezones = [
  {
    isDaylightSavings: false,
    baseUtcOffset: '-12:00:00',
    value: 'Dateline Standard Time',
    text: 'International Date Line West (UTC-12:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-11:00:00',
    value: 'UTC-11',
    text: 'Co-ordinated Universal Time-11 (UTC-11:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-10:00:00',
    value: 'Aleutian Standard Time',
    text: 'Aleutian Islands (UTC-10:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-10:00:00',
    value: 'Hawaiian Standard Time',
    text: 'Hawaii (UTC-10:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-09:30:00',
    value: 'Marquesas Standard Time',
    text: 'Marquesas Islands (UTC-09:30)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-09:00:00',
    value: 'Alaskan Standard Time',
    text: 'Alaska (UTC-09:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-09:00:00',
    value: 'UTC-09',
    text: 'Co-ordinated Universal Time-09 (UTC-09:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-08:00:00',
    value: 'Pacific Standard Time (Mexico)',
    text: 'Baja California (UTC-08:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-08:00:00',
    value: 'UTC-08',
    text: 'Co-ordinated Universal Time-08 (UTC-08:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-08:00:00',
    value: 'Pacific Standard Time',
    text: 'Pacific Time (US & Canada) (UTC-08:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-07:00:00',
    value: 'US Mountain Standard Time',
    text: 'Arizona (UTC-07:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-07:00:00',
    value: 'Mountain Standard Time (Mexico)',
    text: 'Chihuahua, La Paz, Mazatlan (UTC-07:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-07:00:00',
    value: 'Mountain Standard Time',
    text: 'Mountain Time (US & Canada) (UTC-07:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-07:00:00',
    value: 'Yukon Standard Time',
    text: 'Yukon (UTC-07:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-06:00:00',
    value: 'Central America Standard Time',
    text: 'Central America (UTC-06:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-06:00:00',
    value: 'Central Standard Time',
    text: 'Central Time (US & Canada) (UTC-06:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-06:00:00',
    value: 'Central Standard Time (Mexico)',
    text: 'Guadalajara, Mexico City, Monterrey (UTC-06:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-06:00:00',
    value: 'Canada Central Standard Time',
    text: 'Saskatchewan (UTC-06:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-05:00:00',
    value: 'SA Pacific Standard Time',
    text: 'Bogota, Lima, Quito, Rio Branco (UTC-05:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-05:00:00',
    value: 'Eastern Standard Time (Mexico)',
    text: 'Chetumal (UTC-05:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-05:00:00',
    value: 'Eastern Standard Time',
    text: 'Eastern Time (US & Canada) (UTC-05:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-05:00:00',
    value: 'Haiti Standard Time',
    text: 'Haiti (UTC-05:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-05:00:00',
    value: 'Cuba Standard Time',
    text: 'Havana (UTC-05:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-05:00:00',
    value: 'US Eastern Standard Time',
    text: 'Indiana (East) (UTC-05:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-05:00:00',
    value: 'Turks And Caicos Standard Time',
    text: 'Turks and Caicos (UTC-05:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-04:00:00',
    value: 'Atlantic Standard Time',
    text: 'Atlantic Time (Canada) (UTC-04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-04:00:00',
    value: 'Venezuela Standard Time',
    text: 'Caracas (UTC-04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-04:00:00',
    value: 'Central Brazilian Standard Time',
    text: 'Cuiaba (UTC-04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-04:00:00',
    value: 'SA Western Standard Time',
    text: 'Georgetown, La Paz, Manaus, San Juan (UTC-04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-03:30:00',
    value: 'Newfoundland Standard Time',
    text: 'Newfoundland (UTC-03:30)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-03:00:00',
    value: 'Tocantins Standard Time',
    text: 'Araguaina (UTC-03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-03:00:00',
    value: 'E. South America Standard Time',
    text: 'Brasilia (UTC-03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-03:00:00',
    value: 'SA Eastern Standard Time',
    text: 'Cayenne, Fortaleza (UTC-03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-03:00:00',
    value: 'Argentina Standard Time',
    text: 'City of Buenos Aires (UTC-03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-03:00:00',
    value: 'Greenland Standard Time',
    text: 'Greenland (UTC-03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-03:00:00',
    value: 'Montevideo Standard Time',
    text: 'Montevideo (UTC-03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-03:00:00',
    value: 'Magallanes Standard Time',
    text: 'Punta Arenas (UTC-03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-03:00:00',
    value: 'Saint Pierre Standard Time',
    text: 'Saint Pierre and Miquelon (UTC-03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-03:00:00',
    value: 'Bahia Standard Time',
    text: 'Salvador (UTC-03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-02:00:00',
    value: 'UTC-02',
    text: 'Co-ordinated Universal Time-02 (UTC-02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-02:00:00',
    value: 'Mid-Atlantic Standard Time',
    text: 'Mid-Atlantic - Old (UTC-02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-01:00:00',
    value: 'Azores Standard Time',
    text: 'Azores (UTC-01:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '-01:00:00',
    value: 'Cape Verde Standard Time',
    text: 'Cabo Verde Is. (UTC-01:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '00:00:00',
    value: 'UTC',
    text: 'Coordinated Universal Time (UTC)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '00:00:00',
    value: 'GMT Standard Time',
    text: 'Dublin, Edinburgh, Lisbon, London (UTC+00:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '00:00:00',
    value: 'Greenwich Standard Time',
    text: 'Monrovia, Reykjavik (UTC+00:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '00:00:00',
    value: 'Sao Tome Standard Time',
    text: 'Sao Tome (UTC+00:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '01:00:00',
    value: 'W. Europe Standard Time',
    text: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (UTC+01:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '01:00:00',
    value: 'Central Europe Standard Time',
    text: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague (UTC+01:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '01:00:00',
    value: 'Romance Standard Time',
    text: 'Brussels, Copenhagen, Madrid, Paris (UTC+01:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '01:00:00',
    value: 'Central European Standard Time',
    text: 'Sarajevo, Skopje, Warsaw, Zagreb (UTC+01:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '01:00:00',
    value: 'W. Central Africa Standard Time',
    text: 'West Central Africa (UTC+01:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'GTB Standard Time',
    text: 'Athens, Bucharest (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'Middle East Standard Time',
    text: 'Beirut (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'Egypt Standard Time',
    text: 'Cairo (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'E. Europe Standard Time',
    text: 'Chisinau (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'Syria Standard Time',
    text: 'Damascus (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'West Bank Standard Time',
    text: 'Gaza, Hebron (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'South Africa Standard Time',
    text: 'Harare, Pretoria (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'FLE Standard Time',
    text: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'Israel Standard Time',
    text: 'Jerusalem (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'South Sudan Standard Time',
    text: 'Juba (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'Kaliningrad Standard Time',
    text: 'Kaliningrad (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'Sudan Standard Time',
    text: 'Khartoum (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'Libya Standard Time',
    text: 'Tripoli (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'Namibia Standard Time',
    text: 'Windhoek (UTC+02:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '02:00:00',
    value: 'Jordan Standard Time',
    text: 'Amman (UTC+03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '03:00:00',
    value: 'Arabic Standard Time',
    text: 'Baghdad (UTC+03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '03:00:00',
    value: 'Turkey Standard Time',
    text: 'Istanbul (UTC+03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '03:00:00',
    value: 'Arab Standard Time',
    text: 'Kuwait, Riyadh (UTC+03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '03:00:00',
    value: 'Belarus Standard Time',
    text: 'Minsk (UTC+03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '03:00:00',
    value: 'Russian Standard Time',
    text: 'Moscow, St Petersburg (UTC+03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '03:00:00',
    value: 'E. Africa Standard Time',
    text: 'Nairobi (UTC+03:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '03:00:00',
    value: 'Volgograd Standard Time',
    text: 'Volgograd (UTC+04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '03:30:00',
    value: 'Iran Standard Time',
    text: 'Tehran (UTC+03:30)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '04:00:00',
    value: 'Arabian Standard Time',
    text: 'Abu Dhabi, Muscat (UTC+04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '04:00:00',
    value: 'Astrakhan Standard Time',
    text: 'Astrakhan, Ulyanovsk (UTC+04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '04:00:00',
    value: 'Azerbaijan Standard Time',
    text: 'Baku (UTC+04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '04:00:00',
    value: 'Russia Time Zone 3',
    text: 'Izhevsk, Samara (UTC+04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '04:00:00',
    value: 'Mauritius Standard Time',
    text: 'Port Louis (UTC+04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '04:00:00',
    value: 'Saratov Standard Time',
    text: 'Saratov (UTC+04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '04:00:00',
    value: 'Georgian Standard Time',
    text: 'Tbilisi (UTC+04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '04:00:00',
    value: 'Caucasus Standard Time',
    text: 'Yerevan (UTC+04:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '04:30:00',
    value: 'Afghanistan Standard Time',
    text: 'Kabul (UTC+04:30)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '05:00:00',
    value: 'West Asia Standard Time',
    text: 'Ashgabat, Tashkent (UTC+05:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '05:00:00',
    value: 'Ekaterinburg Standard Time',
    text: 'Ekaterinburg (UTC+05:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '05:00:00',
    value: 'Pakistan Standard Time',
    text: 'Islamabad, Karachi (UTC+05:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '05:00:00',
    value: 'Qyzylorda Standard Time',
    text: 'Qyzylorda (UTC+05:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '05:30:00',
    value: 'India Standard Time',
    text: 'Chennai, Kolkata, Mumbai, New Delhi (UTC+05:30)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '05:30:00',
    value: 'Sri Lanka Standard Time',
    text: 'Sri Jayawardenepura (UTC+05:30)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '05:45:00',
    value: 'Nepal Standard Time',
    text: 'Kathmandu (UTC+05:45)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '06:00:00',
    value: 'Central Asia Standard Time',
    text: 'Astana (UTC+06:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '06:00:00',
    value: 'Bangladesh Standard Time',
    text: 'Dhaka (UTC+06:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '06:00:00',
    value: 'Omsk Standard Time',
    text: 'Omsk (UTC+06:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '06:30:00',
    value: 'Myanmar Standard Time',
    text: 'Yangon (Rangoon) (UTC+06:30)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '07:00:00',
    value: 'SE Asia Standard Time',
    text: 'Bangkok, Hanoi, Jakarta (UTC+07:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '07:00:00',
    value: 'Altai Standard Time',
    text: 'Barnaul, Gorno-Altaysk (UTC+07:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '07:00:00',
    value: 'W. Mongolia Standard Time',
    text: 'Hovd (UTC+07:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '07:00:00',
    value: 'North Asia Standard Time',
    text: 'Krasnoyarsk (UTC+07:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '07:00:00',
    value: 'N. Central Asia Standard Time',
    text: 'Novosibirsk (UTC+07:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '07:00:00',
    value: 'Tomsk Standard Time',
    text: 'Tomsk (UTC+07:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '08:00:00',
    value: 'China Standard Time',
    text: 'Beijing, Chongqing, Hong Kong SAR, Urumqi (UTC+08:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '08:00:00',
    value: 'North Asia East Standard Time',
    text: 'Irkutsk (UTC+08:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '08:00:00',
    value: 'Singapore Standard Time',
    text: 'Kuala Lumpur, Singapore (UTC+08:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '08:00:00',
    value: 'W. Australia Standard Time',
    text: 'Perth (UTC+08:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '08:00:00',
    value: 'Taipei Standard Time',
    text: 'Taipei (UTC+08:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '08:00:00',
    value: 'Ulaanbaatar Standard Time',
    text: 'Ulaanbaatar (UTC+08:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '08:45:00',
    value: 'Aus Central W. Standard Time',
    text: 'Eucla (UTC+08:45)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '09:00:00',
    value: 'Transbaikal Standard Time',
    text: 'Chita (UTC+09:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '09:00:00',
    value: 'Tokyo Standard Time',
    text: 'Osaka, Sapporo, Tokyo (UTC+09:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '09:00:00',
    value: 'North Korea Standard Time',
    text: 'Pyongyang (UTC+09:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '09:00:00',
    value: 'Korea Standard Time',
    text: 'Seoul (UTC+09:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '09:00:00',
    value: 'Yakutsk Standard Time',
    text: 'Yakutsk (UTC+09:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '09:30:00',
    value: 'AUS Central Standard Time',
    text: 'Darwin (UTC+09:30)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '10:00:00',
    value: 'E. Australia Standard Time',
    text: 'Brisbane (UTC+10:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '10:00:00',
    value: 'West Pacific Standard Time',
    text: 'Guam, Port Moresby (UTC+10:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '10:00:00',
    value: 'Vladivostok Standard Time',
    text: 'Vladivostok (UTC+10:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '11:00:00',
    value: 'Bougainville Standard Time',
    text: 'Bougainville Island (UTC+11:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '11:00:00',
    value: 'Russia Time Zone 10',
    text: 'Chokurdakh (UTC+11:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '11:00:00',
    value: 'Magadan Standard Time',
    text: 'Magadan (UTC+11:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '11:00:00',
    value: 'Sakhalin Standard Time',
    text: 'Sakhalin (UTC+11:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '11:00:00',
    value: 'Central Pacific Standard Time',
    text: 'Solomon Is., New Caledonia (UTC+11:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '12:00:00',
    value: 'Russia Time Zone 11',
    text: 'Anadyr, Petropavlovsk-Kamchatsky (UTC+12:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '12:00:00',
    value: 'UTC+12',
    text: 'Co-ordinated Universal Time+12 (UTC+12:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '12:00:00',
    value: 'Fiji Standard Time',
    text: 'Fiji (UTC+12:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '12:00:00',
    value: 'Kamchatka Standard Time',
    text: 'Petropavlovsk-Kamchatsky - Old (UTC+12:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '13:00:00',
    value: 'UTC+13',
    text: 'Co-ordinated Universal Time+13 (UTC+13:00)',
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '13:00:00',
    value: 'Tonga Standard Time',
    text: "Nuku'alofa (UTC+13:00)",
  },
  {
    isDaylightSavings: false,
    baseUtcOffset: '14:00:00',
    value: 'Line Islands Standard Time',
    text: 'Kiritimati Island (UTC+14:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-09:00:00',
    value: 'Aleutian Summer Time',
    text: ' Aleutian Islands (UTC-09:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-08:00:00',
    value: 'Alaskan Summer Time',
    text: ' Alaska (UTC-08:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-07:00:00',
    value: 'Pacific Summer Time (Mexico)',
    text: ' Baja California (UTC-07:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-07:00:00',
    value: 'Pacific Summer Time',
    text: ' Pacific Time (US & Canada (UTC-07:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-06:00:00',
    value: 'Mountain Summer Time',
    text: ' Mountain Time (US & Canada (UTC-06:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-05:00:00',
    value: 'Central Summer Time',
    text: ' Central Time (US & Canada (UTC-05:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-05:00:00',
    value: 'Easter Island Summer Time',
    text: ' Easter Island (UTC-05:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-04:00:00',
    value: 'Eastern Summer Time',
    text: ' Eastern Time (US & Canada (UTC-04:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-04:00:00',
    value: 'Haiti Summer Time',
    text: ' Haiti (UTC-04:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-04:00:00',
    value: 'Cuba Summer Time',
    text: ' Havana (UTC-04:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-04:00:00',
    value: 'US Eastern Summer Time',
    text: ' Indiana (East (UTC-04:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-04:00:00',
    value: 'Turks And Caicos Summer Time',
    text: ' Turks and Caicos (UTC-04:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-03:00:00',
    value: 'Atlantic Summer Time',
    text: ' Atlantic Time (Canada (UTC-03:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-03:00:00',
    value: 'Pacific SA Summer Time',
    text: ' Santiago (UTC-03:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-02:30:00',
    value: 'Newfoundland Summer Time',
    text: ' Newfoundland (UTC-02:30)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-02:00:00',
    value: 'Greenland Summer Time',
    text: ' Greenland (UTC-02:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-02:00:00',
    value: 'Saint Pierre Summer Time',
    text: ' Saint Pierre and Miquelon (UTC-02:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '-01:00:00',
    value: 'Mid-Atlantic Summer Time',
    text: ' Mid-Atlantic - Old (UTC-01:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '00:00:00',
    value: 'Azores Summer Time',
    text: ' Azores (UTC+00:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '01:00:00',
    value: 'GMT Summer Time',
    text: ' Dublin, Edinburgh, Lisbon, London (UTC+01:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '01:00:00',
    value: 'Morocco Summer Time',
    text: ' Casablanca (UTC+01:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '02:00:00',
    value: 'W. Europe Summer Time',
    text: ' Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (UTC+02:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '02:00:00',
    value: 'Central Europe Summer Time',
    text: ' Belgrade, Bratislava, Budapest, Ljubljana, Prague (UTC+02:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '02:00:00',
    value: 'Romance Summer Time',
    text: ' Brussels, Copenhagen, Madrid, Paris (UTC+02:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '02:00:00',
    value: 'Central European Summer Time',
    text: ' Sarajevo, Skopje, Warsaw, Zagreb (UTC+02:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '03:00:00',
    value: 'GTB Summer Time',
    text: ' Athens, Bucharest (UTC+03:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '03:00:00',
    value: 'Middle East Summer Time',
    text: ' Beirut (UTC+03:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '03:00:00',
    value: 'E. Europe Summer Time',
    text: ' Chisinau (UTC+03:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '03:00:00',
    value: 'Syria Summer Time',
    text: ' Damascus (UTC+03:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '03:00:00',
    value: 'West Bank Summer Time',
    text: ' Gaza, Hebron (UTC+03:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '03:00:00',
    value: 'FLE Summer Time',
    text: ' Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (UTC+03:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '03:00:00',
    value: 'Israel Summer Time',
    text: ' Jerusalem (UTC+03:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '03:00:00',
    value: 'Jordan Summer Time',
    text: ' Amman (UTC+03:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '04:30:00',
    value: 'Iran Summer Time',
    text: ' Tehran (UTC+04:30)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '10:30:00',
    value: 'Cen. Australia Summer Time',
    text: ' Adelaide (UTC+10:30)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '11:00:00',
    value: 'AUS Eastern Summer Time',
    text: ' Canberra, Melbourne, Sydney (UTC+11:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '11:00:00',
    value: 'Tasmania Summer Time',
    text: ' Hobart (UTC+11:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '11:00:00',
    value: 'Lord Howe Summer Time',
    text: ' Lord Howe Island (UTC+11:30)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '12:00:00',
    value: 'Norfolk Summer Time',
    text: ' Norfolk Island (UTC+12:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '13:00:00',
    value: 'New Zealand Summer Time',
    text: ' Auckland, Wellington (UTC+13:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '13:00:00',
    value: 'Kamchatka Summer Time',
    text: ' Petropavlovsk-Kamchatsky - Old (UTC+13:00)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '13:45:00',
    value: 'Chatham Islands Summer Time',
    text: ' Chatham Islands (UTC+13:45)',
  },
  {
    isDaylightSavings: true,
    baseUtcOffset: '14:00:00',
    value: 'Samoa Summer Time',
    text: ' Samoa (UTC+14:00)',
  },
];

export const videoStreamConfig = {
  width: 640,
  height: 480,
};

export const demoCaptionLanguages: Option[] = [
  { text: 'Arabic', value: 'ar' },
  { text: 'Chinese (Simplified)', value: 'zh' },
  { text: 'English', value: 'en' },
  { text: 'French', value: 'fr' },
  { text: 'German', value: 'de' },
  { text: 'Italian', value: 'it' },
  { text: 'Japanese', value: 'ja' },
  { text: 'Portuguese', value: 'pt' },
  { text: 'Spanish', value: 'es' },
  { text: 'Welsh', value: 'cy' },
];

export const speachToTextEngine: Option[] = [
  { text: 'Google', value: 'google' },
  { text: 'Microsoft', value: 'microsoft' },
  { text: 'Facebook', value: 'facebook' },
  { text: 'Amazon', value: 'amazon' },
];

export const fontFamilies: Option[] = [
  { text: 'Verdana', value: 'Verdana' },
  { text: 'Arial', value: 'Arial' },
  { text: 'Tahoma', value: 'Tahoma' },
  { text: 'Times', value: 'Times' },
];

export const regions: Option[] = [
  { text: 'America/Los_Angeles', value: 'REGION_US_WEST_1' },
  { text: 'America/New_York', value: 'REGION_US_EAST_1' },
  { text: 'America/Toronto', value: 'REGION_CA_EAST_1' },
  { text: 'Europe/Berlin', value: 'REGION_EU_CENTRAL_1' },
  { text: 'Europe/London', value: 'REGION_EU_WEST_1' },
];

export const captionLanguages: Option[] = [
  { text: 'Afrikaans', value: 'af' },
  { text: 'Albanian', value: 'sq' },
  { text: 'Amharic', value: 'am' },
  { text: 'Arabic', value: 'ar' },
  { text: 'Armenian', value: 'hy' },
  { text: 'Azerbaijani', value: 'az' },
  { text: 'Basque', value: 'eu' },
  { text: 'Belarusian', value: 'be' },
  { text: 'Bengali', value: 'bn' },
  { text: 'Bosnian', value: 'bs' },
  { text: 'Bulgarian', value: 'bg' },
  { text: 'Catalan', value: 'ca' },
  { text: 'Cebuano', value: 'ceb' },
  { text: 'Chichewa', value: 'ny' },
  { text: 'Chinese (Simplified)', value: 'zh' },
  { text: 'Chinese (Traditional)', value: 'zh-TW' },
  { text: 'Corsican', value: 'co' },
  { text: 'Croatian', value: 'hr' },
  { text: 'Czech', value: 'cs' },
  { text: 'Danish', value: 'da' },
  { text: 'Dutch', value: 'nl' },
  { text: 'English', value: 'en' },
  { text: 'Esperanto', value: 'eo' },
  { text: 'Estonian', value: 'et' },
  { text: 'Filipino', value: 'tl' },
  { text: 'Finnish', value: 'fi' },
  { text: 'French', value: 'fr' },
  { text: 'Frisian', value: 'fy' },
  { text: 'Galician', value: 'gl' },
  { text: 'Georgian', value: 'ka' },
  { text: 'German', value: 'de' },
  { text: 'Greek', value: 'el' },
  { text: 'Gujarati', value: 'gu' },
  { text: 'Haitian Creole', value: 'ht' },
  { text: 'Hausa', value: 'ha' },
  { text: 'Hawaiian', value: 'haw' },
  { text: 'Hebrew', value: 'iw' },
  { text: 'Hindi', value: 'hi' },
  { text: 'Hmong', value: 'hmn' },
  { text: 'Hungarian', value: 'hu' },
  { text: 'Icelandic', value: 'is' },
  { text: 'Igbo', value: 'ig' },
  { text: 'Indonesian', value: 'id' },
  { text: 'Irish', value: 'ga' },
  { text: 'Italian', value: 'it' },
  { text: 'Japanese', value: 'ja' },
  { text: 'Javanese', value: 'jw' },
  { text: 'Kannada', value: 'kn' },
  { text: 'Kazakh', value: 'kk' },
  { text: 'Khmer', value: 'km' },
  { text: 'Kinyarwanda', value: 'rw' },
  { text: 'Korean', value: 'ko' },
  { text: 'Kurdish (Kurmanji)', value: 'ku' },
  { text: 'Kyrgyz', value: 'ky' },
  { text: 'Lao', value: 'lo' },
  { text: 'Latin', value: 'la' },
  { text: 'Latvian', value: 'lv' },
  { text: 'Lithuanian', value: 'lt' },
  { text: 'Luxembourgish', value: 'lb' },
  { text: 'Macedonian', value: 'mk' },
  { text: 'Malagasy', value: 'mg' },
  { text: 'Malay', value: 'ms' },
  { text: 'Malayalam', value: 'ml' },
  { text: 'Maltese', value: 'mt' },
  { text: 'Maori', value: 'mi' },
  { text: 'Marathi', value: 'mr' },
  { text: 'Mongolian', value: 'mn' },
  { text: 'Myanmar (Burmese)', value: 'my' },
  { text: 'Nepali', value: 'ne' },
  { text: 'Norwegian', value: 'no' },
  { text: 'Odia (Oriya)', value: 'or' },
  { text: 'Pashto', value: 'ps' },
  { text: 'Persian', value: 'fa' },
  { text: 'Polish', value: 'pl' },
  { text: 'Portuguese', value: 'pt' },
  { text: 'Punjabi', value: 'pa' },
  { text: 'Romanian', value: 'ro' },
  { text: 'Russian', value: 'ru' },
  { text: 'Samoan', value: 'sm' },
  { text: 'Scots Gaelic', value: 'gd' },
  { text: 'Serbian', value: 'sr' },
  { text: 'Sesotho', value: 'st' },
  { text: 'Shona', value: 'sn' },
  { text: 'Sindhi', value: 'sd' },
  { text: 'Sinhala', value: 'si' },
  { text: 'Slovak', value: 'sk' },
  { text: 'Slovenian', value: 'sl' },
  { text: 'Somali', value: 'so' },
  { text: 'Spanish', value: 'es' },
  { text: 'Sundanese', value: 'su' },
  { text: 'Swahili', value: 'sw' },
  { text: 'Swedish', value: 'sv' },
  { text: 'Tajik', value: 'tg' },
  { text: 'Tamil', value: 'ta' },
  { text: 'Tatar', value: 'tt' },
  { text: 'Telugu', value: 'te' },
  { text: 'Thai', value: 'th' },
  { text: 'Turkish', value: 'tr' },
  { text: 'Turkmen', value: 'tk' },
  { text: 'Ukrainian', value: 'uk' },
  { text: 'Urdu', value: 'ur' },
  { text: 'Uyghur', value: 'ug' },
  { text: 'Uzbek', value: 'uz' },
  { text: 'Vietnamese', value: 'vi' },
  { text: 'Welsh', value: 'cy' },
  { text: 'Xhosa', value: 'xh' },
  { text: 'Yiddish', value: 'yi' },
  { text: 'Yoruba', value: 'yo' },
  { text: 'Zulu', value: 'zu' },
];
