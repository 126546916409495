import { useGet } from 'restful-react';
import { OkResponse } from '../common/interfaces';
import { AccountDetails } from '../types/AccountDetails';

export default function useUserDetails() {
  const { data: user, loading: loadingUser, error: errorUser, refetch } = useGet({
    path: '/accounts/user',
    resolve: (data: OkResponse<AccountDetails>) => data.result,
  });

  return { user, loadingUser, errorUser, refetch };
}
