import React from 'react';
import ProducerContainer from '../../components/ProducerComponents/ProducerContainer/ProducerContainer';

const Producer = () => {
  return (
    <div>
      <ProducerContainer />
    </div>
  );
};

export default Producer;
