import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../SharedControlPanel.scss';
import './StreamDestinationsPanel.scss';
import useProjectDetails from '../../../../../hooks/useProjectDetails';

import { Consumer, ConsumerCreationDto } from '../../../../../types/Consumer';
import TickIcon from '../../../../../images/tick-destination-icon.png';
import { StreamDestinationModal } from '../../../../Modals/StreamDestinationModal/StreamDestinationModal';

type StreamDestinationsPanelProps = {
  consumers: Consumer[] | undefined | null;
  mainLayoutId: string | undefined;
  hasClosedCaptions: boolean | undefined;
  onUpdate: () => void;
};

const StreamDestinationsPanel = ({
  consumers,
  mainLayoutId,
  hasClosedCaptions,
  onUpdate,
}: StreamDestinationsPanelProps) => {
  const { t } = useTranslation();
  const maxNumberOfDestinations = hasClosedCaptions ? 4 : 10;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { projectDetails } = useProjectDetails();
  const [selectedStream, setSelectedStream] = useState<ConsumerCreationDto>({
    id: '',
    ingestKey: '',
    ingestUrl: '',
    layoutId: '',
    languageId:
      projectDetails?.activeLayout?.sourceCaptionLanguage ||
      projectDetails?.activeLayout?.language ||
      'en',
    name: '',
  });

  const openStreamDestinationModal = (
    key = '',
    url = '',
    id = '',
    languageId = projectDetails?.activeLayout?.sourceCaptionLanguage ||
      projectDetails?.activeLayout?.language ||
      'en',
    name = '',
  ) => {
    setSelectedStream({
      id: id,
      ingestKey: key,
      ingestUrl: url,
      layoutId: mainLayoutId || '',
      languageId: languageId,
      name: name,
    });
    setIsModalOpen(true);
  };

  const createAddDestinationButtons = () => {
    const addDestinationButtons = [];
    for (let index = 0; index < maxNumberOfDestinations - (consumers?.length ?? 0); index++) {
      addDestinationButtons.push(
        <button
          key={index}
          className="control-panel-section-box control-panel-section-box-new"
          onClick={() => openStreamDestinationModal()}
        >
          <span className="control-panel-section-box-text">
            + {t('producer.control-panel.stream-destinations.add-new')}
          </span>
        </button>,
      );
    }

    if ((consumers?.length ?? 0) >= maxNumberOfDestinations && !hasClosedCaptions) {
      addDestinationButtons.push(
        <button
          key={consumers?.length ?? 0}
          className="control-panel-section-box control-panel-section-box-new"
          onClick={() => openStreamDestinationModal()}
        >
          <span className="control-panel-section-box-text">
            + {t('producer.control-panel.stream-destinations.add-new')}
          </span>
        </button>,
      );
    }

    return addDestinationButtons;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onUpdate();
  };

  return (
    <div className="stream-source-panel">
      <div className="stream-source-panel-section">
        <div className="stream-source-panel-section-content">
          {consumers &&
            consumers.length > 0 &&
            consumers.map((consumer: Consumer, index: number) => (
              <button
                key={index}
                className="control-panel-section-box"
                onClick={() =>
                  openStreamDestinationModal(
                    consumer?.ingestKey,
                    consumer?.ingestUrl,
                    consumer?.id,
                    consumer?.languageId,
                    consumer?.name,
                  )
                }
              >
                <span className="control-panel-section-box-text">{consumer?.name}</span>
                <img src={TickIcon} alt="tick" className="control-panel-section-box-tick" />
              </button>
            ))}
          {createAddDestinationButtons()}
        </div>
        <div className="stream-source-panel-section-footer">
          <p>
            <span className="captioning-panel-section-footer-title">
              {t('producer.control-panel.captioning.note')}{' '}
            </span>
            <span className="captioning-panel-section-footer-text">
              {t(
                !hasClosedCaptions
                  ? 'producer.control-panel.stream-destinations.note-text'
                  : 'producer.control-panel.stream-destinations.note-text-closed-captions',
              )}
            </span>
          </p>
        </div>
      </div>

      {isModalOpen && (
        <StreamDestinationModal
          isOpen={isModalOpen}
          contentLabel="Stream Destination Modal"
          selectedStream={selectedStream}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
};

export default StreamDestinationsPanel;
