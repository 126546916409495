import React from 'react';
import { Button } from '../../FormComponents/Buttons/Button';
import { BaseModal } from '../BaseModal/BaseModal';

import './PopupModal.scss';

type Props = {
  isOpen: boolean;
  handleCloseModal: () => void;
  title: string;
  content: string;
};

export const PopupModal = (props: Props) => {
  return (
    <BaseModal
      isOpen={props.isOpen}
      handleCloseModal={props.handleCloseModal}
      ariaHideApp={true}
      title={props.title}
    >
      <div className="popup-content" dangerouslySetInnerHTML={{ __html: props.content }}></div>
      <Button
        translationKey="button.close"
        variant="primary"
        color="green"
        onClick={props.handleCloseModal}
        type="button"
      />
    </BaseModal>
  );
};
