import { MutableRefObject, useEffect } from 'react';

function useOutsideClick(
  ref: MutableRefObject<HTMLElement | null>,
  callback: (event: MouseEvent) => void,
) {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const el = ref?.current;

      if (!el || el.contains(event.target as Node)) {
        return;
      }

      callback(event);
    };

    document.addEventListener(`mousedown`, handleClick);

    return () => {
      document.removeEventListener(`mousedown`, handleClick);
    };
  }, [ref, callback]);
}

export default useOutsideClick;
