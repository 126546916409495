import React, { useCallback, useEffect, useState } from 'react';
import '../../ProjectMainSection/ProjectMainSection.scss';
import './SignerMainSection.scss';
import { ProjectDetail } from '../../../types/ProjectDetail';
import MainSectionVideo from '../../ProjectMainSection/MainSectionVideo/MainSectionVideo';
import MainSectionFooter from '../../ProjectMainSection/MainSectionFooter/MainSectionFooter';
import usePeer from '../../../hooks/usePeer';
import useRemoteStreams from '../../../hooks/useRemoteStream';
import { useUserCamera } from '../../../hooks/useUserCamera';
import UserCamera from '../UserCamera/UserCamera';
import { Peer, Peers } from '../../../types/SignalR';
import { UserTypes } from '../../../types/ProjectUser';
import { Option } from '../../../common/interfaces';
import CameraSelection from '../CameraSelection/CameraSelection';
import MainSectionHeader from '../../ProjectMainSection/MainSectionHeader/MainSectionHeader';

type SignerMainSectionProps = {
  projectDetails: ProjectDetail | null;
  signerId: string;
  connectedPeers: Peers;
  sendRequestControlCallback: () => void;
  sendTakeControlCallback: () => void;
  sendGiveControlCallback: () => void;
  getPeerInfoCallback: (peerId: string) => Peer | null;
  peerRequestingControl: Peer | null;
  triggerRefreshLiveVideo: boolean;
  sendStopSignerVideoCallback: () => void;
  sendStartSignerVideoCallback: () => void;
  signerVideoIsStarted: boolean;
  sendReloadProxyVideo: () => void;
  myConnectionIsLive: boolean;
  isAwaitingApiSaveToComplete: boolean;
  isUserActiveCallback: (userId: string) => boolean;
  isUserLiveCallback: (userId: string) => boolean;
};

const SignerMainSection = (props: SignerMainSectionProps) => {
  const {
    mediaStream,
    getStreams,
    setStream,
    availableVideoStreams,
    errorInitCamera,
  } = useUserCamera();
  const { remoteStreams, addRemoteStream, removeRemoteStream } = useRemoteStreams();
  const {
    callPeer,
    removeRemoteCallsToOtherSigners,
    setCameraDeviceId,
    videoEnabled,
    enableVideo,
    disableVideo,
  } = usePeer(
    props.signerId,
    addRemoteStream,
    removeRemoteStream,
    props.projectDetails?.iceServers ?? [],
    props.myConnectionIsLive,
  );
  const [videoStreamsList, setVideoStreamsList] = useState<Option[]>();
  const [selectedVideoStreamId, setSelectedVideoStreamId] = useState<string>();

  const onChangeMedia = (selectedValue: string) => {
    setSelectedVideoStreamId(selectedValue);
    setStream(selectedValue);
  };

  useEffect(() => {
    const streamList = getStreams();
    const streamSelectList: Option[] = streamList.map(function (val) {
      return {
        value: val.deviceId,
        text: val.label,
      };
    });

    setVideoStreamsList(streamSelectList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableVideoStreams]);

  useEffect(() => {
    const call = (remoteId: string) => {
      if (!mediaStream) {
        return;
      }

      callPeer(remoteId, mediaStream);
    };

    for (const peer of props.connectedPeers) {
      console.log(peer);
      if (
        (peer.userType === UserTypes.SIGNER ||
          peer.userType === UserTypes.PRODUCER ||
          peer.peerId.startsWith('signerproxy-')) &&
        peer.peerId !== props.signerId
      ) {
        call(peer.peerId);
      }
    }

    if (props.myConnectionIsLive) {
      props.sendReloadProxyVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.connectedPeers, mediaStream]);

  useEffect(() => {
    // clean up Peer Calls if no other 'signers' are connected.
    // this approach will only works for 2 signers, will need refactoring if we increase the number of signers
    const otherSignersConnected = props.connectedPeers.find(
      (p) =>
        (p.userType === UserTypes.SIGNER || p.userType === UserTypes.PRODUCER) &&
        p.peerId !== props.signerId,
    );
    if (remoteStreams.length > 0 && !otherSignersConnected) {
      // do not close call to proxy, just close calls to other signers.
      removeRemoteCallsToOtherSigners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.connectedPeers]);

  useEffect(() => {
    if (props.myConnectionIsLive) {
      props.sendReloadProxyVideo();
    }
    setCameraDeviceId(selectedVideoStreamId); // update Peer device ID to force connection to peers to update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaStream]);

  const cameraColumnCssClassName = (): string => {
    return `column${remoteStreams.length > 1 ? ' is-4-signers' : ''}`;
  };

  const buildCamera = useCallback(
    (index: number, myCamera = false): unknown => {
      if (myCamera) {
        return (
          <UserCamera
            stream={mediaStream}
            peerId={props.signerId}
            getPeerInfoCallback={props.getPeerInfoCallback}
            userId={props.getPeerInfoCallback(props.signerId)?.userId ?? ''}
            isUserActiveCallback={props.isUserActiveCallback}
            isUserLiveCallback={props.isUserLiveCallback}
            colorIdNumber={0}
            myCamera={true}
            videoEnabled={videoEnabled}
            enableVideo={enableVideo}
            disableVideo={disableVideo}
            isProducer={false}
            sendStopSignerVideoCallback={props.sendStopSignerVideoCallback}
            sendStartSignerVideoCallback={props.sendStartSignerVideoCallback}
          />
        );
      }

      const streams = remoteStreams.filter(
        (x) => props.getPeerInfoCallback(x.peerId)?.userType === UserTypes.SIGNER,
      );

      if (index >= streams.length) {
        return;
      }

      const stream = streams[index];

      const peerInfo = props.getPeerInfoCallback(stream.peerId);

      if (!peerInfo || peerInfo.userType === UserTypes.PRODUCER) {
        return;
      }

      if (stream.stream.active) {
        return (
          <UserCamera
            key={stream.peerId}
            stream={stream.stream}
            peerId={stream.peerId}
            getPeerInfoCallback={props.getPeerInfoCallback}
            userId={props.getPeerInfoCallback(stream.peerId)?.userId ?? ''}
            isUserActiveCallback={props.isUserActiveCallback}
            isUserLiveCallback={props.isUserLiveCallback}
            colorIdNumber={index + 1}
            myCamera={false}
            videoEnabled={videoEnabled}
            enableVideo={enableVideo}
            disableVideo={disableVideo}
            isProducer={false}
          />
        );
      }
    },
    [enableVideo, disableVideo, props, videoEnabled, mediaStream, remoteStreams],
  );

  return (
    <div className="project-main-section">
      <MainSectionHeader {...props} />
      {!selectedVideoStreamId || errorInitCamera ? (
        <CameraSelection
          videoStreamsList={videoStreamsList || []}
          onChangeMedia={onChangeMedia}
          isOpen={true}
          handleCloseModal={() => null}
          errorInitCamera={errorInitCamera}
        />
      ) : null}
      <div className="columns signer-main-section">
        <div className="column">
          <div className="columns">
            <div className={cameraColumnCssClassName()}>
              {buildCamera(-1, true)}
              {buildCamera(1)}
            </div>
            <div className={cameraColumnCssClassName()}>
              {buildCamera(0)}
              {buildCamera(2)}
            </div>
          </div>
        </div>
        <div className="column columns signer-main-section">
          <div className="column main-video-column">
            <MainSectionVideo
              {...props}
              userType={props.getPeerInfoCallback(props.signerId || '')?.userType}
            />
          </div>
        </div>
      </div>
      <MainSectionFooter
        {...props}
        isProducer={false}
        sendRequestControlCallback={props.sendRequestControlCallback}
        sendTakeControlCallback={props.sendTakeControlCallback}
        sendGiveControlCallback={props.sendGiveControlCallback}
        getPeerInfoCallback={props.getPeerInfoCallback}
        peerRequestingControl={props.peerRequestingControl}
        token={props.signerId}
        sendStopSignerVideoCallback={props.sendStopSignerVideoCallback}
        sendStartSignerVideoCallback={props.sendStartSignerVideoCallback}
        signerVideoIsStarted={props.signerVideoIsStarted}
      />
    </div>
  );
};

export default SignerMainSection;
