import React from 'react';
import './TitleBar.scss';
import { useTranslation } from 'react-i18next';
import history from '../../../tools/history';
import BackArrowLarge from '../../../images/back-arrow-large.png';

type PageTitleProps = {
  pageTitleTranslationKey?: string;
};

const TitleBar = ({ pageTitleTranslationKey }: PageTitleProps) => {
  const { t } = useTranslation();

  const navigateToHome = () => {
    history.push('/');
  };

  return (
    <header className="titlebar level">
      <div className="level-left">
        <button className="project-header-back-button" type="button" onClick={navigateToHome}>
          <img src={BackArrowLarge} className="project-header-back-arrow" alt="back" />
        </button>
      </div>
      <h1 className="titlebar-title">
        {pageTitleTranslationKey ? t(pageTitleTranslationKey) : null}
      </h1>
    </header>
  );
};

export default TitleBar;
