import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Message, Messages } from '../../types/Message';
import './Chat.scss';
import { animateScroll } from 'react-scroll';

import SendMesssageIcon from '../../images/sent-mail.png';
import { getFriendlyTime } from '../../helpers/dateFormatHelper/dateFormatHelper';

type ChatProps = {
  chatMessages: Messages;
  userId: string;
  sendMessageToRoomCallback?: (messageToSend: string) => void;
  isChatConnected: boolean;
  isReadOnly?: boolean;
};

const Chat = ({
  chatMessages,
  userId,
  sendMessageToRoomCallback,
  isReadOnly = false,
  isChatConnected,
}: ChatProps) => {
  const { t } = useTranslation();
  const [messageToSend, setMessageToSend] = useState('');

  const sendMessage = () => {
    if (messageToSend.trim().length > 0 && sendMessageToRoomCallback) {
      sendMessageToRoomCallback(messageToSend);
      setMessageToSend('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !(
        e.key === 'Enter' ||
        e.key === 'Delete' ||
        e.key === 'Backspace' ||
        e.key === 'ArrowLeft' ||
        e.key === 'ArrowRight'
      ) &&
      messageToSend.length >= 500
    ) {
      e.preventDefault();
      setMessageToSend(messageToSend.substring(0, 500));
      return;
    }

    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: 'chat-messages',
      duration: 100,
      delay: 5,
      smooth: true,
    });
  };

  useEffect(scrollToBottom, [chatMessages]);

  useEffect(() => {
    setTimeout(function () {
      scrollToBottom();
    }, 300);
  }, []);

  return (
    <div className="chat-container">
      <div className="chat-messages" id="chat-messages">
        {chatMessages?.map((message: Message) => (
          <div
            key={message.id}
            className={`chat-message ${
              message.userId === userId ? 'my-chat-message' : 'user-chat-message'
            }`}
          >
            <div className="chat-message-user">
              {message.userName} - {getFriendlyTime(message.createdUtc)}
            </div>
            <div className="chat-bubble">
              <div className="chat-message-text">{message.messageText}</div>
            </div>
          </div>
        ))}
      </div>
      {!isReadOnly ? (
        <div className="chat-console">
          <input
            className="chat-console-input"
            type="text"
            value={messageToSend}
            placeholder={
              !isChatConnected
                ? t('chat.input-placeholder-disconnected')
                : t('chat.input-placeholder')
            }
            onChange={(e) => setMessageToSend(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={!isChatConnected}
          />
          {isChatConnected ? (
            <button className="chat-console-send" onClick={sendMessage} type={'button'}>
              <img src={SendMesssageIcon} alt="send message" className="chat-console-send-icon" />
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Chat;
