import React from 'react';
import { useTranslation } from 'react-i18next';
import BackArrow from '../../../images/back-arrow-large.png';

import './AccountHeader.scss';

type Props = {
  onClick: () => void;
};

export const AccountHeader = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="header">
      <button className="back-button" onClick={onClick}>
        <img src={BackArrow} alt="Back" className="back-arrow" />
      </button>
      <h1 className="title">{t('account.title')}</h1>
    </div>
  );
};
