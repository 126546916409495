import { TFunctionResult } from 'i18next';
import React from 'react';
import './Row.scss';

type Props = {
  left: string | number | TFunctionResult | null;
  right: string | number | TFunctionResult | false | Element[] | null;
  columnClass?: string;
  leftClass?: string;
  rightClass?: string;
};

const Row = ({ left, right, columnClass, leftClass, rightClass }: Props) => {
  return (
    <div className={`columns layout-row-columns ${columnClass ? columnClass : ''}`}>
      <div
        className={`column layout-row-column layout-row-column-left ${leftClass ? leftClass : ''}`}
      >
        {left}
      </div>
      <div
        className={`column layout-row-column layout-row-column-right ${
          rightClass ? rightClass : ''
        }`}
      >
        {right}
      </div>
    </div>
  );
};

export default Row;
