import React from 'react';

import { useTranslation } from 'react-i18next';
import { Consumers, Consumer } from '../../../../types/Consumer';
import Row from '../../../Layout/Row/Row';
import BaseProjectSummaryCardBlock from './BaseProjectSummaryCardBlock';

import './BaseProjectSummaryCardBlock.scss';

type Props = {
  heading: string;
  sourceUrl: string;
  consumers: Consumers;
};

const ProjectSummaryCardBlockLinks = ({ heading, sourceUrl, consumers }: Props) => {
  const { t } = useTranslation();

  return (
    <BaseProjectSummaryCardBlock heading={heading}>
      <Row
        left={t('project.links.source')}
        right={sourceUrl}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
      <Row
        left={t('project.links.destination')}
        right={
          consumers.length > 0 &&
          consumers.map((consumer: Consumer, index: number) => (
            <p key={index}>{consumer?.ingestUrl}</p>
          ))
        }
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
    </BaseProjectSummaryCardBlock>
  );
};

export default ProjectSummaryCardBlockLinks;
