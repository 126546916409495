import React from 'react';
import { BaseModal } from '../BaseModal/BaseModal';

export const BroadcastedProjectModal = () => {
  return (
    <BaseModal
      isOpen={true}
      ariaHideApp={true}
      handleCloseModal={() => null}
      title="project-user.broadcast-ended.title"
    ></BaseModal>
  );
};
