import { UserType } from './ProjectUser';

export enum SignalREvents {
  JOINED = 'joined',
  USERJOINED = 'user-joined',
  USERLEFT = 'user-left',
  CALLPEER = 'call-peer',
  MESSAGEGROUP = 'message-group',
  GOLIVE = 'go-live',
  REQUESTCONTROL = 'request-control',
  UPDATECONNECTEDPEERS = 'update-connected-peers',
  REFRESHLIVEVIDEO = 'refresh-live-video',
  UPDATEPROJECTDETAILS = 'update-project-details',
  UPDATEISAWAITINGAPISAVETOCOMPLETE = 'update-is-awaiting-api-save-to-complete',
  UPDATEISSIGNERVIDEOSTOPPED = 'update-is-signer-video-stopped',
}

export type Peer = {
  peerId: string;
  userId: string;
  userName: string;
  userType: UserType;
  isCurrentlyLive: boolean;
};

export type Peers = Peer[];
