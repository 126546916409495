import React from 'react';
import './App.scss';

import { Route, Switch } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import env from '../../Env';
import { RestfulProvider } from 'restful-react';
import { useAuth0 } from '@auth0/auth0-react';

import PageNotFound from '../../pages/PageNotFound/PageNotFound';
import ProjectOverview from '../../pages/ProjectOverview/ProjectOverview';
import Producer from '../../pages/Producer/Producer';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';
import Captioner from '../../pages/Captioner/Captioner';
import Signer from '../../pages/Signer/Signer';
import Account from '../../pages/Account/Account';
import history from '../../tools/history';
import Help from '../../pages/Help/Help';
import VerifyEmailMessage from '../VerifyEmailMessage/VerifyEmailMessage';
import Subscribe from '../../pages/Subscribe/Subscribe';

declare global {
  interface Window {
    id: unknown;
    lang: unknown;
    cName: unknown;
    cEmail: unknown;
    cMessage: unknown;
    lcjUrl: unknown;
  }

  interface HTMLCanvasElement {
    captureStream(frameRate?: number): MediaStream;
  }
}

const App = () => {
  const { t } = useTranslation();
  const { getAccessTokenSilently, isAuthenticated, logout, error } = useAuth0();

  const onError = (statuscode: number | undefined) => {
    switch (statuscode) {
      case 402: {
        history.push('/subscribe');
        break;
      }
      default: {
        return (
          <div className="solid-background">
            <ErrorMessage message={t('error-messages.generic-error')} />
          </div>
        );
      }
    }
  };

  if (error) {
    if (error.message === 'Please verify your email before logging in.') {
      return (
        <div className="solid-background">
          <VerifyEmailMessage />;
        </div>
      );
    }
    logout();
  }

  return (
    <RestfulProvider
      base={`${env.baseUrl}/api/`}
      requestOptions={async () => ({
        headers: {
          Authorization: isAuthenticated ? `Bearer ${await getAccessTokenSilently()}` : '',
        },
      })}
      onError={({ status }) => onError(status)}
    >
      <Navbar />

      <div role="main" className="content-holder">
        <Switch>
          <ProtectedRoute path="/" exact component={ProjectOverview} />
          <ProtectedRoute path="/producer/:projectId" exact component={Producer} />
          <Route path="/captioner/:token" exact component={Captioner}></Route>
          <Route path="/signer/:token" exact component={Signer}></Route>
          <ProtectedRoute path="/account" exact component={Account} />
          <ProtectedRoute path="/help" exact component={Help} />
          <ProtectedRoute path="/subscribe" exact component={Subscribe} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>

      <Footer />
    </RestfulProvider>
  );
};

export default App;
