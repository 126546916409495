import React, { useState } from 'react';

import SettingsCog from '../../../images/settings-cog.png';
import { ProjectDetailsModal } from '../../Modals/ProjectDetailsModal/ProjectDetailsModal';
import useProjectDetails from '../../../hooks/useProjectDetails';

import './ProducerSettingsCog.scss';

type ProducerSettingsProps = {
  reloadProjectDetails: () => void;
};

const ProducerSettings = ({ reloadProjectDetails }: ProducerSettingsProps) => {
  const projectDetails = useProjectDetails();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="producer-settings-cog">
      <button onClick={() => setIsModalOpen(true)} className="producer-settings-cog-button">
        <img src={SettingsCog} className="producer-settings-cog-image" alt="settings icon" />
      </button>

      {isModalOpen && (
        <ProjectDetailsModal
          isOpen={isModalOpen}
          contentLabel="Edit Project Details"
          projectDetails={projectDetails.projectDetails}
          handleCloseModal={() => {
            setIsModalOpen(false);
            reloadProjectDetails();
          }}
        />
      )}
    </div>
  );
};

export default ProducerSettings;
