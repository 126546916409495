import React from 'react';
import { useTranslation } from 'react-i18next';
import TranslationHtml from '../TranslationHtml/TranslationHtml';
import Logo from '../../images/logo-footer.png';
import './Footer.scss';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer" role="contentinfo">
      <div className="content has-text-centered">
        <div className="footer__logo-container">
          <img src={Logo} alt="AccessLoop Footer Logo" />
        </div>
        <TranslationHtml htmlString={t('footer.copyright')} />
      </div>
    </footer>
  );
};

export default Footer;
