import React from 'react';
import { Button } from '../FormComponents/Buttons/Button';
import './ErrorMessage.scss';

type ErrorMessageProps = {
  message: string;
  handleErrorClose?: () => void;
};

const ErrorMessage = (props: ErrorMessageProps) => {
  const baseHandleErrorClose = (): void => {
    window.location.reload();
  };

  return (
    <div className="error-message">
      <div className="error-message-container">
        <p className="error-message-text">{props.message}</p>
        <div className="error-message-button-wrapper">
          <Button
            translationKey="button.ok"
            variant="primary"
            color="green"
            onClick={props.handleErrorClose == null ? baseHandleErrorClose : props.handleErrorClose}
            type="button"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
