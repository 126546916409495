import React from 'react';
import { useTranslation } from 'react-i18next';
import './Button.scss';

type Props = {
  translationKey: string;
  variant: 'primary' | 'secondary';
  color: 'blue' | 'pink' | 'green' | 'white' | 'grey' | 'red';
  additionalClassName?: string;
  onClick: () => void;
  type: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
};

export const Button = (props: Props) => {
  const { t } = useTranslation();

  const className = `btn btn-${props.variant} btn-${props.variant}--${props.color} ${
    props.additionalClassName ?? ''
  }`;

  return (
    <button
      className={className}
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
    >
      {t(props.translationKey)}
    </button>
  );
};
