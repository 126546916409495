import { Consumers } from './Consumer';

export type Layout = {
  id: string;
  layoutType: string;
  hasSigner: boolean;
  hasCaption: boolean;
  captionTheme: string;
  captionBackgroundOpacity: number;
  captionFontSize: string;
  captionLinesOfText: number;
  signerPosition: string;
  streamSize: string;
  hasClosedCaptions: boolean;
  sourceCaptionLanguage: string;
  language: string;
  signLanguageHasLogo: boolean;
  hasDemoCaptions: boolean;
  hasAiCaptions: boolean;
  signerSize: string;
  captionFontFamily: string;
  captionStyle: string;
  region: string;
  consumers: Consumers;
};

export type Layouts = Array<Layout>;

export enum LayoutTypes {
  PREVIEW = 'Preview',
  MAIN = 'Main',
}

export type LayoutType = LayoutTypes.PREVIEW | LayoutTypes.MAIN;

export enum SignerPositions {
  BOTTOMRIGHT = 'BottomRight',
  BOTTOMLEFT = 'BottomLeft',
}

export enum StreamSizes {
  FULLSCREEN = 'FullScreen',
  SCALED = 'Scaled',
}

export enum SignerSizes {
  Large = 'Large',
  Small = 'Small',
}

export type StreamSize = StreamSizes.FULLSCREEN | StreamSizes.SCALED;
export type SignerPosition = SignerPositions.BOTTOMRIGHT | SignerPositions.BOTTOMLEFT;

export enum CaptionFontSizes {
  SMALL = 'Small',
  LARGE = 'Large',
}

export enum CaptionFontFamilies {
  Verdana = 'Verdana',
  Arial = 'Arial',
  Tahoma = 'Tahoma',
}

export type CaptionFontSize = CaptionFontSizes.SMALL | CaptionFontSizes.LARGE;

export enum CaptionThemes {
  BLACKBACKGROUNDWHITETEXT = 'BlackBackgroundWhiteText',
  BLACKBACKGROUNDYELLOWTEXT = 'BlackBackgroundYellowText',
}

export type CaptionTheme =
  | CaptionThemes.BLACKBACKGROUNDWHITETEXT
  | CaptionThemes.BLACKBACKGROUNDYELLOWTEXT;

export enum CaptionLinesOfText {
  TWO = 2,
  THREE = 3,
}

export enum CaptionBackgroundOpacity {
  SOLID = 1,
  QUARTERTRANSPARENT = 2,
  SEMITRANSPARENT = 3,
}

export enum CaptionStyle {
  DOCKED = 'Docked',
  UNDOCKED = 'Undocked',
  USER = 'User',
}

export type Asset = {
  assetType: string;
  assetUrl: string;
};
