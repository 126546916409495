import { ConsoleLogType } from './consoleLogType';

export class ConsoleLog {
  public log: string;
  public logType: ConsoleLogType;
  public constructor(log: string, logType: ConsoleLogType) {
    this.log = log;
    this.logType = logType;
  }
}
