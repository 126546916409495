import { loadStripe } from '@stripe/stripe-js';
import { useMutate } from 'restful-react';
import env from '../Env';

const stripePromise = loadStripe(env.stripePublicKey);

function useStripeCheckout() {
  const onCreateSessionSuccess = async (body: { stripePriceId: string }, sessionId: string) => {
    const stripe = await stripePromise;

    await stripe?.redirectToCheckout({ sessionId });
  };

  const onUpdateSubscriptionSuccess = async (
    body: { stripePriceId: string; selectedSubscriptionId: string },
    sessionId: string,
  ) => {
    if (sessionId !== null) {
      window.location.reload();
    }
  };

  const { mutate: createSession, loading: loadingSession, error: errorSession } = useMutate({
    verb: 'POST',
    path: '/stripe/create-checkout-session',
    onMutate: onCreateSessionSuccess,
  });

  const {
    mutate: updateSubscription,
    loading: loadingUpdateSubscription,
    error: errorUpdateSubscription,
  } = useMutate({
    verb: 'POST',
    path: '/stripe/update-subscription',
    onMutate: onUpdateSubscriptionSuccess,
  });

  return {
    createSession,
    loadingSession,
    errorSession,
    updateSubscription,
    loadingUpdateSubscription,
    errorUpdateSubscription,
  };
}

export default useStripeCheckout;
