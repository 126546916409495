import _ from 'lodash';
import { ProjectDetail } from '../../types/ProjectDetail';

export const updateProjectDetailsFromSignalRData = (
  currentProjectDetails: ProjectDetail | undefined,
  projectDetailsFromSignalR: ProjectDetail,
): ProjectDetail | undefined => {
  if (!currentProjectDetails) {
    return currentProjectDetails;
  }

  const newProjectDetails = _.cloneDeep(currentProjectDetails);
  if (newProjectDetails) {
    newProjectDetails.activeLayout = projectDetailsFromSignalR.activeLayout;
    newProjectDetails.duration = projectDetailsFromSignalR.duration;
    newProjectDetails.name = projectDetailsFromSignalR.name;
    newProjectDetails.projectUsers = projectDetailsFromSignalR.projectUsers;
    newProjectDetails.startDateTimeLocal = projectDetailsFromSignalR.startDateTimeLocal;
    newProjectDetails.startDateTimeUtc = projectDetailsFromSignalR.startDateTimeUtc;
    newProjectDetails.startTimeLocal = projectDetailsFromSignalR.startTimeLocal;
    newProjectDetails.startTimeUtc = projectDetailsFromSignalR.startTimeUtc;
    newProjectDetails.timeZone = projectDetailsFromSignalR.timeZone;
    newProjectDetails.deletedOnUtc = projectDetailsFromSignalR.deletedOnUtc;
    newProjectDetails.broadcastStartedOnUtc = projectDetailsFromSignalR.broadcastStartedOnUtc;
    newProjectDetails.broadcastEndedOnUtc = projectDetailsFromSignalR.broadcastEndedOnUtc;
    newProjectDetails.previewStartedOnUtc = projectDetailsFromSignalR.previewStartedOnUtc;
    newProjectDetails.resolution = projectDetailsFromSignalR.resolution;

    return newProjectDetails;
  }
  return currentProjectDetails;
};
