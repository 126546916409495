export type ProjectUser = {
  id: string;
  userType: string;
  name: string;
  emailAddress: string;
  accessToken: string;
};

export type ProjectUsers = Array<ProjectUser>;

export type ProjectUserCreationDto = {
  id: string;
  userType: string;
  name: string;
  emailAddress: string;
  projectId: string;
  accessToken: string;
};

export enum UserTypes {
  SIGNER = 'Signer',
  CAPTIONER = 'Captioner',
  PRODUCER = 'Producer',
  AIWRITER = 'AiWriter',
  UNKNOWN = 'Unknown',
}

export type UserType =
  | UserTypes.SIGNER
  | UserTypes.CAPTIONER
  | UserTypes.PRODUCER
  | UserTypes.AIWRITER
  | UserTypes.UNKNOWN;
