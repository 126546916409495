import React from 'react';

import { useTranslation } from 'react-i18next';
import { CaptionThemes, Layout } from '../../../../types/Layout';
import Row from '../../../Layout/Row/Row';
import BaseProjectSummaryCardBlock from './BaseProjectSummaryCardBlock';

import './BaseProjectSummaryCardBlock.scss';

type Props = {
  heading: string;
  mainLayoutDetail: Layout | null;
};

const ProjectSummaryCardBlockCaptionStyles = ({ heading, mainLayoutDetail }: Props) => {
  const { t } = useTranslation();

  const getThemeText = (captionTheme: string) => {
    switch (captionTheme) {
      case CaptionThemes.BLACKBACKGROUNDWHITETEXT:
        return t('project-overview.themes.black-background-white-text');
      case CaptionThemes.BLACKBACKGROUNDYELLOWTEXT:
        return t('project-overview.themes.black-background-yellow-text');
      default:
        return '';
    }
  };

  return (
    <BaseProjectSummaryCardBlock heading={heading}>
      <Row
        left={t('project.caption-styles.theme')}
        right={getThemeText(mainLayoutDetail?.captionTheme || '')}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
      <Row
        left={t('project.caption-styles.text-size')}
        right={mainLayoutDetail?.captionFontSize}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
      <Row
        left={t('project.caption-styles.text-lines')}
        right={mainLayoutDetail?.captionLinesOfText}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
      <Row
        left={t('project.caption-styles.opacity')}
        right={mainLayoutDetail?.captionBackgroundOpacity}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
    </BaseProjectSummaryCardBlock>
  );
};

export default ProjectSummaryCardBlockCaptionStyles;
