import React from 'react';
import { useTranslation } from 'react-i18next';
import env from '../../../Env';

import './MainSectionStreamTextButton.scss';

type MainSectionStreamTextButtonProps = {
  streamTextEventName?: string | undefined;
};

const MainSectionStreamTextButton = (props: MainSectionStreamTextButtonProps) => {
  const { t } = useTranslation();

  const getStreamtextUrl = () => {
    return `${env.streamTextPlayerUrl}?event=${props.streamTextEventName}`;
  };

  if (!props.streamTextEventName || !env.streamTextPlayerUrl) {
    return null;
  }

  return (
    <div className="streamtext-caption-button">
      <a
        className="btn btn-primary btn-primary--pink"
        href={getStreamtextUrl()}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('project-user.captioner.streamtext-button')}
      </a>
    </div>
  );
};

export default MainSectionStreamTextButton;
