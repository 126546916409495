import React from 'react';

import { useTranslation } from 'react-i18next';
import { ProjectDetail } from '../../../../types/ProjectDetail';
import {
  getFriendlyDate,
  getFriendlyScheduledDuration,
  getFriendlyElapsedDuration,
  getTimezoneName,
} from '../../../../helpers/dateFormatHelper/dateFormatHelper';
import Row from '../../../Layout/Row/Row';
import BaseProjectSummaryCardBlock from './BaseProjectSummaryCardBlock';

import './BaseProjectSummaryCardBlock.scss';

type Props = {
  heading: string;
  projectDetail: ProjectDetail;
};

const ProjectSummaryCardBlockGeneral = ({ heading, projectDetail }: Props) => {
  const { t } = useTranslation();
  const friendlyCreatedDate = getFriendlyDate(projectDetail?.createdDateLocal);
  const friendlyStartTime = getFriendlyDate(projectDetail?.startDateTimeLocal);
  const friendlyDuration = getFriendlyScheduledDuration(projectDetail?.duration ?? 0);
  const friendlyElapsedTime = !projectDetail?.elapsedDuration
    ? t('project.not-elapsed')
    : getFriendlyElapsedDuration(projectDetail?.elapsedDuration || '');
  const friendlyStreamedDate = !projectDetail?.elapsedDuration
    ? t('project.not-streamed')
    : getFriendlyDate(projectDetail?.broadcastStartedOnLocal);

  return (
    <BaseProjectSummaryCardBlock heading={heading}>
      <Row
        left={t('project.created-date')}
        right={friendlyCreatedDate}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
      <Row
        left={t('project.scheduled-for')}
        right={friendlyStartTime}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
      <Row
        left={t('project.duration')}
        right={friendlyDuration}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
      <Row
        left={t('project.time-zone')}
        right={getTimezoneName(
          projectDetail?.startDateTimeLocal || new Date(),
          projectDetail?.startTimeLocal || '00:00:00',
          projectDetail?.timeZone,
        )}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
      <Row
        left={t('project.streamed')}
        right={friendlyStreamedDate}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
      <Row
        left={t('project.elapsed-duration')}
        right={friendlyElapsedTime}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
      <Row
        left={t('project.resolution')}
        right={projectDetail?.resolution}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
    </BaseProjectSummaryCardBlock>
  );
};

export default ProjectSummaryCardBlockGeneral;
