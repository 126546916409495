import * as React from 'react';
import DOMPurify from 'dompurify';

interface Props {
  htmlString: string;
  className?: string;
}

const TranslationHtml = ({ htmlString, className = '' }: Props) => {
  return (
    <span
      className={className}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlString, { ADD_ATTR: ['target'] }) }}
    ></span>
  );
};

export default TranslationHtml;
