import React, { useEffect, useState } from 'react';

import './ProjectHeader.scss';

import ProducerSettingsCog from './ProducerSettingsCog/ProducerSettingsCog';
import ProjectHeaderInfo from './ProjectHeaderInfo/ProjectHeaderInfo';
import history from '../../tools/history';

import BackArrowLarge from '../../images/back-arrow-large.png';
import { ProjectDetail } from '../../types/ProjectDetail';
import { getDateDifferenceFromNow } from '../../helpers/dateFormatHelper/dateFormatHelper';
import moment from 'moment';

type ProjectHeaderProps = {
  reloadProjectDetails: () => void;
  projectDetails: ProjectDetail | null;
  isProducer: boolean;
};

const ProjectHeader = (props: ProjectHeaderProps) => {
  const [isReadOnly, setIsReadOnly] = useState<boolean>(
    !!props.projectDetails?.broadcastStartedOnUtc,
  );

  const navigateToHome = () => {
    history.push('/');
  };

  useEffect(() => {
    const isProjectStarted = () => {
      if (
        !!props.projectDetails?.broadcastStartedOnUtc ||
        !!props.projectDetails?.previewStartedOnUtc ||
        props.projectDetails?.deletedOnUtc
      ) {
        setIsReadOnly(true);
      }
    };
    isProjectStarted();
  }, [props.projectDetails]);

  useEffect(() => {
    const checkIfStartDateHasPassed = () => {
      if (
        !isReadOnly &&
        props.projectDetails?.startDateTimeUtc &&
        moment
          .duration(getDateDifferenceFromNow(props.projectDetails?.startDateTimeUtc))
          .asMinutes() < 1
      ) {
        setIsReadOnly(true);
      }
    };
    const interval = setInterval(() => checkIfStartDateHasPassed(), 30000);
    checkIfStartDateHasPassed();
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectDetails]);

  return (
    <header className="project-header level">
      <div className="level-left">
        {props.isProducer && (
          <button className="project-header-back-button" type="button" onClick={navigateToHome}>
            <img src={BackArrowLarge} className="project-header-back-arrow" alt="back" />
          </button>
        )}
      </div>
      <div>
        <h1 className="project-header-title">{props.projectDetails?.name}</h1>
      </div>
      <div className="level-right">
        <ProjectHeaderInfo {...props} />
        {props.isProducer && !isReadOnly && <ProducerSettingsCog {...props} />}
      </div>
    </header>
  );
};

export default ProjectHeader;
