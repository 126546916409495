import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-http-backend';
import metadata from '../metadata.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    backend: {
      loadPath: `/locales/{{lng}}/translation.json?version=${metadata?.build}`,
    },
    load: 'languageOnly',
  });

export default i18n;
