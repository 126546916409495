import React, { createRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Peer } from '../../../types/SignalR';
import ProjectUserElement from '../../ProjectUserElement/ProjectUserElement';
import SilhouetteOverlay from '../../../images/signer-silhouette.png';
import CameraOn from '../../../images/camera-on.png';
import CameraOff from '../../../images/camera-off.png';
import NoCamera from '../../../images/no-camera.png';
import './UserCamera.scss';

interface UserCameraProps extends WithTranslation {
  stream?: MediaStream;
  peerId: string;
  getPeerInfoCallback: (peerId: string) => Peer | null;
  colorIdNumber: number;
  myCamera: boolean;
  userId: string;
  isUserActiveCallback: (userId: string) => boolean;
  isUserLiveCallback: (userId: string) => boolean;
  videoEnabled: boolean | undefined;
  enableVideo: () => void | undefined;
  disableVideo: () => void | undefined;
  isProducer: boolean;
  sendStopSignerVideoCallback?: () => void | undefined;
  sendStartSignerVideoCallback?: () => void | undefined;
}

const UserCameraMemo = React.memo(
  class UserCamera extends React.Component<UserCameraProps> {
    private videoRef: React.RefObject<HTMLVideoElement>;
    private videoControlRef: React.RefObject<HTMLImageElement>;

    constructor(props: UserCameraProps) {
      super(props);
      this.videoRef = createRef();
      this.videoControlRef = createRef();
    }

    private handleCanPlay = () => {
      this.videoRef?.current?.play();
    };

    private cameraClickHandler = () => {
      if (this.props.videoEnabled) {
        if (this.props.sendStopSignerVideoCallback) {
          this.props.sendStopSignerVideoCallback();
        }

        this.props.disableVideo();
      } else {
        if (this.props.sendStartSignerVideoCallback) {
          this.props.sendStartSignerVideoCallback();
        }

        this.props.enableVideo();
      }

      if (!this.videoControlRef || !this.videoControlRef.current) {
        return;
      }

      this.videoControlRef.current.src = this.props.videoEnabled ? CameraOn : CameraOff;
    };

    render() {
      if (!this.props.stream) {
        return (
          <div className="column signer-video-column">
            <div className="signer-video-wrapper">
              <img className="signer-video-silhouette" src={SilhouetteOverlay} alt="Silhouette" />
            </div>
            <div className="signer-user-info-wrapper">
              <ProjectUserElement
                colorId={this.props.colorIdNumber + 1}
                userId={this.props.userId}
                isUserActiveCallback={this.props.isUserActiveCallback}
                isUserLiveCallback={this.props.isUserLiveCallback}
                name={this.props.getPeerInfoCallback(this.props.peerId)?.userName ?? ''}
                description={
                  this.props.myCamera
                    ? this.props.t('labels.your-camera')
                    : this.props.t('labels.remote-camera')
                }
              />
            </div>
          </div>
        );
      }

      return (
        <div className="column signer-video-column">
          <div className="signer-video-wrapper">
            <div key={this.props.peerId}>
              <div
                className={`signer-video-wrapper${
                  this.props.getPeerInfoCallback(this.props.peerId)?.isCurrentlyLive
                    ? ' is-active-signer'
                    : ''
                }${this.props.isProducer ? ' signer-producers' : ''}`}
              >
                {(this.props.videoEnabled || this.props.isProducer || !this.props.myCamera) && (
                  <video
                    className="signer-video-element"
                    id={this.props.peerId ?? 'local'}
                    ref={(video) => {
                      if (!video || !this.props.stream || !this.props.stream.active) {
                        return;
                      }

                      video.srcObject = this.props.stream;
                    }}
                    onCanPlay={this.handleCanPlay}
                    autoPlay
                    playsInline
                    muted
                  />
                )}
                {this.props.myCamera && !this.props.videoEnabled && (
                  <div className="disabled-video">
                    <img src={NoCamera} alt="" />
                  </div>
                )}
              </div>
              <div className="signer-user-info-wrapper">
                <ProjectUserElement
                  colorId={this.props.colorIdNumber + 1}
                  name={this.props.getPeerInfoCallback(this.props.peerId)?.userName ?? ''}
                  userId={this.props.userId}
                  isUserActiveCallback={this.props.isUserActiveCallback}
                  isUserLiveCallback={this.props.isUserLiveCallback}
                  description={
                    this.props.myCamera
                      ? this.props.t('labels.your-camera')
                      : this.props.t('labels.remote-camera')
                  }
                />
                {this.props.myCamera && (
                  <div
                    role="button"
                    tabIndex={-1}
                    className="camera-control"
                    onClick={this.cameraClickHandler}
                    onKeyDown={this.cameraClickHandler}
                  >
                    <img
                      ref={this.videoControlRef}
                      src={this.props.videoEnabled ? CameraOn : CameraOff}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  },
);

export default withTranslation()(UserCameraMemo);
