import React from 'react';
import { useTranslation } from 'react-i18next';
import './VerifyEmailMessage.scss';
import Logo from '../../images/accessloop-logo.png';
import { useAuth0 } from '@auth0/auth0-react';

const VerifyEmailMessage = () => {
  const { t } = useTranslation();

  const { loginWithRedirect } = useAuth0();

  return (
    <div className="verify-email-message">
      <div className="verify-email-message-header">
        <img className="verify-email-message-logo" src={Logo} alt="AccessLoop Logo" />
      </div>
      <div className="verify-email-message-text-wrapper">
        <p className="verify-email-message-text">{t('error-messages.verify-email-line-one')}</p>
        <p className="verify-email-message-text">{t('error-messages.verify-email-line-two')}</p>
        <div className="verify-email-continue-button">
          <button className="btn btn-primary btn-primary--pink" onClick={() => loginWithRedirect()}>
            {t('button.continue')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailMessage;
