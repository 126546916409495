import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGet } from 'restful-react';
import { ConsoleLog } from '../common/consoleLog';
import { ConsoleLogType } from '../common/consoleLogType';
import { OkResponse } from '../common/interfaces';
import env from '../Env';
import { Message, Messages } from '../types/Message';
import { ProjectDetail } from '../types/ProjectDetail';
import { UserType, UserTypes } from '../types/ProjectUser';
import { Peer, Peers, SignalREvents } from '../types/SignalR';

const useSignalRConnection = () => {
  const [hubConnection, setHubConnection] = useState<HubConnection>();
  const hubConnectionRef = useRef(hubConnection);
  hubConnectionRef.current = hubConnection;

  const [connectedPeers, setConnectedPeers] = useState<Peers>([]);
  const [chatMessages, setChatMessages] = useState<Messages>([]);
  const [isChatConnected, setIsChatConnected] = useState<boolean>(false);

  const [projectId, setProjectId] = useState<string>();
  const projectIdRef = useRef(projectId);
  projectIdRef.current = projectId;

  const [projectDetailsFromSignalR, setProjectDetailsFromSignalR] = useState<ProjectDetail>();

  const [myUserId, setMyUserId] = useState<string>();
  const myUserIdRef = useRef(myUserId);
  myUserIdRef.current = myUserId;

  const [myUserName, setMyUserName] = useState<string>();
  const myUserNameRef = useRef(myUserName);
  myUserNameRef.current = myUserName;

  const [myPeerId, setMyPeerId] = useState<string>();

  const [myUserType, setMyUserType] = useState<UserType>(UserTypes.UNKNOWN);
  const myUserTypeRef = useRef(myUserType);
  myUserTypeRef.current = myUserType;

  const [peerRequestingControl, setPeerRequestingControl] = useState<Peer | null>(null);
  const [myConnectionIsLive, setMyConnectionIsLive] = useState<boolean>(false);
  const [signerVideoIsStarted, setSignerVideoIsStarted] = useState<boolean>(false);

  const [triggerRefreshLiveVideo, setTriggerRefreshLiveVideo] = useState<boolean>(false);
  const [triggerRefetchChatMessages, setTriggerRefetchChatMessages] = useState<boolean>(false);

  const [isAwaitingApiSaveToComplete, setIsAwaitingApiSaveToComplete] = useState<boolean>(false);

  const [isSendingConsoleLogs] = useState<boolean>(false);
  const isSendingConsoleLogsRef = useRef(isSendingConsoleLogs);
  isSendingConsoleLogsRef.current = isSendingConsoleLogs;

  const [logs] = useState<Array<ConsoleLog>>(new Array<ConsoleLog>());
  const logsRef = useRef(logs);
  logsRef.current = logs;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  let consoleLog = (...args: any[]) => {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  let consoleError = (...args: any[]) => {};

  const containsLog = (log: ConsoleLog): boolean => {
    for (let i = 0; i < logsRef.current.length; i++) {
      if (logsRef.current[i].log === log.log) {
        return true;
      }
    }

    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const logToConsole = (...args: any[]): void => {
    if (args.length === 0) {
      return;
    }

    const log = new ConsoleLog(args[0].toString(), ConsoleLogType.Log);

    if (!containsLog(log)) {
      logsRef.current.push(log);
      consoleLog(...args);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  const warnToConsole = (...args: any[]): void => {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  const errorToConsole = (...args: any[]): void => {
    if (args.length === 0) {
      return;
    }
    let message = '';

    if (args[0] instanceof Error) {
      message = `Message: ${args[0].message}`;
      message += `Name: ${args[0].name}`;
      message += `Stack: ${args[0].stack}`;
    } else {
      message = args[0].toString();
    }

    const log = new ConsoleLog(message, ConsoleLogType.Error);
    if (!containsLog(log)) {
      logsRef.current.push(log);
      consoleError(...args);
    }
  };

  // eslint-disable-next-line no-console
  consoleLog = console.log;
  // eslint-disable-next-line no-console
  consoleError = console.error;

  // eslint-disable-next-line no-console
  console.log = logToConsole;
  // eslint-disable-next-line no-console
  console.error = errorToConsole;
  // eslint-disable-next-line no-console
  console.warn = warnToConsole;

  const sendConsoleLogs = useCallback((): NodeJS.Timeout => {
    if (
      hubConnectionRef.current?.state === HubConnectionState.Connected &&
      !isSendingConsoleLogsRef.current
    ) {
      isSendingConsoleLogsRef.current = true;

      const clonedLogs: Array<ConsoleLog> = [];

      Object.assign(clonedLogs, logsRef.current);
      logsRef.current.length = 0;

      if (clonedLogs.length > 0) {
        hubConnectionRef.current.invoke(
          'ClientLogger',
          projectIdRef.current,
          '',
          myUserNameRef.current,
          myUserTypeRef.current,
          clonedLogs.map((x) => x.log),
          new Date().toISOString(),
        );
      }

      isSendingConsoleLogsRef.current = false;
    }

    return setTimeout(sendConsoleLogs, 5000);
  }, []);

  useEffect(() => {
    const timer = sendConsoleLogs();
    return () => clearTimeout(timer);
  }, [sendConsoleLogs]);

  const { refetch } = useGet({
    lazy: true,
    path: '/projects/' + projectId,
    resolve: (project: OkResponse<ProjectDetail>) => {
      const result = project.result;
      setChatMessages(result?.messages ?? []);
      return result;
    },
  });

  useEffect(() => {
    if (triggerRefetchChatMessages) {
      refetch();
      setTriggerRefetchChatMessages(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRefetchChatMessages]);

  const createHubConnection = async (
    projectId: string,
    userId: string,
    peerId: string,
    userType: UserType,
    userName: string,
    chatMessages: Messages,
  ) => {
    setProjectId(projectId);
    setMyUserId(userId);
    setMyUserName(userName);
    setMyUserType(userType);
    setMyPeerId(peerId);
    setChatMessages(chatMessages);

    const hubConn = new HubConnectionBuilder()
      .withUrl(`${env.baseUrl}/hub`)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => {
          return 3000;
        },
      })
      .build();

    hubConn.on(SignalREvents.JOINED, (peerIds: Peers) => {
      setConnectedPeers(peerIds);
    });

    hubConn.on(SignalREvents.MESSAGEGROUP, (newMessage: Message) => {
      setChatMessages((chatMessages) => [...chatMessages, newMessage]);
    });

    hubConn.on(SignalREvents.USERLEFT, (peerId) => {
      setConnectedPeers((connectedPeers) =>
        connectedPeers.filter((peer) => peer.peerId !== peerId),
      );
    });

    hubConn.on(SignalREvents.GOLIVE, () => {
      setMyConnectionIsLive(true);
      setPeerRequestingControl(null);
      hubConn.invoke('GetIsSignerVideoStopped', projectId).catch((err) => {
        console.error(err);
      });
    });

    hubConn.on(SignalREvents.UPDATEISSIGNERVIDEOSTOPPED, (result: boolean) => {
      setSignerVideoIsStarted(!result);
    });

    hubConn.on(SignalREvents.REQUESTCONTROL, (peer: Peer) => {
      setPeerRequestingControl(peer);
    });

    hubConn.on(SignalREvents.UPDATECONNECTEDPEERS, (peers: Peers) => {
      setConnectedPeers(peers);

      // if another peer is live, then ensure I'm not live
      const livePeer = peers.find((x) => {
        const userTypes = [userType];

        if (userType === UserTypes.CAPTIONER) {
          userTypes.push(UserTypes.AIWRITER);
        }

        if (userType === UserTypes.AIWRITER) {
          userTypes.push(UserTypes.CAPTIONER);
        }

        return x.isCurrentlyLive && userTypes.includes(x.userType) && x.peerId !== peerId;
      });

      if (livePeer !== undefined) {
        setMyConnectionIsLive(false);
        setPeerRequestingControl(null);
      }
    });

    hubConn.on(SignalREvents.CALLPEER, (peer: Peer) => {
      setConnectedPeers((connectedPeers) => [...connectedPeers, peer]);
    });

    hubConn.on(SignalREvents.REFRESHLIVEVIDEO, () => {
      setTriggerRefreshLiveVideo(true);
      // Reset trigger after 5 seconds
      setTimeout(function () {
        setTriggerRefreshLiveVideo(false);
      }, 5000);
    });

    hubConn.on(SignalREvents.UPDATEPROJECTDETAILS, (currentProjectDetails: ProjectDetail) => {
      setProjectDetailsFromSignalR(currentProjectDetails);
    });

    hubConn.on(
      SignalREvents.UPDATEISAWAITINGAPISAVETOCOMPLETE,
      (isAwaitingApiSaveToComplete: boolean) => {
        setIsAwaitingApiSaveToComplete(isAwaitingApiSaveToComplete);
      },
    );

    const startConnection = async () => {
      await hubConn.start().then(() => {
        hubConn?.invoke('JoinRoom', projectId, peerId, userType, userId, userName).catch((err) => {
          console.log(err);
        });
      });
      setHubConnection(hubConn);
      setIsChatConnected(true);
    };

    hubConn.onreconnecting(() => {
      setIsChatConnected(false);
    });

    hubConn.onreconnected(() => {
      setIsChatConnected(true);
      setTriggerRefetchChatMessages(true);
      console.log('JoinRoom');
      hubConn.invoke('JoinRoom', projectId, peerId, userType, userId, userName).catch((err) => {
        console.log(err);
      });
    });

    hubConn.onclose(async () => {
      setIsChatConnected(false);
      await startConnection();
    });

    try {
      await startConnection();
    } catch (err) {
      console.log(err);
    }
  };

  const sendMessageToRoom = useCallback(
    (messageText: string) => {
      hubConnection
        ?.invoke('SendMessageToRoom', projectId, myPeerId, myUserName, messageText)
        .catch((err) => {
          console.log(err);
        });
    },
    [hubConnection, projectId, myPeerId, myUserName],
  );

  const sendRequestControl = useCallback(() => {
    hubConnection?.invoke('RequestControl', projectId).catch((err) => {
      console.log(err);
    });
  }, [hubConnection, projectId]);

  const sendTakeControl = useCallback(() => {
    hubConnection?.invoke('TakeControl', projectId, myPeerId).catch(console.log);
  }, [hubConnection, projectId, myPeerId]);

  const sendGiveControl = useCallback(() => {
    setMyConnectionIsLive(false);
    setPeerRequestingControl(null);
    hubConnection?.invoke('GiveControl', projectId, peerRequestingControl?.peerId).catch((err) => {
      console.log(err);
    });
  }, [hubConnection, projectId, peerRequestingControl]);

  const sendRefreshLiveVideo = useCallback(() => {
    hubConnection?.invoke('RefreshLiveVideo', projectId).catch((err) => {
      console.log(err);
    });
  }, [hubConnection, projectId]);

  const sendReloadProxyVideo = useCallback(() => {
    // setSignerVideoIsStarted(true); // forcing stop/start button to start as reloading proxy auto starts video.
    hubConnection?.invoke('ReloadProxyVideo', projectId).catch((err) => {
      console.log(err);
    });
  }, [hubConnection, projectId]);

  const sendReloadProjectDetails = useCallback(
    (currentProjectDetails: ProjectDetail) => {
      hubConnection
        ?.invoke('UpdateProjectDetails', projectId, currentProjectDetails)
        .catch((err) => {
          console.log(err);
        });
    },
    [hubConnection, projectId],
  );

  const sendStopSignerVideo = useCallback(() => {
    hubConnection
      ?.invoke('StopSignerVideo', projectId)
      .then((res) => {
        if (res) {
          setSignerVideoIsStarted(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [hubConnection, projectId]);

  const sendStartSignerVideo = useCallback(() => {
    hubConnection
      ?.invoke('StartSignerVideo', projectId)
      .then((res) => {
        if (res) {
          setSignerVideoIsStarted(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [hubConnection, projectId]);

  const sendIsAwaitingApiSaveToComplete = useCallback(
    (isAwaitingApiSaveToComplete: boolean) => {
      hubConnection
        ?.invoke('UpdateIsAwaitingApiSaveToComplete', projectId, isAwaitingApiSaveToComplete)
        .catch((err) => {
          console.log(err);
        });
    },
    [hubConnection, projectId],
  );

  const getPeerInfo = useCallback(
    (peerId: string): Peer | null => {
      if (peerId === myPeerId) {
        return {
          peerId: myPeerId,
          userId: myUserId ?? '',
          userName: myUserName ?? '',
          userType: myUserType,
          isCurrentlyLive: myConnectionIsLive,
        };
      }
      return connectedPeers.find((x) => x.peerId === peerId) ?? null;
    },
    [myPeerId, myUserId, myUserName, myUserType, myConnectionIsLive, connectedPeers],
  );

  const isUserActive = useCallback(
    (userId: string): boolean => {
      return connectedPeers.find((x) => x.userId === userId) !== undefined;
    },
    [connectedPeers],
  );

  const isUserLive = useCallback(
    (userId: string): boolean => {
      const peer: Peer | undefined = connectedPeers.find((x) => x.userId === userId);

      if (peer === undefined || peer.userType === UserTypes.PRODUCER) return false;

      return peer.isCurrentlyLive;
    },
    [connectedPeers],
  );

  return {
    createHubConnection,
    connectedPeers,
    isChatConnected,
    sendMessageToRoom,
    chatMessages,
    sendRequestControl,
    sendTakeControl,
    sendGiveControl,
    getPeerInfo,
    peerRequestingControl,
    sendRefreshLiveVideo,
    triggerRefreshLiveVideo,
    sendReloadProjectDetails,
    projectDetailsFromSignalR,
    sendStopSignerVideo,
    sendStartSignerVideo,
    signerVideoIsStarted,
    sendReloadProxyVideo,
    myConnectionIsLive,
    isAwaitingApiSaveToComplete,
    sendIsAwaitingApiSaveToComplete,
    isUserActive,
    isUserLive,
  };
};

export default useSignalRConnection;
