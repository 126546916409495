import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'bulma/css/bulma.css';
import './scss/core.scss';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
// import axe from '@axe-core/react';

import { Auth0Provider } from '@auth0/auth0-react';
import { Route, Router } from 'react-router-dom';

import './tools/I18n';
import env from './Env';
import history from './tools/history';

if (env.environment !== 'production') {
  // axe(React, ReactDOM, 1000, {});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onRedirectCallback = (appState: any) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

//TODO: Put Strict mode back in once the finddomnode errors go away from react-modal
ReactDOM.render(
  <Suspense fallback="">
    <Auth0Provider
      domain={env.authDomain}
      clientId={env.authClientId}
      redirectUri={env.authRedirect}
      audience={env.authAudience}
      useRefreshTokens={true}
      cacheLocation={'localstorage'}
      scope="openid profile name portal.access-loop.co.uk/name portal.access-loop.co.uk/email"
    >
      <Router history={history}>
        <Route component={App} />
      </Router>
    </Auth0Provider>
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
