interface Env {
  authDomain: string;
  authClientId: string;
  authRedirect: string;
  baseUrl: string;
  authAudience: string;
  environment: string;
  stripePublicKey: string;
  antMediaServer: string;
  peerServer: string;
  streamTextPlayerUrl: string;
  blobStorageUrl: string;
  stripeSignUpPriceId: string;
}

const env: Env = {
  authDomain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  authClientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  authRedirect: process.env.REACT_APP_AUTH0_REDIRECT_URI as string,
  baseUrl: process.env.REACT_APP_BASE_URL as string,
  authAudience: process.env.REACT_APP_AUTH0_API_AUDIENCE as string,
  environment: process.env.NODE_ENV as string,
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
  antMediaServer: process.env.REACT_APP_ANT_MEDIA_SERVER as string,
  peerServer: process.env.REACT_APP_PEER_SERVER as string,
  streamTextPlayerUrl: process.env.REACT_APP_STREAMTEXT_PLAYER_URL as string,
  blobStorageUrl: process.env.REACT_APP_BLOB_STORAGE_URL as string,
  stripeSignUpPriceId: process.env.REACT_APP_STRIPE_SIGN_UP_PRICE_ID as string,
};

export default env;
