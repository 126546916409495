import React, { useState } from 'react';
import { useGet } from 'restful-react';

import './ProjectCardContainer.scss';

import NewProjectCard from '../Cards/NewProjectCard/NewProjectCard';
import ProjectCard from '../Cards/ProjectCard/ProjectCard';
import { ProjectSummaries, ProjectSummary } from '../../../types/ProjectSummary';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { CardTypes } from '../../../types/CardType';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import ProjectSummaryCard from '../Cards/ProjectSummaryCard/ProjectSummaryCard';
import { ProjectOverviewViews } from '../../../types/ProjectOverviewView';
import { OkResponse } from '../../../common/interfaces';

type ProjectCardContainerProps = {
  activeProjectView: ProjectOverviewViews;
  onChangeProjectView: (activeView: ProjectOverviewViews) => void;
};

type FetchProjectsProps = {
  apiPath: string;
  cardType: CardTypes;
};

const ProjectCardContainer = ({
  activeProjectView,
  onChangeProjectView,
}: ProjectCardContainerProps) => {
  const { t } = useTranslation();
  const [selectedProjectId, setSelectedProjectId] = useState<string>('');

  const HandleShowProjectSummary = (projectId: string) => {
    setSelectedProjectId(projectId);
    onChangeProjectView(ProjectOverviewViews.SUMMARY);
  };

  const HandleProjectSummaryBackButton = () => {
    setSelectedProjectId('');
    onChangeProjectView(ProjectOverviewViews.PREVIOUS);
  };

  const FetchProjects = ({ apiPath, cardType }: FetchProjectsProps) => {
    const { data: projects, loading, error } = useGet({
      path: apiPath,
      resolve: (projects: OkResponse<ProjectSummaries>) => projects.result,
    });

    if (loading) {
      return <LoadingSpinner />;
    }

    if (error) {
      return <ErrorMessage message={t('error-messages.generic-error')} />;
    }

    return (
      <>
        {projects &&
          projects.length > 0 &&
          projects.map((project: ProjectSummary) => (
            <ProjectCard
              cardType={cardType}
              project={project}
              key={project.id}
              onShowProjectSummary={HandleShowProjectSummary}
            />
          ))}
      </>
    );
  };

  const renderSwitch = (activeView: ProjectOverviewViews) => {
    switch (activeView) {
      case ProjectOverviewViews.ACTIVE:
        return (
          <div className="project-card-wrapper">
            <NewProjectCard />
            <FetchProjects apiPath="/projects/active" cardType={CardTypes.ACTIVE} />
          </div>
        );
      case ProjectOverviewViews.PREVIOUS:
        return (
          <div className="project-card-wrapper">
            <FetchProjects apiPath="/projects/previous" cardType={CardTypes.PREVIOUS} />
          </div>
        );
      case ProjectOverviewViews.SUMMARY:
        return (
          <ProjectSummaryCard
            projectId={selectedProjectId}
            onBackButton={HandleProjectSummaryBackButton}
          />
        );
      default:
        return null;
    }
  };

  return <div className="project-card-container">{renderSwitch(activeProjectView)}</div>;
};

export default ProjectCardContainer;
