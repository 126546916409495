import { useState, useEffect } from 'react';
import { videoStreamConfig } from '../common/constants';

export function useUserCamera() {
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [availableVideoStreams, setAvailableVideoStreams] = useState<MediaDeviceInfo[]>([]);
  const [errorInitCamera, setErrorInitCamera] = useState<boolean>(false);

  useEffect(() => {
    async function getAvailableVideoStreams() {
      try {
        // call used to prompt use to allow access to camera for site.
        await navigator.mediaDevices.getUserMedia({ audio: false, video: true });

        const streams = await navigator.mediaDevices.enumerateDevices();
        const videoStreams = streams.filter((x) => x.kind === 'videoinput');
        setAvailableVideoStreams(videoStreams);
      } catch (err) {
        console.log(err);
        setErrorInitCamera(true);
      }
    }

    getAvailableVideoStreams();
  }, []); // only fire once on load of page for now.

  const getStreams = () => {
    return availableVideoStreams;
  };

  const setStream = async (streamId: string) => {
    try {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (streamId) {
        const constraints = {
          audio: false,
          video: {
            width: videoStreamConfig.width,
            height: videoStreamConfig.height,
            deviceId: { exact: streamId },
          },
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        setMediaStream(stream);
        setErrorInitCamera(false);
      }
    } catch (err) {
      console.log(err);
      setErrorInitCamera(true);
    }
  };

  return { mediaStream, getStreams, setStream, availableVideoStreams, errorInitCamera };
}
