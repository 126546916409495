import React, { useState } from 'react';
import { useGet } from 'restful-react';

import './ProjectSummaryCard.scss';

import { useTranslation } from 'react-i18next';
import { Button } from '../../../FormComponents/Buttons/Button';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';

import BackArrow from '../../../../images/back-arrow-blue.png';
import ProjectSummaryCardBlockProjectUser from '../ProjectSummaryCardBlocks/ProjectSummaryCardBlockProjectUser';
import ProjectSummaryCardBlockGeneral from '../ProjectSummaryCardBlocks/ProjectSummaryCardBlockGeneral';
import ProjectSummaryCardBlockLinks from '../ProjectSummaryCardBlocks/ProjectSummaryCardBlockLinks';
import ProjectSummaryCardBlockCaptionStyles from '../ProjectSummaryCardBlocks/ProjectSummaryCardBlockCaptionStyles';
import { Layout, LayoutTypes } from '../../../../types/Layout';
import { OkResponse } from '../../../../common/interfaces';
import { ProjectDetail } from '../../../../types/ProjectDetail';
import { getSelectedLayout } from '../../../../helpers/selectedLayoutHelper/selectedLayoutHelper';
import Chat from '../../../Chat/Chat';
import { DuplicateProjectDetailsModal } from '../../../Modals/DuplicateProjectDetailsModal/DuplicateProjectDetailsModal';

type ProjectSummaryCardProps = {
  projectId: string;
  onBackButton: () => void;
};

const ProjectSummaryCard = ({ projectId, onBackButton }: ProjectSummaryCardProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: project, loading, error } = useGet({
    path: '/projects/' + projectId,
    resolve: (project: OkResponse<ProjectDetail>) => project.result,
  });

  const hasCaptioning = (): boolean => {
    if (project?.layouts) {
      const layout: Layout = project?.layouts[0];

      return layout.hasCaption;
    }

    return false;
  };

  const hasSigning = (): boolean => {
    if (project?.layouts) {
      const layout: Layout = project?.layouts[0];

      return layout.hasSigner;
    }

    return false;
  };

  const hasStreamDestinations = (): boolean => {
    if (project) {
      return project?.consumers?.length !== 0;
    }

    return false;
  };

  const hasStreamSettings = (): boolean => {
    return (
      (project?.customLogoUrl !== undefined && project?.customLogoUrl !== '') ||
      (project?.customColourHex !== undefined && project?.customColourHex !== '#000000')
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  if (!project) {
    return null;
  }

  return (
    <div className="project-summary-card">
      <div className="project-summary-card-header level">
        <div className="level-left project-summary-card-title">
          <button onClick={onBackButton} className="project-summary-card-back-button">
            <img src={BackArrow} alt="back" className="project-summary-card-back-button-image" />
          </button>
          {project?.name} - {t('project-overview.cards.summary-label')}
        </div>
        <div className="level-right">
          <Button
            translationKey="button.duplicate-project"
            variant="secondary"
            color="blue"
            additionalClassName="btn-no-margin project-summary-card-button"
            onClick={() => setIsModalOpen(true)}
            type="button"
          />
          {/* <Button
            translationKey="button.download-chat"
            variant="secondary"
            color="blue"
            additionalClassName="btn-no-margin project-summary-card-button"
            onClick={() => null}
            type="button"
          /> */}
        </div>
      </div>
      <div className="columns project-summary-card-body">
        <div className="column is-half">
          <ProjectSummaryCardBlockGeneral
            heading={t('project.general-heading')}
            projectDetail={project}
          />
          <ProjectSummaryCardBlockLinks
            heading={t('project.links.heading')}
            sourceUrl={project?.ingestKey || ''}
            consumers={project?.consumers ?? []}
          />
          <ProjectSummaryCardBlockCaptionStyles
            heading={t('project.caption-styles.heading')}
            mainLayoutDetail={getSelectedLayout(project?.layouts, LayoutTypes.MAIN)}
          />
          {project?.projectUsers &&
            project.projectUsers.length > 0 &&
            project.projectUsers.map((projectUser, index) => (
              <ProjectSummaryCardBlockProjectUser
                heading={projectUser.userType}
                projectUser={projectUser}
                key={index}
              />
            ))}
        </div>
        <div className="column is-half">
          <div className="project-summary-card-chat-title">
            {t('project-overview.cards.chat-history')}
          </div>
          <div className="project-summary-card-chat-panel">
            <Chat
              chatMessages={project.messages || []}
              userId={''}
              isReadOnly={true}
              isChatConnected={false}
            />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <DuplicateProjectDetailsModal
          isOpen={isModalOpen}
          contentLabel="Copy Project"
          projectIdToDuplicate={project.id}
          projectName={project.name}
          projectResolutionToDuplicate={project.resolution}
          handleCloseModal={() => setIsModalOpen(false)}
          hasStreamDestinations={hasStreamDestinations()}
          hasCaptioning={hasCaptioning()}
          hasSigning={hasSigning()}
          hasStreamSettings={hasStreamSettings()}
        />
      )}
    </div>
  );
};

export default ProjectSummaryCard;
