import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../FormComponents/Buttons/Button';
import { TextInput } from '../../FormComponents/TextInput/TextInput';
import { BaseModal } from '../BaseModal/BaseModal';

import './UserDetailsModal.scss';

type Props = {
  currentValue: string;
  isOpen: boolean;
  handleCloseModal: () => void;
  onSubmit: (value: string) => void;
  placeholder: string;
};

export const UserDetailsModal = (props: Props) => {
  const { control, errors, handleSubmit } = useForm({
    defaultValues: { value: props.currentValue },
  });

  return (
    <BaseModal
      isOpen={props.isOpen}
      handleCloseModal={props.handleCloseModal}
      ariaHideApp={true}
      title="account.user-details.title"
    >
      <form
        className="user-details-modal"
        onSubmit={handleSubmit((data) => props.onSubmit(data.value))}
      >
        <Controller
          name="value"
          control={control}
          rules={{ required: true, maxLength: 100 }}
          render={({ onChange, value }) => (
            <TextInput
              placeholder={props.placeholder}
              value={value}
              onChange={onChange}
              onAfterChange={null}
              errorType={errors.value}
              errorMessageKey={'account.user-details.field-error'}
            />
          )}
        />
        <Button
          translationKey="account.user-details.update"
          variant="primary"
          color="blue"
          onClick={() => null}
          type="submit"
        />
      </form>
    </BaseModal>
  );
};
