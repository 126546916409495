import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectDetail } from '../../../types/ProjectDetail';
import { ProjectUser, UserTypes } from '../../../types/ProjectUser';
import { Peer, Peers } from '../../../types/SignalR';
import ProjectUserElement from '../../ProjectUserElement/ProjectUserElement';

import './MainSectionProjectUsers.scss';

type MainSectionProjectUsersProp = {
  projectDetails: ProjectDetail | null;
  isUserActiveCallback: (userId: string) => boolean;
  isUserLiveCallback: (userId: string) => boolean;
  connectedPeers: Peers;
};

const MainSectionProjectUsers = (props: MainSectionProjectUsersProp) => {
  const { t } = useTranslation();
  return (
    <div className="main-section-project-users">
      <ProjectUserElement
        colorId={0}
        userId={props.projectDetails?.createdByUserId ?? ''}
        isUserActiveCallback={props.isUserActiveCallback}
        isUserLiveCallback={props.isUserLiveCallback}
        name={props.projectDetails?.createdByUserName ?? ''}
        description={t('labels.producer-description')}
      />
      {props.projectDetails?.projectUsers &&
        props.projectDetails?.projectUsers.length > 0 &&
        props.projectDetails?.projectUsers.map((projectUser: ProjectUser, index: number) => (
          <div key={projectUser?.id}>
            {(props.projectDetails?.activeLayout?.hasCaption &&
              projectUser?.userType === UserTypes.CAPTIONER) ||
            (props.projectDetails?.activeLayout?.hasSigner &&
              projectUser?.userType === UserTypes.SIGNER) ? (
              <ProjectUserElement
                colorId={index + 1}
                name={projectUser.name}
                userId={projectUser.id}
                isUserActiveCallback={props.isUserActiveCallback}
                isUserLiveCallback={props.isUserLiveCallback}
                description={
                  projectUser.userType === UserTypes.SIGNER
                    ? t('labels.signer-known-as')
                    : projectUser.userType
                }
              />
            ) : null}
          </div>
        ))}
      {props.connectedPeers.map((peer: Peer, index: number) => (
        <div key={peer?.peerId}>
          {peer?.userType === UserTypes.AIWRITER &&
          props.projectDetails?.projectUsers?.filter((x) => x.id === peer?.userId).length === 0 ? (
            <ProjectUserElement
              colorId={index + 1}
              name={peer.userName}
              userId={peer.userId}
              isUserActiveCallback={props.isUserActiveCallback}
              isUserLiveCallback={props.isUserLiveCallback}
              description={peer.userType}
            />
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default MainSectionProjectUsers;
