import React from 'react';
import { useTranslation } from 'react-i18next';
import { Option, TimeZoneOption } from '../../../common/interfaces';
import { ValidationError } from '../ValidationError/ValidationError';
import { FieldError } from 'react-hook-form';
import { Select, CaretIcon } from 'react-responsive-select';
import 'react-responsive-select/dist/react-responsive-select.css';
import './Dropdown.scss';

type Props = {
  options: Option[] | TimeZoneOption[];
  onChange: (data: Option) => void;
  placeholderKey: string;
  errorType: FieldError | undefined;
  errorMessageKey: string;
  value?: string;
  readonly?: boolean;
};

export const Dropdown = (props: Props) => {
  const { t } = useTranslation();

  const handleChange = (changes: Option) => {
    props.onChange(changes);
  };

  return (
    <>
      <ValidationError errorType={props.errorType} errorMessageKey={props.errorMessageKey} />
      <Select
        name="dropdown"
        options={props.options}
        caretIcon={<CaretIcon />}
        selectedValue={props.value}
        onChange={(changes) => handleChange(changes as Option)}
        noSelectionLabel={t(props.placeholderKey)}
        disabled={props.readonly}
      />
    </>
  );
};
