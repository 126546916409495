export enum ProjectOverviewViews {
  ACTIVE,
  PREVIOUS,
  SUMMARY,
}

export type ProjectOverviewView =
  | ProjectOverviewViews.ACTIVE
  | ProjectOverviewViews.PREVIOUS
  | ProjectOverviewViews.SUMMARY;
