import React, { useRef, useState } from 'react';
import './Navbar.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import i18n from '../../tools/I18n';
import AppLogo from './Logos/AppLogo/AppLogo';
import { useAuth0 } from '@auth0/auth0-react';
import env from '../../Env';
import useOutsideClick from '../../hooks/useOutsideClick';

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  // const lngs = ['EN', 'DE'];
  // const changeLanguage = (lng: string) => i18n.changeLanguage(lng);

  const { user, logout, isAuthenticated } = useAuth0();

  useOutsideClick(node, () => setIsDropdownOpen(false));

  const renderNavMenu = () => {
    if (!isAuthenticated) {
      return (
        <div className="navbar-menu">
          <div className="navbar-end">
            <a
              href="https://support.accessloop.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-secondary btn-secondary--pink"
            >
              {t('navbar.help')}
            </a>
          </div>
        </div>
      );
    }

    return (
      <div className="navbar-menu">
        <div className="navbar-end">
          <a
            href="https://support.accessloop.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-secondary btn-secondary--pink"
          >
            {t('navbar.help')}
          </a>

          {/* <select
            className="navbar-item navbar-item__select"
            onChange={(event) => changeLanguage(event.target.value)}
            defaultValue={i18n.language}
          >
            {lngs.map((lng) => (
              <option key={lng} value={lng.toLowerCase()} label={lng} />
            ))}
          </select> */}

          <div className="navbar-item">
            <NavLink exact to="/account" className="btn btn-primary btn-primary--pink">
              {t('navbar.pricing')}
            </NavLink>
          </div>

          <div className="navbar-item">
            <div className={'dropdown is-right' + (isDropdownOpen ? ' is-active' : '')}>
              <div className="dropdown-trigger">
                <button
                  className="profile-button"
                  aria-haspopup="true"
                  aria-controls="account-dropdown-menu"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <img src={user.picture} alt="Account" />
                </button>
              </div>
              <div ref={node} className="dropdown-menu" id="account-dropdown-menu" role="menu">
                <div className="dropdown-content">
                  <NavLink
                    exact
                    to="/account"
                    className="dropdown-item"
                    role="menuitem"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    {t('navbar.account')}
                  </NavLink>
                  <button
                    className="dropdown-item navbar__sign-out"
                    onClick={() => logout({ returnTo: env.authRedirect })}
                    role="menuitem"
                  >
                    {t('navbar.sign-out')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <AppLogo />
      </div>
      {renderNavMenu()}
    </nav>
  );
};

export default Navbar;
