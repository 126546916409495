import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from '../ValidationError/ValidationError';
import './TextInput.scss';
import { FieldError } from 'react-hook-form';

type Props = {
  placeholder: string;
  value: string;
  onChange: (data: string) => void;
  onAfterChange: null | ((data: string) => void);
  errorType: FieldError | undefined;
  errorMessageKey: string;
  readOnly?: boolean;
};

export const TextInput = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <ValidationError errorType={props.errorType} errorMessageKey={props.errorMessageKey} />
      <input
        className={(props.errorType && 'is-danger ') + ' input input-text'}
        type="text"
        value={props.value}
        placeholder={t(props.placeholder)}
        onChange={(e) => {
          props.onChange(e.target.value);

          if (props.onAfterChange !== null) {
            props.onAfterChange(e.target.value);
          }
        }}
        readOnly={props.readOnly}
      />
    </>
  );
};
