import React from 'react';
import { useTranslation } from 'react-i18next';
import './ValidationError.scss';
import { FieldError } from 'react-hook-form';

type Props = {
  errorMessageKey: string;
  errorType: FieldError | undefined;
};

export const ValidationError = (props: Props) => {
  const { t } = useTranslation();

  return <span className="help is-danger">{props.errorType && t(props.errorMessageKey)}</span>;
};
