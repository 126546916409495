// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorMessageToTranslationKey = (errorObject: any): string => {
  const defaultError = 'error-messages.generic-error';
  const errorMessages: { [key: string]: string } = {
    'User subscription tier does not allow concurrent events':
      'error-messages.concurrent-events-not-allowed',
  };

  if (!errorObject) {
    return defaultError;
  }

  if (errorObject?.data?.errors && errorObject?.data?.errors.length > 0) {
    if (errorMessages[errorObject.data.errors[0]]) {
      return errorMessages[errorObject.data.errors[0]];
    }
  }

  return defaultError;
};
