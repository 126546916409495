import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../FormComponents/Buttons/Button';
import { BaseModal } from '../BaseModal/BaseModal';

import './DeleteProjectModal.scss';

type Props = {
  isOpen: boolean;
  handleCancelModal: () => void;
  handleOkModal: () => void;
};

export const DeleteProjectModal = (props: Props) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      isOpen={props.isOpen}
      handleCloseModal={() => null}
      ariaHideApp={true}
      title="project.delete-project.modal-title"
    >
      <div className="delete-project-modal">
        <div className="delete-project-modal-info-text">
          <p className="delete-project-modal-info-text__row">
            {t('project.delete-project.modal-text-line-one')}
          </p>
          <p className="delete-project-modal-info-text__row">
            {t('project.delete-project.modal-text-line-two')}
          </p>
        </div>
        <div className="delete-project-modal-button-bar">
          <Button
            translationKey="button.cancel"
            variant="primary"
            color="blue"
            onClick={() => props.handleCancelModal()}
            type="button"
            additionalClassName={'delete-project-modal-button'}
          />
          <Button
            translationKey="button.delete-project"
            variant="primary"
            color="red"
            onClick={() => props.handleOkModal()}
            type="button"
            additionalClassName={'delete-project-modal-button'}
          />
        </div>
      </div>
    </BaseModal>
  );
};
