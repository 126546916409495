export enum BroadcastStates {
  NOTSTARTED,
  STARTED,
  FINISHED,
}

export type BroadcastState =
  | BroadcastStates.NOTSTARTED
  | BroadcastStates.STARTED
  | BroadcastStates.FINISHED;
