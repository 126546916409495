import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import env from '../../Env';
import useStripeCheckout from '../../hooks/useStripeCheckout';

export default function Subscribe() {
  const { t } = useTranslation();
  const [creatingSession, setCreatingSession] = useState(false);
  const { createSession, errorSession, loadingSession } = useStripeCheckout();

  if (!creatingSession) {
    setCreatingSession(true);
    createSession({ stripePriceId: env.stripeSignUpPriceId });
  }

  if (loadingSession) {
    return <LoadingSpinner />;
  }

  if (errorSession) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  return <div></div>;
}
