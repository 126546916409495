import React from 'react';
import { ProjectDetailContext } from '../../types/ProjectDetail';
import ProjectDetailsContext from './ProjectDetailsContext';

type ProjectDetailsProviderOptions = {
  /**
   * The child nodes the Provider has wrapped
   */
  children?: React.ReactNode;
  /**
   * The project details data to provide to consumers.
   */
  value: ProjectDetailContext;
};

const ProjectDetailsProvider = (opts: ProjectDetailsProviderOptions) => {
  const { value, children } = opts;

  return <ProjectDetailsContext.Provider value={value}>{children}</ProjectDetailsContext.Provider>;
};

export default ProjectDetailsProvider;
