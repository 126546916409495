import React from 'react';
import history from '../../tools/history';
import { AccountHeader } from '../../components/AccountComponents/AccountHeader/AccountHeader';

import './Account.scss';
import { UserDetails } from '../../components/AccountComponents/UserDetails/UserDetails';
import { useMutate } from 'restful-react';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { AccountDetails } from '../../types/AccountDetails';
import useUserDetails from '../../hooks/useUserDetails';
import { Pricing } from '../../components/AccountComponents/Pricing/Pricing';

export default function Account() {
  const { t } = useTranslation();

  const navigateToHome = () => history.push('/');

  const { user, loadingUser, errorUser, refetch } = useUserDetails();

  const {
    mutate: updateDetails,
    loading: loadingUpdate,
    error: errorUpdate,
  } = useMutate<AccountDetails>({
    verb: 'PATCH',
    path: '/accounts',
    onMutate: () => refetch(),
  });

  const handleChange = (name: string, email: string) => {
    updateDetails({ name, email });
  };

  if (loadingUser || loadingUpdate) {
    return <LoadingSpinner />;
  }

  if (errorUser || !user || errorUpdate) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  return (
    <>
      <AccountHeader onClick={navigateToHome} />

      <div className="container account">
        <div className="columns">
          <div className="column is-4">
            <div className="account__section">
              <UserDetails user={user} handleUserDetailChange={handleChange} />
            </div>
          </div>
          <div className="column">
            <div className="account__section">
              <Pricing></Pricing>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
