import React, { useEffect, useState } from 'react';

import './ProducerFooter.scss';

import { Button } from '../../FormComponents/Buttons/Button';
import { useMutate } from 'restful-react';
import useProjectDetails from '../../../hooks/useProjectDetails';
import { Layout } from '../../../types/Layout';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import {
  // getDateDifferenceFromNow,
  getUTCDateNow,
} from '../../../helpers/dateFormatHelper/dateFormatHelper';
import _ from 'lodash';
import { BroadcastStates } from '../../../types/BroadcastState';
// import moment from 'moment';
import { errorMessageToTranslationKey } from '../../../helpers/errorMessageHelper/errorMessageHelper';
import history from '../../../tools/history';
import { DeleteProjectModal } from '../../Modals/DeleteProjectModal/DeleteProjectModal';
import { PopupModal } from '../../Modals/PopupModal/PopupModal';

type BroadcastPayload = {
  projectId: string;
  layoutId: string;
  start: boolean;
  restart?: boolean;
  activatePreview?: boolean;
};

type DeleteProjectPayload = {
  id: string;
};

type ProducerFooterProps = {
  sendRefreshLiveVideoCallback?: () => void;
};

const ProducerFooter = ({ sendRefreshLiveVideoCallback }: ProducerFooterProps) => {
  const { projectDetails, setProjectDetails } = useProjectDetails();

  const { t } = useTranslation();
  const [broadcastState, setBroadcastState] = useState<BroadcastStates>(BroadcastStates.NOTSTARTED);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [isNoDestinationsModelOpen, setIsNoDestinationsModelOpen] = useState<boolean>(false);
  // const [isPreviewAvailable, setIsPreviewAvailable] = useState<boolean>(false);
  // const [disablePreviewButton, setDisablePreviewButton] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const layout = projectDetails?.layouts?.find((x) => x.layoutType === 'Main') as Layout;

  // useEffect(() => {
  //   const checkIfPreviewIsAvailable = () => {
  //     const minutesTillStart = moment
  //       .duration(getDateDifferenceFromNow(projectDetails?.startDateTimeUtc))
  //       .asMinutes();
  //     if (minutesTillStart < 16) {
  //       setIsPreviewAvailable(true);
  //     }
  //   };
  //   const interval = setInterval(() => checkIfPreviewIsAvailable(), 30000);
  //   checkIfPreviewIsAvailable();
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [projectDetails]);

  useEffect(() => {
    const lookupBroadcastState = () => {
      if (projectDetails?.broadcastStartedOnUtc && projectDetails?.broadcastEndedOnUtc) {
        setBroadcastState(BroadcastStates.FINISHED);
      } else if (projectDetails?.broadcastStartedOnUtc && !projectDetails?.broadcastEndedOnUtc) {
        setBroadcastState(BroadcastStates.STARTED);
      } else if (!projectDetails?.broadcastStartedOnUtc) {
        setBroadcastState(BroadcastStates.NOTSTARTED);
      }
    };
    // const isPreviewStarted = () => {
    //   if (projectDetails?.previewStartedOnUtc) {
    //     setDisablePreviewButton(true);
    //   }
    // };
    lookupBroadcastState();
    // isPreviewStarted();
  }, [projectDetails]);

  const onSuccess = (body: BroadcastPayload, data: unknown) => {
    const typedData = data as {
      result: string;
    };

    setRefreshing(false);
    if (
      broadcastState === BroadcastStates.NOTSTARTED ||
      broadcastState === BroadcastStates.STARTED
    ) {
      const newProjectDetails = _.cloneDeep(projectDetails);
      if (broadcastState === BroadcastStates.NOTSTARTED && newProjectDetails) {
        newProjectDetails.broadcastStartedOnUtc = getUTCDateNow().toString();
        newProjectDetails.paymentStatus = typedData.result;
        setProjectDetails(newProjectDetails);
      } else if (broadcastState === BroadcastStates.STARTED && newProjectDetails) {
        newProjectDetails.broadcastEndedOnUtc = getUTCDateNow().toString();
        newProjectDetails.paymentStatus = typedData.result;
        setProjectDetails(newProjectDetails);
      }
    }
  };

  const onSuccessRestart = () => {
    setRefreshing(false);
    if (sendRefreshLiveVideoCallback) {
      sendRefreshLiveVideoCallback();
    }
  };

  // const onSuccessPreview = () => {
  //   // setDisablePreviewButton(true);
  //   const newProjectDetails = _.cloneDeep(projectDetails);
  //   if (newProjectDetails) {
  //     newProjectDetails.previewStartedOnUtc = getUTCDateNow().toString();
  //     setProjectDetails(newProjectDetails);
  //   }
  // };

  const onSuccessDelete = () => {
    history.push('/');
  };

  const { mutate: broadcast, loading, error } = useMutate<BroadcastPayload>({
    verb: 'POST',
    path: `/projects/${projectDetails?.id}/broadcast`,
    onMutate: onSuccess,
  });

  const { mutate: broadcastRestart, error: errorRestarting } = useMutate<BroadcastPayload>({
    verb: 'POST',
    path: `/projects/${projectDetails?.id}/broadcast`,
    onMutate: onSuccessRestart,
  });

  const preventStartIfNoDestinations = (): boolean => {
    if (!projectDetails?.consumers || projectDetails?.consumers?.length === 0) {
      setIsNoDestinationsModelOpen(true);
      return false;
    }

    return true;
  };

  // const {
  //   mutate: broadcastPreview,
  //   loading: loadingPreviewing,
  //   error: errorPreviewing,
  // } = useMutate<BroadcastPayload>({
  //   verb: 'POST',
  //   path: `/projects/${projectDetails?.id}/broadcast`,
  //   onMutate: onSuccessPreview,
  // });

  const { mutate: deleteProject } = useMutate<DeleteProjectPayload>({
    verb: 'DELETE',
    path: `/projects`,
    onMutate: onSuccessDelete,
  });

  const handleStart = () => {
    if (!preventStartIfNoDestinations()) {
      return;
    }

    const data: BroadcastPayload = {
      projectId: projectDetails?.id ?? '',
      layoutId: layout.id,
      start: true,
    };
    broadcast(data);
  };

  const handleStop = (event?: React.BaseSyntheticEvent) => {
    event?.preventDefault();

    const data: BroadcastPayload = {
      projectId: projectDetails?.id ?? '',
      layoutId: layout.id,
      start: false,
    };
    broadcast(data);
  };

  const handleRestart = () => {
    setRefreshing(true);
    const data: BroadcastPayload = {
      projectId: projectDetails?.id ?? '',
      layoutId: layout.id,
      start: true,
      restart: true,
    };
    broadcastRestart(data);
  };

  // const handlePreview = () => {
  //   if (!preventStartIfNoDestinations()) {
  //     return;
  //   }

  //   const data: BroadcastPayload = {
  //     projectId: projectDetails?.id ?? '',
  //     layoutId: layout.id,
  //     start: false,
  //     activatePreview: true,
  //   };
  //   broadcastPreview(data);
  // };

  const handleDeleteProject = () => {
    setIsModalOpen(true);
  };

  const handleDeleteProjectModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleDeleteProjectModalOk = () => {
    const data: DeleteProjectPayload = {
      id: projectDetails?.id ?? '',
    };
    deleteProject(data);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || errorRestarting) {
    const errorData = error || errorRestarting;
    const message = errorMessageToTranslationKey(errorData);
    return <ErrorMessage message={t(message)} />;
  }

  if (projectDetails?.deletedOnUtc || projectDetails?.broadcastEndedOnUtc) {
    return null;
  }

  return (
    <div className="main-section-footer level">
      <div className="level-left">
        {broadcastState !== BroadcastStates.STARTED &&
        broadcastState !== BroadcastStates.FINISHED ? (
          <Button
            translationKey="button.delete-project"
            variant="secondary"
            color="red"
            additionalClassName="btn-no-margin btn-margin-right"
            onClick={handleDeleteProject}
            type="button"
            disabled={refreshing}
          />
        ) : null}
        {broadcastState !== BroadcastStates.NOTSTARTED ||
        projectDetails?.previewStartedOnUtc != null ? (
          <Button
            translationKey="button.restart-loop"
            variant="secondary"
            color="green"
            additionalClassName="btn-no-margin"
            onClick={handleRestart}
            type="button"
            disabled={refreshing || broadcastState === BroadcastStates.FINISHED}
          />
        ) : null}
      </div>
      <div className="level-right stop-start-buttons-wrapper">
        {/* {isPreviewAvailable && broadcastState === BroadcastStates.NOTSTARTED ? (
          <Button
            translationKey="button.preview-loop"
            variant="secondary"
            color="green"
            additionalClassName="btn-no-margin btn-margin-right"
            onClick={handlePreview}
            type="button"
            disabled={refreshing || disablePreviewButton}
          />
        ) : null} */}
        {broadcastState === BroadcastStates.NOTSTARTED ? (
          <Button
            translationKey="button.start-loop"
            variant="primary"
            color="green"
            additionalClassName="btn-no-margin "
            onClick={handleStart}
            type="button"
            disabled={refreshing}
          />
        ) : null}
        {broadcastState === BroadcastStates.STARTED ? (
          <Button
            translationKey="button.stop-loop"
            variant="primary"
            color="pink"
            additionalClassName="btn-no-margin"
            onClick={handleStop}
            type="button"
            disabled={refreshing}
          />
        ) : null}
        {broadcastState === BroadcastStates.FINISHED ? (
          <Button
            translationKey="button.loop-stopped"
            variant="secondary"
            color="grey"
            additionalClassName="btn-no-margin is-disabled"
            onClick={() => null}
            type="button"
          />
        ) : null}
      </div>
      {isModalOpen && (
        <DeleteProjectModal
          isOpen={isModalOpen}
          handleCancelModal={handleDeleteProjectModalCancel}
          handleOkModal={handleDeleteProjectModalOk}
        />
      )}
      {isNoDestinationsModelOpen && (
        <PopupModal
          isOpen={true}
          handleCloseModal={() => setIsNoDestinationsModelOpen(false)}
          title="producer.no-destinations-modal.title"
          content={t('producer.no-destinations-modal.content')}
        />
      )}
    </div>
  );
};

export default ProducerFooter;
