import React, { useEffect, useState } from 'react';
import { useGet } from 'restful-react';

import './ProducerContainer.scss';

import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import ProducerHeader from '../../ProjectHeaderBar/ProjectHeader';
import ProjectMainSection from '../../ProjectMainSection/ProjectMainSection';
import ProjectDetailsProvider from '../../../contexts/project-details/ProjectDetailsProvider';
import { OkResponse } from '../../../common/interfaces';
import {
  DefaultProjectDetail,
  ProjectDetail,
  ProjectDetailContext,
} from '../../../types/ProjectDetail';
import { useParams } from 'react-router';
import { Layout, LayoutTypes } from '../../../types/Layout';
import { getSelectedLayout } from '../../../helpers/selectedLayoutHelper/selectedLayoutHelper';
import ProjectControlPanel from '../../ProjectControlPanel/ProjectControlPanel';
import useAutosaveProject from '../../../hooks/useAutosaveProject';
import { UserTypes } from '../../../types/ProjectUser';
import useSignalRConnection from '../../../hooks/useSignalRConnection';
import { updateProjectDetailsFromSignalRData } from '../../../helpers/projectDetailsHelper/projectDetailsHelper';

type RouterParams = {
  projectId: string;
};

const ProducerContainer = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<RouterParams>();
  const [hubCreated, setHubCreated] = useState<boolean>(false);
  const [selectedLayout] = useState<LayoutTypes>(LayoutTypes.MAIN);
  const context: ProjectDetailContext = useAutosaveProject(DefaultProjectDetail);
  const {
    createHubConnection,
    isChatConnected,
    chatMessages,
    sendMessageToRoom,
    sendRequestControl,
    sendTakeControl,
    sendGiveControl,
    getPeerInfo,
    peerRequestingControl,
    connectedPeers,
    sendRefreshLiveVideo,
    triggerRefreshLiveVideo,
    sendReloadProjectDetails,
    projectDetailsFromSignalR,
    isAwaitingApiSaveToComplete,
    sendIsAwaitingApiSaveToComplete,
    isUserActive,
    isUserLive,
    sendReloadProxyVideo,
    myConnectionIsLive,
  } = useSignalRConnection();

  useEffect(() => {
    if (projectDetailsFromSignalR) {
      const newProjectDetails = updateProjectDetailsFromSignalRData(
        context.projectDetails as ProjectDetail,
        projectDetailsFromSignalR,
      );

      const newLayout = { ...newProjectDetails?.activeLayout } as Layout;
      const newProject = { ...newProjectDetails, activeLayout: newLayout } as ProjectDetail;

      if (newProject) {
        context.setProjectDetails(newProject);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetailsFromSignalR]);

  const { data: project, loading, error, refetch } = useGet({
    path: '/projects/' + projectId,
    resolve: (project: OkResponse<ProjectDetail>) => {
      const result = project.result;

      result.activeLayout = getSelectedLayout(result?.layouts, selectedLayout) || undefined;
      context.setProjectDetails(result);

      if (!hubCreated) {
        createHubConnection(
          result?.id ?? '',
          result?.createdByUserId,
          `Producer-${result?.id}`,
          UserTypes.PRODUCER,
          result?.createdByUserName ?? '',
          result?.messages ?? [],
        );
        setHubCreated(true);
      }
      return result;
    },
  });

  useEffect(() => {
    const projectDetails = context.projectDetails as ProjectDetail;

    if (
      typeof projectDetailsFromSignalR?.activeLayout?.hasCaption !== 'undefined' &&
      typeof projectDetailsFromSignalR?.activeLayout?.hasSigner !== 'undefined'
    ) {
      context.updateAssetsFromSignalR(
        projectDetailsFromSignalR?.activeLayout?.hasCaption,
        projectDetailsFromSignalR?.activeLayout?.hasSigner,
        projectDetailsFromSignalR?.activeLayout?.hasAiCaptions,
      );
    }

    context.setProjectDetails({
      ...projectDetails,
      activeLayout: {
        ...projectDetails.activeLayout,
        hasCaption: !!projectDetailsFromSignalR?.activeLayout?.hasCaption,
        hasSigner: !!projectDetailsFromSignalR?.activeLayout?.hasSigner,
        hasAiCaptions: !!projectDetailsFromSignalR?.activeLayout?.hasAiCaptions,
      } as Layout,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    projectDetailsFromSignalR?.activeLayout?.hasCaption,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    projectDetailsFromSignalR?.activeLayout?.hasSigner,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    projectDetailsFromSignalR?.activeLayout?.hasAiCaptions,
  ]);

  // catch all changes to context and broadcast to others.
  useEffect(() => {
    if (context.projectDetails) {
      sendReloadProjectDetails(context.projectDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.projectDetails]);

  useEffect(() => {
    sendIsAwaitingApiSaveToComplete(context.isAwaitingApiSaveToComplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isAwaitingApiSaveToComplete]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  if (!project) {
    return null;
  }

  const reloadProjectDetails = () => {
    refetch();
  };

  return (
    <ProjectDetailsProvider value={context}>
      <section>
        <ProducerHeader
          reloadProjectDetails={reloadProjectDetails}
          projectDetails={context.projectDetails}
          isProducer={true}
        />
        <div className="producer-container">
          <ProjectControlPanel
            userType={UserTypes.PRODUCER}
            token={`Producer-${context.projectDetails?.id}`}
            chatMessages={chatMessages}
            sendMessageToRoomCallback={sendMessageToRoom}
            isChatConnected={isChatConnected}
            projectDetails={context.projectDetails as ProjectDetail | undefined}
            sendReloadProjectDetails={sendReloadProjectDetails}
          />
          <ProjectMainSection
            projectDetails={context.projectDetails}
            isProducer={true}
            sendRequestControlCallback={sendRequestControl}
            sendTakeControlCallback={sendTakeControl}
            sendGiveControlCallback={sendGiveControl}
            getPeerInfoCallback={getPeerInfo}
            peerRequestingControl={peerRequestingControl}
            connectedPeers={connectedPeers}
            triggerRefreshLiveVideo={triggerRefreshLiveVideo}
            sendRefreshLiveVideoCallback={sendRefreshLiveVideo}
            isAwaitingApiSaveToComplete={isAwaitingApiSaveToComplete}
            isUserActiveCallback={isUserActive}
            isUserLiveCallback={isUserLive}
            myConnectionIsLive={myConnectionIsLive}
            sendReloadProxyVideo={sendReloadProxyVideo}
          />
        </div>
      </section>
    </ProjectDetailsProvider>
  );
};

export default ProducerContainer;
