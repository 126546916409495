import React, { useEffect, useState } from 'react';
import './ProjectUserContainer.scss';

import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import ProjectHeader from '../../ProjectHeaderBar/ProjectHeader';
import {
  DefaultProjectDetail,
  ProjectDetail,
  ProjectDetailContext,
} from '../../../types/ProjectDetail';
import ProjectMainSection from '../../ProjectMainSection/ProjectMainSection';
import ProjectControlPanel from '../../ProjectControlPanel/ProjectControlPanel';
import { useParams } from 'react-router-dom';
import { useGet } from 'restful-react';
import { OkResponse } from '../../../common/interfaces';
import { UserType } from '../../../types/ProjectUser';
import useSignalRConnection from '../../../hooks/useSignalRConnection';
import { getSelectedLayout } from '../../../helpers/selectedLayoutHelper/selectedLayoutHelper';
import { LayoutTypes } from '../../../types/Layout';
import { updateProjectDetailsFromSignalRData } from '../../../helpers/projectDetailsHelper/projectDetailsHelper';
import { AcceptTermsModal } from '../../Modals/AcceptTermsModal/AcceptTermsModal';
import useAutosaveProject from '../../../hooks/useAutosaveProject';
import ProjectDetailsProvider from '../../../contexts/project-details/ProjectDetailsProvider';
import { BroadcastedProjectModal } from '../../Modals/BroadcastedProjectModal/BroadcastedProjectModal';
import moment from 'moment';

type RouterParams = {
  token: string;
};

type ProjectContainerProps = {
  userType: UserType;
  hasAcceptedForTest?: boolean;
};

const ProjectContainer = ({ userType, hasAcceptedForTest }: ProjectContainerProps) => {
  const { t } = useTranslation();
  const context: ProjectDetailContext = useAutosaveProject(DefaultProjectDetail);

  const [currentProjectDetails, setCurrentProjectDetails] = useState<ProjectDetail>();
  const [hasAccepted, setHasAccepted] = useState<boolean>(hasAcceptedForTest ?? false);
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const [hasBroadcasted, setHasBroadcasted] = useState<boolean>(false);
  const [hubCreated, setHubCreated] = useState<boolean>(false);
  const { token } = useParams<RouterParams>();
  const {
    createHubConnection,
    isChatConnected,
    chatMessages,
    sendMessageToRoom,
    sendRequestControl,
    sendTakeControl,
    sendGiveControl,
    getPeerInfo,
    peerRequestingControl,
    connectedPeers,
    triggerRefreshLiveVideo,
    projectDetailsFromSignalR,
    sendReloadProjectDetails,
    isAwaitingApiSaveToComplete,
    isUserActive,
    isUserLive,
    sendReloadProxyVideo,
    myConnectionIsLive,
  } = useSignalRConnection();

  const { data: project, loading, error } = useGet({
    path: '/project-users/' + token,
    resolve: (project: OkResponse<ProjectDetail>) => {
      setHasFetched(true);
      const result = project.result;
      console.log(project.result);
      if (
        result.broadcastEndedOnUtc ||
        (moment(result.startDateTimeUtc).add(result.duration, 'minutes') < moment.utc() &&
          !result.broadcastStartedOnUtc &&
          !result.previewStartedOnUtc)
      ) {
        setHasBroadcasted(true);
        return;
      }

      result.activeLayout = getSelectedLayout(result?.layouts, LayoutTypes.MAIN) || undefined;
      context.setProjectDetails(result);

      if (!hubCreated) {
        createHubConnection(
          result?.id ?? '',
          result?.projectUserId ?? '',
          token,
          userType,
          result?.projectUserName ?? '',
          result?.messages ?? [],
        );
        setHubCreated(true);
      }

      setCurrentProjectDetails(result);
      return result;
    },
  });

  useEffect(() => {
    if (projectDetailsFromSignalR) {
      const newProjectDetails = updateProjectDetailsFromSignalRData(
        currentProjectDetails,
        projectDetailsFromSignalR,
      );
      if (newProjectDetails) {
        setCurrentProjectDetails(newProjectDetails);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetailsFromSignalR]);

  if (hasBroadcasted && hasFetched) {
    return <BroadcastedProjectModal />;
  }

  if (!hasAccepted && hasFetched) {
    return (
      <AcceptTermsModal
        userType={userType}
        isOpen={true}
        handleCloseModal={() => setHasAccepted(true)}
      />
    );
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  if (!project) {
    return null;
  }

  return (
    <ProjectDetailsProvider value={context}>
      <section>
        <ProjectHeader
          projectDetails={currentProjectDetails || null}
          isProducer={false}
          reloadProjectDetails={() => {
            return null;
          }}
        />
        <div className="project-container">
          <ProjectControlPanel
            userType={userType}
            token={token}
            chatMessages={chatMessages}
            sendMessageToRoomCallback={sendMessageToRoom}
            isChatConnected={isChatConnected}
            projectDetails={currentProjectDetails}
            sendReloadProjectDetails={sendReloadProjectDetails}
          />
          <ProjectMainSection
            isProducer={false}
            projectDetails={currentProjectDetails || null}
            token={token}
            sendRequestControlCallback={sendRequestControl}
            sendTakeControlCallback={sendTakeControl}
            sendGiveControlCallback={sendGiveControl}
            getPeerInfoCallback={getPeerInfo}
            peerRequestingControl={peerRequestingControl}
            connectedPeers={connectedPeers}
            triggerRefreshLiveVideo={triggerRefreshLiveVideo}
            isAwaitingApiSaveToComplete={isAwaitingApiSaveToComplete}
            isUserActiveCallback={isUserActive}
            isUserLiveCallback={isUserLive}
            myConnectionIsLive={myConnectionIsLive}
            sendReloadProxyVideo={sendReloadProxyVideo}
          />
        </div>
      </section>
    </ProjectDetailsProvider>
  );
};

export default ProjectContainer;
