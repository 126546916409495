import React, { useState } from 'react';

import TabBar from '../../components/Navbar/TabBar/TabBar';
import ProjectCardContainer from '../../components/ProjectOverviewComponents/ProjectCardContainer/ProjectCardContainer';
import { ProjectOverviewViews } from '../../types/ProjectOverviewView';

const ProjectOverview = () => {
  const [activeProjectView, setActiveProjectView] = useState<ProjectOverviewViews>(
    ProjectOverviewViews.ACTIVE,
  );

  return (
    <div>
      <TabBar
        onChangeProjectView={(activeView: ProjectOverviewViews) => {
          setActiveProjectView(activeView);
        }}
        activeProjectView={activeProjectView}
      />
      <ProjectCardContainer
        activeProjectView={activeProjectView}
        onChangeProjectView={(activeView: ProjectOverviewViews) => {
          setActiveProjectView(activeView);
        }}
      />
    </div>
  );
};

export default ProjectOverview;
