import React, { useState } from 'react';
import { useMutate } from 'restful-react';
import './SupportRequestForm.scss';
import { Button } from '../FormComponents/Buttons/Button';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { ValidationError } from '../FormComponents/ValidationError/ValidationError';
import { TextArea } from '../FormComponents/TextArea/TextArea';
import useUserDetails from '../../hooks/useUserDetails';

type SupportRequestFormData = {
  name: string;
  emailAddress: string;
  message: string;
};

export const SupportRequestForm = () => {
  const { t } = useTranslation();
  const { user } = useUserDetails();
  const [sentSuccessfully, setSentSuccessfully] = useState<boolean>(false);

  const defaultValues: SupportRequestFormData = {
    name: user?.name || '',
    emailAddress: user?.email || '',
    message: '',
  };

  const { control, errors, handleSubmit } = useForm<SupportRequestFormData>({
    defaultValues,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessfulCreate = (body: any, data: any) => {
    if (data.statusCode === 200) {
      setSentSuccessfully(true);
    }
  };

  const {
    mutate: createSupportRequest,
    loading: loadingSupportRequest,
    error: errorSupportRequest,
  } = useMutate<SupportRequestFormData>({
    verb: 'POST',
    path: '/accounts/send-support-request',
    onMutate: onSuccessfulCreate,
  });

  const onSubmit: SubmitHandler<SupportRequestFormData> = (data: SupportRequestFormData) => {
    data.emailAddress = user?.email || '';
    data.name = user?.name || '';
    createSupportRequest(data);
  };

  if (errorSupportRequest) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  if (sentSuccessfully) {
    return (
      <div className="support-request-form-container">
        <p className="support-request-form-success">{t('help.send-support-request-complete')}</p>
      </div>
    );
  }

  return (
    <div className="support-request-form-container">
      <p>
        {t('help.support-info-part-one')}
        <br />
        <br />
        {t('help.support-info-part-two')}
      </p>
      <div className="column support-request-form-wrapper">
        <form className="support-request-form-component" onSubmit={handleSubmit(onSubmit)}>
          <ValidationError
            errorType={errors.message}
            errorMessageKey={errors.message?.message ?? ''}
          />
          <Controller
            name="message"
            control={control}
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <TextArea
                placeholder="help.support-message-placeholder"
                value={value}
                onChange={onChange}
                errorType={errors.message}
                errorMessageKey={'help.support-message-required'}
                classOverride={'support-request-form-textarea'}
              />
            )}
          />
          <br />
          <Button
            translationKey="help.send-support-request"
            variant="primary"
            color="blue"
            onClick={() => null}
            type="submit"
            disabled={loadingSupportRequest}
          />
        </form>
      </div>
      <p className="support-form-footer">{t('help.support-form-info')}</p>
    </div>
  );
};
