import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectDetail } from '../../../types/ProjectDetail';
import { UserTypes } from '../../../types/ProjectUser';
import { Peer, Peers } from '../../../types/SignalR';
import { Button } from '../../FormComponents/Buttons/Button';
import TranslationHtml from '../../TranslationHtml/TranslationHtml';

import './ProjectUserFooter.scss';

type ProjectUserFooter = {
  token?: string;
  sendRequestControlCallback: () => void;
  sendTakeControlCallback: () => void;
  sendGiveControlCallback: () => void;
  getPeerInfoCallback: (peerId: string) => Peer | null;
  peerRequestingControl: Peer | null;
  connectedPeers: Peers;
  projectDetails: ProjectDetail | null;
  sendStopSignerVideoCallback?: () => void;
  sendStartSignerVideoCallback?: () => void;
  signerVideoIsStarted?: boolean;
};

const ProjectUserFooter = ({
  token,
  sendRequestControlCallback,
  sendTakeControlCallback,
  sendGiveControlCallback,
  getPeerInfoCallback,
  peerRequestingControl,
  connectedPeers,
  projectDetails,
  sendStopSignerVideoCallback,
  sendStartSignerVideoCallback,
  signerVideoIsStarted,
}: ProjectUserFooter) => {
  const [requestedControl, setRequestedControl] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleRequestControl = () => {
    setRequestedControl(true);
    sendRequestControlCallback();
  };

  const handleTakeControl = () => {
    setRequestedControl(false);
    sendTakeControlCallback();
  };

  const handleGiveControl = () => {
    setRequestedControl(false);
    sendGiveControlCallback();
  };

  useEffect(() => {
    setRequestedControl(false);
  }, [connectedPeers]);

  if (projectDetails?.broadcastEndedOnUtc || projectDetails?.deletedOnUtc) {
    return null;
  }

  return (
    <div className={(peerRequestingControl ? 'active ' : '') + 'main-section-footer level'}>
      <div className="columns">
        <div className="column is-full control-switcher">
          {getPeerInfoCallback(token ?? '')?.isCurrentlyLive && !peerRequestingControl ? (
            <>
              <div className="stop-start-wrapper">
                {sendStopSignerVideoCallback && signerVideoIsStarted ? (
                  <Button
                    translationKey="button.stop-signer-video"
                    variant="secondary"
                    color="red"
                    additionalClassName="btn-no-margin"
                    onClick={sendStopSignerVideoCallback}
                    type="button"
                  />
                ) : null}

                {sendStartSignerVideoCallback && !signerVideoIsStarted ? (
                  <Button
                    translationKey="button.start-signer-video"
                    variant="primary"
                    color="green"
                    additionalClassName="btn-no-margin"
                    onClick={sendStartSignerVideoCallback}
                    type="button"
                  />
                ) : null}
              </div>
              <p className="is-currently-live-text">
                {getPeerInfoCallback(token ?? '')?.userType === UserTypes.CAPTIONER
                  ? t('labels.is-currently-live-captioner')
                  : t('labels.is-currently-live-signer')}
              </p>
            </>
          ) : null}
          {!getPeerInfoCallback(token ?? '')?.isCurrentlyLive ? (
            <Button
              translationKey="button.request-control"
              variant="secondary"
              color="blue"
              additionalClassName="btn-no-margin"
              onClick={handleRequestControl}
              type="button"
              disabled={requestedControl}
            />
          ) : null}{' '}
          {requestedControl && (
            <>
              <Button
                translationKey="button.take-control"
                variant="primary"
                color="pink"
                additionalClassName="btn-no-margin"
                onClick={handleTakeControl}
                type="button"
              />
              <p className="take-control-notice">{t('project-user.footer.take-control-notice')}</p>
            </>
          )}
          {peerRequestingControl ? (
            <>
              <TranslationHtml
                htmlString={t('project-user.footer.user-requested-control', {
                  user: peerRequestingControl?.userName,
                })}
                className={'user-requested'}
              />
              <Button
                translationKey="button.give-control"
                variant="secondary"
                color="blue"
                additionalClassName="btn-no-margin"
                onClick={handleGiveControl}
                type="button"
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProjectUserFooter;
