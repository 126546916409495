import React from 'react';
import { ProjectDetail } from '../../../types/ProjectDetail';
import { Peers } from '../../../types/SignalR';
import MainSectionProjectUsers from '../MainSectionProjectUsers/MainSectionProjectUsers';
import MainSectionStreamTextButton from '../MainSectionStreamTextButton/MainSectionStreamTextButton';

import './MainSectionHeader.scss';

type MainSectionHeaderProps = {
  projectDetails: ProjectDetail | null;
  isUserActiveCallback: (userId: string) => boolean;
  isUserLiveCallback: (userId: string) => boolean;
  connectedPeers: Peers;
};

const MainSectionHeader = (props: MainSectionHeaderProps) => {
  return (
    <div className="project-main-section__header">
      <MainSectionProjectUsers {...props} />
      <MainSectionStreamTextButton {...props.projectDetails} />
    </div>
  );
};

export default MainSectionHeader;
