import React from 'react';
import './ToggleButton.scss';

type ToggleButtonProps = {
  leftImage?: string;
  rightImage?: string;
  leftText?: string;
  rightText?: string;
  leftValue?: string | number | boolean;
  rightValue?: string | number | boolean;
  leftButtonClassName?: string;
  rightButtonClassName?: string;
  leftContentClassName?: string;
  rightContentClassName?: string;
  footerText: string;
  isReadOnly: boolean;
  onSelection: (selectedButton: string | number | boolean) => void;
  leftAccessibilityName?: string;
  rightAccessibilityName?: string;
  hasMiddleButton?: boolean;
  middleImage?: string;
  middleText?: string;
  middleValue?: string | number | boolean;
  middleButtonClassName?: string;
  middleContentClassName?: string;
  middleAccessibilityName?: string;
};

const CaptioningPanel = ({
  leftImage = '',
  leftText = '',
  leftValue = '',
  leftButtonClassName = '',
  leftContentClassName = '',
  leftAccessibilityName = '',

  hasMiddleButton = false,
  middleImage = '',
  middleText = '',
  middleValue = '',
  middleButtonClassName = '',
  middleContentClassName = '',
  middleAccessibilityName = '',

  rightImage = '',
  rightText = '',
  rightValue = '',
  rightButtonClassName = '',
  rightContentClassName = '',
  rightAccessibilityName = '',

  footerText,
  onSelection,
  isReadOnly,
}: ToggleButtonProps) => {
  return (
    <div
      className={`control-panel-toggle-button-wrapper ${
        hasMiddleButton ? 'control-panel-toggle-button-wrapper-middle' : ''
      }`}
    >
      <button
        className={`control-panel-toggle-button control-panel-toggle-button-left ${
          leftButtonClassName !== null ? leftButtonClassName : ''
        }`}
        onClick={() => onSelection(leftValue)}
        disabled={isReadOnly}
        aria-label={leftAccessibilityName}
      >
        {leftImage && (
          <img src={leftImage} alt="select left" className="control-panel-toggle-button-icon" />
        )}
        {leftText && (
          <span
            className={`control-panel-toggle-button-text ${
              leftContentClassName !== null ? leftContentClassName : ''
            }`}
          >
            {leftText}
          </span>
        )}
      </button>
      {hasMiddleButton && (
        <button
          className={`control-panel-toggle-button control-panel-toggle-button-middle ${
            middleButtonClassName !== null ? middleButtonClassName : ''
          }`}
          onClick={() => onSelection(middleValue)}
          disabled={isReadOnly}
          aria-label={middleAccessibilityName}
        >
          {middleImage && (
            <img
              src={middleImage}
              alt="select middle"
              className={`control-panel-toggle-button-icon ${
                middleContentClassName !== null ? middleContentClassName : ''
              }`}
            />
          )}
          {middleText && (
            <span
              className={`control-panel-toggle-button-text ${
                middleContentClassName !== null ? middleContentClassName : ''
              }`}
            >
              {middleText}
            </span>
          )}
        </button>
      )}
      <button
        className={`control-panel-toggle-button control-panel-toggle-button-right ${
          rightButtonClassName !== null ? rightButtonClassName : ''
        }`}
        onClick={() => onSelection(rightValue)}
        disabled={isReadOnly}
        aria-label={rightAccessibilityName}
      >
        {rightImage && (
          <img
            src={rightImage}
            alt="select right"
            className={`control-panel-toggle-button-icon ${
              rightContentClassName !== null ? rightContentClassName : ''
            }`}
          />
        )}
        {rightText && (
          <span
            className={`control-panel-toggle-button-text ${
              rightContentClassName !== null ? rightContentClassName : ''
            }`}
          >
            {rightText}
          </span>
        )}
      </button>

      <div
        className={`control-panel-toggle-button-footer ${
          hasMiddleButton ? 'control-panel-toggle-button-footer-middle' : ''
        }`}
      >
        {footerText}
      </div>
    </div>
  );
};

export default CaptioningPanel;
