import { useCallback, useEffect, useState } from 'react';
import { useMutate } from 'restful-react';
import { ProjectDetail } from '../types/ProjectDetail';
import { Layout } from '../types/Layout';

const useAutosaveProject = (dataToSave: ProjectDetail) => {
  const [projectDetails, setProjectDetails] = useState<ProjectDetail>(dataToSave);

  const [isSavingLayout, setIsSavingLayout] = useState<boolean>(false);
  const [isAwaitingApiSaveToComplete, setIsAwaitingApiSaveToComplete] = useState<boolean>(false);
  const [awaitingSaveProjectLayout, setAwaitingSaveProjectLayout] = useState<Layout | null>(null);
  const [triggerSaveAwaitingData, setTriggerSaveAwaitingData] = useState<boolean>(false);

  const onSaveProjectLayout = () => {
    setTriggerSaveAwaitingData(true);
    setIsAwaitingApiSaveToComplete(false);
  };

  const { mutate: updateProjectLayout } = useMutate({
    verb: 'PUT',
    path: `/projects/${projectDetails.id}/layouts/${projectDetails.activeLayout?.id}`,
    onMutate: onSaveProjectLayout,
  });

  const triggerUpdateProjectLayout = (awaitingLayout: Layout) => {
    setIsSavingLayout(true);
    setIsAwaitingApiSaveToComplete(true);
    updateProjectLayout(awaitingLayout).then((data) => {
      if (data.result && !projectDetails.streamTextEventName) {
        if (projectDetails.activeLayout) {
          projectDetails.activeLayout = awaitingLayout;
          projectDetails.activeLayout.hasCaption = true;
        }

        projectDetails.streamTextEventName = data.result;

        setProjectDetails(projectDetails);
      }
    });
  };

  useEffect(() => {
    if (triggerSaveAwaitingData) {
      setIsSavingLayout(false);
      if (awaitingSaveProjectLayout !== null) {
        triggerUpdateProjectLayout(awaitingSaveProjectLayout);
        setAwaitingSaveProjectLayout(null);
      }

      setTriggerSaveAwaitingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSaveAwaitingData]);

  const updateActiveLayout = (property: string, value: string | number | boolean) => {
    const newLayout = { ...projectDetails.activeLayout, [property]: value } as Layout;
    const newProject = { ...projectDetails, activeLayout: newLayout };

    setProjectDetails(newProject);

    if (isSavingLayout) {
      setAwaitingSaveProjectLayout(newLayout);
    } else {
      triggerUpdateProjectLayout(newLayout);
    }
  };

  const updateActiveLayoutWithMultiple = (
    properties: Record<string, string | number | boolean>,
  ) => {
    let newLayout = { ...projectDetails.activeLayout } as Layout;

    for (const key in properties) {
      newLayout = { ...newLayout, [key]: properties[key] } as Layout;
    }

    const newProject = { ...projectDetails, activeLayout: newLayout };

    setProjectDetails(newProject);

    if (isSavingLayout) {
      setAwaitingSaveProjectLayout(newLayout);
    } else {
      triggerUpdateProjectLayout(newLayout);
    }
  };

  // only triggered by Signer or Captioner view setting signer or captioner on or off, not triggered by producer
  const updateAssetsFromSignalR = (
    hasCaption: boolean,
    hasSigner: boolean,
    hasAiCaptions: boolean,
  ) => {
    const newProject: ProjectDetail = {
      ...projectDetails,
      activeLayout: {
        ...projectDetails.activeLayout,
        hasCaption,
        hasSigner,
        hasAiCaptions,
      } as Layout,
    };
    const newLayout = { ...newProject.activeLayout } as Layout;

    setProjectDetails(newProject);

    if (isSavingLayout) {
      setAwaitingSaveProjectLayout(newLayout);
    } else {
      triggerUpdateProjectLayout(newLayout);
    }
  };

  const saveData = useCallback((newData: ProjectDetail) => {
    setProjectDetails(newData);
  }, []);

  useEffect(() => {
    if (projectDetails) {
      saveData(projectDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetails]);

  return {
    projectDetails,
    setProjectDetails,
    updateActiveLayout,
    updateAssetsFromSignalR,
    isAwaitingApiSaveToComplete,
    updateActiveLayoutWithMultiple,
  };
};

export default useAutosaveProject;
