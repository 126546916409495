import React from 'react';
import './BaseProjectCard.scss';

type BaseProjectCardProps = {
  isDim?: boolean;
};

const BaseProjectCard: React.FC<BaseProjectCardProps> = ({ isDim = false, children }) => {
  return <div className={`base-project-card ${isDim ? 'is-dim' : ''}`}>{children}</div>;
};

export default BaseProjectCard;
