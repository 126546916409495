import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountDetails } from '../../../types/AccountDetails';
import { UserDetailsModal } from '../../Modals/UserDetailsModal/UserDetailsModal';
import useStripeCheckout from '../../../hooks/useStripeCheckout';
import env from '../../../Env';

import './UserDetails.scss';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

type Props = {
  user: AccountDetails;
  handleUserDetailChange: (name: string, email: string) => void;
};

export const UserDetails = (props: Props) => {
  const [showNameModal, setShowNameModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const { t } = useTranslation();
  const { createSession, loadingSession } = useStripeCheckout();

  const handleChange = (name: string, email: string) => {
    props.handleUserDetailChange(name, email);
  };

  return (
    <div className="user-details">
      <div>
        <h2 className="user-details__name">{props.user.name}</h2>
        <button className="user-details__change" onClick={() => setShowNameModal(true)}>
          {t('account.change-name')}
        </button>
        <UserDetailsModal
          currentValue={props.user.name}
          isOpen={showNameModal}
          handleCloseModal={() => setShowNameModal(false)}
          onSubmit={(newName) => handleChange(newName, props.user.email)}
          placeholder={'account.user-details.name-placeholder'}
        />
        <hr />
      </div>
      <div>
        <p className="user-details__text">{props.user.email}</p>
        <button className="user-details__change" onClick={() => setShowEmailModal(true)}>
          {t('account.change-email')}
        </button>
        <UserDetailsModal
          currentValue={props.user.email}
          isOpen={showEmailModal}
          handleCloseModal={() => setShowEmailModal(false)}
          onSubmit={(newEmail) => handleChange(props.user.name, newEmail)}
          placeholder={'account.user-details.email-placeholder'}
        />
      </div>
      <div>
        {!loadingSession ? (
          <button
            className="user-details__change"
            onClick={() => createSession({ stripePriceId: env.stripeSignUpPriceId })}
          >
            {t('account.update-payment-details')}
          </button>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
};
