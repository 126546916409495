import moment from 'moment';
import { timezones } from '../../common/constants';

export const formatString = 'YYYY-MM-DDTHH:mm:ss.000Z';

export const getFriendlyDuration = (time: number) => {
  if (time < 0) {
    return `0m`;
  }
  const days = Math.floor(time / 60 / 24);
  time -= days * 60 * 24;
  const hours = Math.floor(time / 60);
  time -= hours * 60;
  const minutes = (time % 60).toFixed(0);
  if (days > 0) {
    return `${days}d ${hours}h ${minutes}m`;
  }
  return `${hours}h ${minutes}m`;
};

export const getFriendlyScheduledDuration = (time: number) => {
  if (time < 0) {
    return `00:00:00`;
  }
  const days = Math.floor(time / 60 / 24);
  time -= days * 60 * 24;
  const hours = Math.floor(time / 60);
  time -= hours * 60;
  const minutes = (time % 60).toFixed(0);

  return `${(days * 24 + hours).toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:00`;
};

export const getFriendlyElapsedDuration = (time: string) => {
  if (!time) {
    time = '00:00:00';
  }

  return time.split('.')[0];
};

export const getFriendlyShortDate = (date: string | undefined) => {
  if (!date) {
    return '';
  }
  return moment(date).format('DD MMM YYYY');
};

export const getFriendlyDate = (date: string | undefined) => {
  if (!date) {
    return '';
  }
  return moment(date).format('DD MMM YYYY - HH:mm');
};

export const getFriendlyTime = (date: string | undefined) => {
  if (!date) {
    return '';
  }
  return moment(date).format('HH:mm');
};

export const getDateDifferenceFromNow = (date: string | undefined) => {
  if (!date) {
    return 0;
  }

  const utcMoment = moment.utc();
  return moment(date).diff(moment(utcMoment));
};

export const isDateTimeInThePast = (date: Date, timezone: string) => {
  const result = timezones.find((x) => x.value === timezone);

  if (!result) return;

  const currentDateTime = moment();
  const selectedDateTime = moment(date);
  const tokens = result.baseUtcOffset.split(':');
  currentDateTime.utcOffset(parseInt(tokens[0]));

  return selectedDateTime.isBefore(currentDateTime);
};

export const formatDate = (date: string | Date | undefined): string => {
  if (typeof date === 'string') {
    return moment(date).format(formatString);
  }
  if (date instanceof Date) {
    return moment(date as Date).format(formatString);
  }

  return moment().format(formatString);
};

export const getUTCDateNow = () => {
  return moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
};

export const isDaylightSavings = (date: string | Date, utcOffset = ''): boolean => {
  if (!utcOffset) {
    return moment(date).isDST();
  }

  return moment(date).utcOffset(utcOffset).isDST();
};

export const mergeDateAndTime = (date: string, time: string): string => {
  const m = moment(formatDate(date));

  if (time === '') {
    return m.format(formatString);
  }
  const tokens = time.split(':');

  m.set({ h: parseInt(tokens[0]), m: parseInt(tokens[1]) });

  return m.format(formatString);
};

export const getTimezoneName = (date: string | Date, time: string, timeZone = '') => {
  const merged = mergeDateAndTime(formatDate(date), time);

  if (isDaylightSavings(merged)) {
    return timeZone.replace('Standard', 'Summer');
  }

  return timeZone.replace('Summer', 'Standard');
};

export const isValidTime = (time: string, date: string, timezone: string) => {
  const zone = getTimezoneName(date, time, timezone);

  const merged = mergeDateAndTime(date, time);

  return isDateTimeInThePast(moment(merged).toDate(), zone) ? 'create-project.time-in-past' : true;
};

export const isValidTimeZone = (timeZone: string): boolean => (timeZone === 'null' ? false : true);

export const getBaseUtcOffset = (timeZone: string): string => {
  if (!timeZone) {
    return '00:00';
  }

  const results = timezones.filter((x) => x.value === timeZone);

  if (results.length === 0) {
    return '';
  }

  return results[0].baseUtcOffset;
};
