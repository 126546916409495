import React from 'react';

import './TabBar.scss';

// import ProjectSearch from '../../ProjectOverviewComponents/ProjectSearch/ProjectSearch';
import { useTranslation } from 'react-i18next';
import { ProjectOverviewViews } from '../../../types/ProjectOverviewView';

type TabBarProps = {
  onChangeProjectView: (activeView: ProjectOverviewViews) => void;
  activeProjectView: ProjectOverviewViews;
};

const TabBar = ({ onChangeProjectView, activeProjectView }: TabBarProps) => {
  const { t } = useTranslation();

  return (
    <div className="tabbar">
      <div className="tabbar-link-wrapper">
        <button
          className={`tabbar-link ${
            activeProjectView === ProjectOverviewViews.ACTIVE ? 'active' : ''
          }`}
          onClick={() => onChangeProjectView(ProjectOverviewViews.ACTIVE)}
        >
          {t('project-overview.tabbar.active-projects')}
        </button>
        <button
          className={`tabbar-link ${
            activeProjectView === ProjectOverviewViews.PREVIOUS ||
            activeProjectView === ProjectOverviewViews.SUMMARY
              ? 'active'
              : ''
          }`}
          onClick={() => onChangeProjectView(ProjectOverviewViews.PREVIOUS)}
        >
          {t('project-overview.tabbar.previous-projects')}
        </button>
      </div>
      <div className="tabbar-search-wrapper">{/* <ProjectSearch /> */}</div>
    </div>
  );
};

export default TabBar;
