import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useProjectDetails from '../../../hooks/useProjectDetails';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from '@reach/accordion';
import '@reach/accordion/styles.css';
import './ProducerControlPanel.scss';

import ExpandCollapseIcon from '../../../images/back-arrow-blue.png';
// import ChatPanelIcon from '../../../images/chat-panel-Icon.png';

import StreamSourcePanel from './Panels/StreamSourcePanel/StreamSourcePanel';
// import SigningPanel from './Panels/SigningPanel/SigningPanel';
import StreamDestinationsPanel from './Panels/StreamDestinationsPanel/StreamDestinationsPanel';
import CaptioningPanel from './Panels/CaptioningPanel/CaptioningPanel';
import Chat from '../../Chat/Chat';
import { Messages } from '../../../types/Message';
import _ from 'lodash';
import { getDateDifferenceFromNow } from '../../../helpers/dateFormatHelper/dateFormatHelper';
import moment from 'moment';
// import StreamSettings from './Panels/StreamSettingsPanel/StreamSettings';
import { ProjectDetail } from '../../../types/ProjectDetail';

type ProducerControlPanelProps = {
  chatMessages: Messages;
  sendMessageToRoomCallback: (messageToSend: string) => void;
  isChatConnected: boolean;
  projectDetails?: ProjectDetail;
  sendReloadProjectDetails: (projectDetails: ProjectDetail) => void;
};

const ProducerControlPanel = ({
  chatMessages,
  sendMessageToRoomCallback,
  isChatConnected,
  projectDetails,
  sendReloadProjectDetails,
}: ProducerControlPanelProps) => {
  const { t } = useTranslation();
  const { updateActiveLayout, setProjectDetails } = useProjectDetails();

  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [, setIsProjectStarted] = useState<boolean>(false);
  const [, setIsProjectStartDatePassed] = useState<boolean>(false);
  const [destinationDots, setDestinationDots] = useState<object>();

  const createDestinationDots = useCallback(() => {
    if (!projectDetails || !projectDetails.activeLayout || !projectDetails?.consumers) {
      return null;
    }
    const destinationDotsCount = projectDetails?.activeLayout?.hasClosedCaptions ? 4 : 10;
    const destinationDots = [];

    if (projectDetails?.consumers.length >= destinationDotsCount) {
      for (let x = 0; x < destinationDotsCount; x++) {
        destinationDots.push(<span key={x} className="control-panel-multi-dot"></span>);
      }
    } else {
      for (let x = 0; x < projectDetails?.consumers.length; x++) {
        destinationDots.push(<span key={x} className="control-panel-multi-dot"></span>);
      }
      for (let y = 0; y < destinationDotsCount - projectDetails?.consumers.length; y++) {
        destinationDots.push(
          <span
            key={y + destinationDotsCount}
            className="control-panel-multi-dot control-panel-multi-dot-grey"
          ></span>,
        );
      }
    }

    return setDestinationDots(destinationDots);
  }, [projectDetails, setDestinationDots]);

  const updateStreamTextEventInProjectDetails = (hasCaptions: boolean) => {
    updateActiveLayout('hasCaption', hasCaptions);
    if (hasCaptions) {
      return;
    }
    const newProjectDetails = _.cloneDeep(projectDetails);
    if (newProjectDetails?.streamTextEventName && newProjectDetails.activeLayout) {
      newProjectDetails.streamTextEventName = undefined;
      newProjectDetails.activeLayout.hasCaption = hasCaptions;
      setProjectDetails(newProjectDetails);
    }
  };

  const toggleCaptionAsset = (hasCaptions: boolean) => {
    updateActiveLayout('hasCaption', hasCaptions);
    const newProjectDetails = _.cloneDeep(projectDetails);
    if (newProjectDetails?.activeLayout) {
      newProjectDetails.activeLayout.hasCaption = hasCaptions;
      setProjectDetails(newProjectDetails);
      sendReloadProjectDetails && sendReloadProjectDetails(newProjectDetails);
    }
  };

  const updateCaptioningAssetDetails = (hasCaptions: boolean) => {
    if (projectDetails?.broadcastStartedOnUtc) {
      toggleCaptionAsset(hasCaptions);
    } else {
      updateStreamTextEventInProjectDetails(hasCaptions);
    }
  };

  useEffect(() => {
    const isProjectBroadcastFinished = () => {
      if (
        (projectDetails?.broadcastStartedOnUtc && projectDetails?.broadcastEndedOnUtc) ||
        projectDetails?.deletedOnUtc
      ) {
        setIsReadOnly(true);
      }
    };
    const isProjectStarted = () => {
      if (!!projectDetails?.broadcastStartedOnUtc || !!projectDetails?.previewStartedOnUtc) {
        setIsProjectStarted(true);
      }
    };
    isProjectBroadcastFinished();
    isProjectStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetails]);

  useEffect(() => {
    const checkIfStartDateHasPassed = () => {
      if (
        !isReadOnly &&
        projectDetails?.startDateTimeUtc &&
        moment.duration(getDateDifferenceFromNow(projectDetails?.startDateTimeUtc)).asMinutes() < 1
      ) {
        setIsProjectStartDatePassed(true);
      }
    };
    const interval = setInterval(() => checkIfStartDateHasPassed(), 30000);
    checkIfStartDateHasPassed();
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetails]);

  useEffect(() => {
    createDestinationDots();
  }, [projectDetails, createDestinationDots]);

  return (
    <Accordion className="control-panel-accordion" collapsible={true} defaultIndex={0}>
      {/* Stream Source */}
      <AccordionItem className="control-panel-accordion-item">
        <h3>
          <AccordionButton className="control-panel-accordion-button">
            <img
              src={ExpandCollapseIcon}
              alt="open icon"
              className="control-panel-accordion-button-arrow"
            />
            {t('producer.control-panel.stream-source.heading')}
            <span className="control-panel-single-dot"></span>
          </AccordionButton>
        </h3>
        <AccordionPanel className="control-panel-accordion-panel">
          <StreamSourcePanel
            sourceKey={projectDetails?.ingestKey}
            sourceUrl={projectDetails?.ingestUrl}
            selectedLayout={projectDetails?.activeLayout}
          />
        </AccordionPanel>
      </AccordionItem>
      {/* Stream Destinations */}
      {/* Captioning */}
      <AccordionItem>
        <h3>
          <AccordionButton className="control-panel-accordion-button">
            <img
              src={ExpandCollapseIcon}
              alt="open icon"
              className="control-panel-accordion-button-arrow"
            />
            {t('producer.control-panel.captioning.heading')}
          </AccordionButton>
          <div className="control-panel-accordion-button-on-off">
            <button
              className="control-panel-accordion-button-toggle-button"
              onClick={(event) => {
                event.stopPropagation();
                updateActiveLayout('hasCaption', !projectDetails?.activeLayout?.hasCaption);
              }}
            >
              <span className="control-panel-accordion-button-on-off-button-back"></span>
              {projectDetails?.activeLayout && projectDetails?.activeLayout.hasCaption ? (
                <>
                  <span className="control-panel-accordion-button-on-off-text">
                    {t('labels.on')}
                  </span>
                  <span className="control-panel-accordion-button-on-off-button-switch"></span>
                </>
              ) : (
                <>
                  <span className="control-panel-accordion-button-on-off-text">
                    {t('labels.off')}
                  </span>
                  <span className="control-panel-accordion-button-on-off-button-switch control-panel-accordion-button-on-off-button-switch-off"></span>{' '}
                </>
              )}
            </button>
          </div>
        </h3>
        <AccordionPanel className="control-panel-accordion-panel">
          <CaptioningPanel
            selectedLayout={projectDetails?.activeLayout}
            projectUsers={projectDetails?.projectUsers || []}
            isReadOnly={isReadOnly}
            updateAssetDetails={(hasCaptions) => updateCaptioningAssetDetails(hasCaptions)}
          />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h3>
          <AccordionButton className="control-panel-accordion-button">
            <img
              src={ExpandCollapseIcon}
              alt="open icon"
              className="control-panel-accordion-button-arrow"
            />
            {t('producer.control-panel.stream-destinations.heading')}
            <div className="control-panel-accordion-button-arrow-multidots">{destinationDots}</div>
          </AccordionButton>
        </h3>
        <AccordionPanel className="control-panel-accordion-panel">
          <StreamDestinationsPanel
            consumers={projectDetails?.consumers}
            mainLayoutId={projectDetails?.activeLayout?.id}
            hasClosedCaptions={projectDetails?.activeLayout?.hasClosedCaptions}
            onUpdate={() => {
              createDestinationDots();
            }}
          />
        </AccordionPanel>
      </AccordionItem>

      {/* Signing */}
      {/* <AccordionItem>
        <h3>
          <AccordionButton className="control-panel-accordion-button">
            <img
              src={ExpandCollapseIcon}
              alt="open icon"
              className="control-panel-accordion-button-arrow"
            />
            {t('producer.control-panel.signing.heading')}
          </AccordionButton>
          <div className="control-panel-accordion-button-on-off">
            <button
              className="control-panel-accordion-button-toggle-button"
              onClick={(event) => {
                event.stopPropagation();
                updateActiveLayout('hasSigner', !projectDetails?.activeLayout?.hasSigner);
              }}
            >
              <span className="control-panel-accordion-button-on-off-button-back"></span>
              {projectDetails?.activeLayout && projectDetails?.activeLayout.hasSigner ? (
                <>
                  <span className="control-panel-accordion-button-on-off-text">
                    {t('labels.on')}
                  </span>
                  <span className="control-panel-accordion-button-on-off-button-switch"></span>
                </>
              ) : (
                <>
                  <span className="control-panel-accordion-button-on-off-text">
                    {t('labels.off')}
                  </span>
                  <span className="control-panel-accordion-button-on-off-button-switch control-panel-accordion-button-on-off-button-switch-off"></span>{' '}
                </>
              )}
            </button>
          </div>
        </h3>
        <AccordionPanel className="control-panel-accordion-panel">
          <SigningPanel
            selectedLayout={projectDetails?.activeLayout}
            projectUsers={projectDetails?.projectUsers || []}
            isReadOnly={isReadOnly}
            updateAssetDetails={(hasSigner) => updateActiveLayout('hasSigner', hasSigner)}
          />
        </AccordionPanel>
      </AccordionItem> */}

      {/* Stream Settings */}
      {/* <AccordionItem className="control-panel-accordion-item">
        <h3>
          <AccordionButton className="control-panel-accordion-button">
            <img
              src={ExpandCollapseIcon}
              alt="open icon"
              className="control-panel-accordion-button-arrow"
            />
            {t('producer.control-panel.stream-settings.heading')}
          </AccordionButton>
        </h3>
        <AccordionPanel className="control-panel-accordion-panel">
          <StreamSettings selectedLayout={projectDetails?.activeLayout} isReadOnly={false} />
        </AccordionPanel>
      </AccordionItem> */}
      {/* Chat */}
      <AccordionItem>
        <h3>
          <AccordionButton className="control-panel-accordion-button">
            <img
              src={ExpandCollapseIcon}
              alt="open icon"
              className="control-panel-accordion-button-arrow"
            />
            {t('producer.control-panel.chat.heading')}
            {/* <img src={ChatPanelIcon} alt="chat panel" className="control-panel-chat-panel-icon" /> */}
          </AccordionButton>
        </h3>
        <AccordionPanel className="control-panel-accordion-panel">
          <Chat
            chatMessages={chatMessages}
            userId={`Producer-${projectDetails?.id}`}
            sendMessageToRoomCallback={sendMessageToRoomCallback}
            isChatConnected={isChatConnected}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default ProducerControlPanel;
