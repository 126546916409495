import React from 'react';
import { ProjectDetail } from '../../../types/ProjectDetail';
import { Peer, Peers } from '../../../types/SignalR';
import ProducerFooter from '../../ProducerComponents/ProducerFooter/ProducerFooter';
import ProjectUserFooter from '../../ProjectUserComponents/ProjectUserFooter/ProjectUserFooter';

import './MainSectionFooter.scss';

type MainSectionFooterProps = {
  token?: string;
  isProducer: boolean;
  sendRequestControlCallback: () => void;
  sendTakeControlCallback: () => void;
  sendGiveControlCallback: () => void;
  getPeerInfoCallback: (peerId: string) => Peer | null;
  peerRequestingControl: Peer | null;
  connectedPeers: Peers;
  sendRefreshLiveVideoCallback?: () => void;
  projectDetails: ProjectDetail | null;
  sendStopSignerVideoCallback?: () => void;
  sendStartSignerVideoCallback?: () => void;
  signerVideoIsStarted?: boolean;
};
const MainSectionFooter = (props: MainSectionFooterProps) => {
  if (props.isProducer) {
    return <ProducerFooter sendRefreshLiveVideoCallback={props.sendRefreshLiveVideoCallback} />;
  }

  return (
    <ProjectUserFooter
      sendRequestControlCallback={props.sendRequestControlCallback}
      sendTakeControlCallback={props.sendTakeControlCallback}
      sendGiveControlCallback={props.sendGiveControlCallback}
      peerRequestingControl={props.peerRequestingControl}
      getPeerInfoCallback={props.getPeerInfoCallback}
      token={props.token}
      connectedPeers={props.connectedPeers}
      projectDetails={props.projectDetails}
      sendStopSignerVideoCallback={props.sendStopSignerVideoCallback}
      sendStartSignerVideoCallback={props.sendStartSignerVideoCallback}
      signerVideoIsStarted={props.signerVideoIsStarted}
    />
  );
};

export default MainSectionFooter;
