import { Layout, Layouts, LayoutTypes } from '../../types/Layout';

export const getSelectedLayout = (
  layouts: Layouts | undefined,
  selectedLayoutType: LayoutTypes,
): Layout | null => {
  if (!layouts || layouts.length === 0) {
    return null;
  }

  return layouts.find((l) => l.layoutType === selectedLayoutType) || null;
};
