import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from '@reach/accordion';
import React from 'react';
import Chat from '../../Chat/Chat';

import ExpandCollapseIcon from '../../../images/back-arrow-blue.png';
import { useTranslation } from 'react-i18next';
import { Messages } from '../../../types/Message';
import { UserType, UserTypes } from '../../../types/ProjectUser';
import _ from 'lodash';
import { ProjectDetail } from '../../../types/ProjectDetail';

type ProjectUserControlPanelProps = {
  token: string;
  chatMessages: Messages;
  sendMessageToRoomCallback: (messageToSend: string) => void;
  isChatConnected: boolean;
  userType: UserType;
  projectDetails?: ProjectDetail;
  sendReloadProjectDetails?: (projectDetails: ProjectDetail) => void;
};

const ProjectUserControlPanel = ({
  token,
  chatMessages,
  sendMessageToRoomCallback,
  isChatConnected,
  userType,
  projectDetails,
  sendReloadProjectDetails,
}: ProjectUserControlPanelProps) => {
  const { t } = useTranslation();

  const updateStreamTextEventInProjectDetails = (hasCaptions: boolean, hasSigner: boolean) => {
    const newProjectDetails = _.cloneDeep(projectDetails);
    if (newProjectDetails?.activeLayout) {
      newProjectDetails.activeLayout.hasCaption = hasCaptions;
      newProjectDetails.activeLayout.hasSigner = hasSigner;
      sendReloadProjectDetails && sendReloadProjectDetails(newProjectDetails);
    }
  };

  return (
    <Accordion className="control-panel-accordion" defaultIndex={1}>
      {userType === UserTypes.CAPTIONER && (
        <AccordionItem>
          <h3>
            <AccordionButton className="control-panel-accordion-button">
              <span className="control-panel-accordion-title-padding">
                {t('producer.control-panel.captioning.heading')}
              </span>
            </AccordionButton>
            <div className="control-panel-accordion-button-on-off">
              <button
                className="control-panel-accordion-button-toggle-button"
                onClick={(event) => {
                  event.stopPropagation();
                  updateStreamTextEventInProjectDetails(
                    !projectDetails?.activeLayout?.hasCaption,
                    !!projectDetails?.activeLayout?.hasSigner,
                  );
                }}
              >
                <span className="control-panel-accordion-button-on-off-button-back"></span>
                {projectDetails?.activeLayout && projectDetails?.activeLayout.hasCaption ? (
                  <>
                    <span className="control-panel-accordion-button-on-off-text">
                      {t('labels.on')}
                    </span>
                    <span className="control-panel-accordion-button-on-off-button-switch"></span>
                  </>
                ) : (
                  <>
                    <span className="control-panel-accordion-button-on-off-text">
                      {t('labels.off')}
                    </span>
                    <span className="control-panel-accordion-button-on-off-button-switch control-panel-accordion-button-on-off-button-switch-off"></span>{' '}
                  </>
                )}
              </button>
            </div>
          </h3>
        </AccordionItem>
      )}
      {userType === UserTypes.SIGNER && (
        <AccordionItem>
          <h3>
            <AccordionButton className="control-panel-accordion-button">
              <span className="control-panel-accordion-title-padding">
                {t('producer.control-panel.signing.heading')}
              </span>
            </AccordionButton>
            <div className="control-panel-accordion-button-on-off">
              <button
                className="control-panel-accordion-button-toggle-button"
                onClick={(event) => {
                  event.stopPropagation();
                  updateStreamTextEventInProjectDetails(
                    !!projectDetails?.activeLayout?.hasCaption,
                    !projectDetails?.activeLayout?.hasSigner,
                  );
                }}
              >
                <span className="control-panel-accordion-button-on-off-button-back"></span>
                {projectDetails?.activeLayout && projectDetails?.activeLayout.hasSigner ? (
                  <>
                    <span className="control-panel-accordion-button-on-off-text">
                      {t('labels.on')}
                    </span>
                    <span className="control-panel-accordion-button-on-off-button-switch"></span>
                  </>
                ) : (
                  <>
                    <span className="control-panel-accordion-button-on-off-text">
                      {t('labels.off')}
                    </span>
                    <span className="control-panel-accordion-button-on-off-button-switch control-panel-accordion-button-on-off-button-switch-off"></span>{' '}
                  </>
                )}
              </button>
            </div>
          </h3>
        </AccordionItem>
      )}
      <AccordionItem>
        <h3>
          <AccordionButton className="control-panel-accordion-button">
            <img
              src={ExpandCollapseIcon}
              alt="open icon"
              className="control-panel-accordion-button-arrow"
            />
            {t('producer.control-panel.chat.heading')}
            {/* <img src={ChatPanelIcon} alt="chat panel" className="control-panel-chat-panel-icon" /> */}
          </AccordionButton>
        </h3>
        <AccordionPanel className="control-panel-accordion-panel">
          <Chat
            chatMessages={chatMessages}
            userId={token}
            sendMessageToRoomCallback={sendMessageToRoomCallback}
            isChatConnected={isChatConnected}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default ProjectUserControlPanel;
