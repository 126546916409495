import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from '../ValidationError/ValidationError';
import './TextArea.scss';
import { FieldError } from 'react-hook-form';

type Props = {
  placeholder: string;
  value: string;
  onChange: (data: string) => void;
  errorType: FieldError | undefined;
  errorMessageKey: string;
  readOnly?: boolean;
  classOverride?: string;
};

export const TextArea = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <ValidationError errorType={props.errorType} errorMessageKey={props.errorMessageKey} />
      <textarea
        className={(props.errorType && 'is-danger ') + ' input input-text ' + props.classOverride}
        value={props.value}
        placeholder={t(props.placeholder)}
        onChange={(e) => props.onChange(e.target.value)}
        readOnly={props.readOnly}
        maxLength={2500}
      />
    </>
  );
};
