import React, { useEffect, useRef, useState } from 'react';
import { useMutate } from 'restful-react';
import { Dropdown } from '../../FormComponents/Dropdown/Dropdown';
import { TextInput } from '../../FormComponents/TextInput/TextInput';
import { BaseModal } from '../BaseModal/BaseModal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './DuplicateProjectDetailsModal.scss';
import { Button } from '../../FormComponents/Buttons/Button';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ValidationError } from '../../FormComponents/ValidationError/ValidationError';
import { durationInMinutes, timezones } from '../../../common/constants';
import history from '../../../tools/history';
import {
  formatDate,
  getBaseUtcOffset,
  getTimezoneName,
  isDaylightSavings,
  isValidTime,
  isValidTimeZone,
  mergeDateAndTime,
} from '../../../helpers/dateFormatHelper/dateFormatHelper';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { ProjectDetail } from '../../../types/ProjectDetail';
import { errorMessageToTranslationKey } from '../../../helpers/errorMessageHelper/errorMessageHelper';
import moment from 'moment';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

type Props = {
  isOpen: boolean;
  contentLabel: string;
  projectDetails?: ProjectDetail | null;
  projectIdToDuplicate?: string;
  projectName?: string;
  projectResolutionToDuplicate?: number;
  handleCloseModal: () => void;
  hasStreamDestinations: boolean;
  hasCaptioning: boolean;
  hasSigning: boolean;
  hasStreamSettings: boolean;
};

type DuplicateProjectFormData = {
  id: string;
  name: string;
  timeZone: string;
  date: Date | string;
  time: string;
  duration: string;
  projectIdToCopy?: string;
  resolution: string;
  copyStreamKey: boolean | undefined;
  copyStreamDestinations: boolean | undefined;
  copyCaptioners: boolean | undefined;
  copyCaptioningFormat: boolean | undefined;
  copySigners: boolean | undefined;
  copySignLanguageFormat: boolean | undefined;
  copyStreamSettings: boolean | undefined;
  copyStreamTextEventName: boolean | undefined;
  hasStreamDestinations: boolean | undefined;
  hasCaptioning: boolean | undefined;
  hasSigning: boolean | undefined;
};

export const DuplicateProjectDetailsModal = (props: Props) => {
  const date = props.projectDetails?.startDateTimeLocal || new Date();
  const time = props.projectDetails?.startTimeLocal || '';
  const defaultValues: DuplicateProjectFormData = {
    id: props.projectDetails?.id || '',
    name: props.projectDetails?.name || props.projectName || '',
    timeZone: getTimezoneName(date, time, props.projectDetails?.timeZone || 'GMT Standard Time'),
    date: date,
    time: time,
    duration: props.projectDetails?.duration.toString() || '60',
    projectIdToCopy: props.projectIdToDuplicate,
    resolution: props.projectResolutionToDuplicate
      ? props.projectResolutionToDuplicate.toString()
      : props.projectDetails?.resolution.toString() || '1080',
    copyStreamKey: true,
    copyStreamDestinations: true,
    copyCaptioners: props.hasCaptioning,
    copyCaptioningFormat: props.hasCaptioning,
    copySigners: props.hasSigning,
    copySignLanguageFormat: props.hasSigning,
    copyStreamSettings: true,
    copyStreamTextEventName: props.hasCaptioning,
    hasStreamDestinations: props.hasStreamDestinations,
    hasCaptioning: props.hasCaptioning,
    hasSigning: props.hasSigning,
  };

  const { t } = useTranslation();
  const { control, errors, handleSubmit, register } = useForm<DuplicateProjectFormData>({
    defaultValues,
  });

  const [loadingCreate, setLoadingCreate] = useState(false);
  const timezoneRef = useRef(defaultValues.timeZone);
  const dateRef = useRef(defaultValues.date);
  const timeRef = useRef(defaultValues.time);
  const [isError, setIsError] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessfulCreate = (body: DuplicateProjectFormData, data: any) => {
    if (data.statusCode === 201) {
      props.handleCloseModal();
      history.push('/producer/' + data.result);
    }
  };

  const { mutate: duplicateProject, error: errorCreate } = useMutate<DuplicateProjectFormData>({
    verb: 'POST',
    path: 'projects/duplicate',
    onMutate: onSuccessfulCreate,
  });

  useEffect(() => {
    setIsError(errorCreate !== null && errorCreate !== undefined);
  }, [errorCreate]);

  const onSubmit: SubmitHandler<DuplicateProjectFormData> = (
    data: DuplicateProjectFormData,
    event?: React.BaseSyntheticEvent,
  ) => {
    setLoadingCreate(true);
    event?.preventDefault();

    data.date = moment(data.date).format('YYYY-MM-DD');

    duplicateProject(data);
  };

  const getDate = (): string =>
    mergeDateAndTime(formatDate(moment(dateRef.current).toDate()), timeRef.current);

  const getTimezones = () => {
    return (
      timezones?.filter(
        (timezone) =>
          timezone.isDaylightSavings ===
          isDaylightSavings(getDate(), getBaseUtcOffset('GMT Standard Time')),
      ) || []
    );
  };

  const changeTimezone = (): void => {
    const date = getDate();

    if (isDaylightSavings(date, getBaseUtcOffset('GMT Standard Time'))) {
      timezoneRef.current = timezoneRef.current.replace('Standard', 'Summer');
    } else {
      timezoneRef.current = timezoneRef.current.replace('Summer', 'Standard');
    }

    control.setValue('timeZone', '');
    control.setValue('timeZone', timezoneRef.current);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isOverlapException = (errorCreate: any): boolean => {
    if (errorCreate.data.errors[0] === 'Project cannot be created as it overlaps with another') {
      return true;
    }

    return false;
  };

  const handleErrorClose = (): void => {
    setIsError(false);
    setLoadingCreate(false);
  };

  if (isError) {
    if (isOverlapException(errorCreate)) {
      return (
        <ErrorMessage
          message={t('error-messages.project-overlap')}
          handleErrorClose={handleErrorClose}
        />
      );
    }
    return (
      <ErrorMessage
        message={t(errorMessageToTranslationKey(errorCreate))}
        handleErrorClose={handleErrorClose}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const closeHandler = () => {
    if (!isError) {
      props.handleCloseModal();
    }
  };

  return (
    <BaseModal
      isOpen={props.isOpen}
      contentLabel={props.contentLabel}
      handleCloseModal={closeHandler}
      ariaHideApp={true}
      title={'create-project.modal-title-duplicate'}
    >
      <form className="duplicate-project-form" onSubmit={handleSubmit(onSubmit)}>
        <input
          name="id"
          defaultValue={defaultValues.id}
          type="hidden"
          ref={() => register({ name: 'id' })}
        />
        <input
          name="projectIdToCopy"
          defaultValue={defaultValues.projectIdToCopy}
          type="hidden"
          ref={() => register({ name: 'projectIdToCopy' })}
        />
        <Controller
          name="name"
          control={control}
          rules={{ required: true, maxLength: 150 }}
          render={({ onChange, value }) => (
            <TextInput
              placeholder="create-project.project-title-placeholder"
              value={value}
              onChange={onChange}
              onAfterChange={null}
              errorType={errors.name}
              errorMessageKey={
                value.length <= 150
                  ? 'create-project.missing-title'
                  : 'create-project.title-max-length'
              }
            />
          )}
        />
        <div className="columns">
          <div className="column">
            <ValidationError
              errorType={errors.date}
              errorMessageKey="create-project.missing-date"
            />
            <Controller
              name="date"
              control={control}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Calendar
                  onChange={(data) => {
                    dateRef.current = formatDate(data as Date);
                    onChange(data);
                    changeTimezone();
                  }}
                  minDate={new Date()}
                  minDetail="year"
                  next2Label={null}
                  prev2Label={null}
                  value={value ? new Date(value) : new Date()}
                />
              )}
            />
          </div>
          <div className="column">
            <ValidationError errorType={errors.time} errorMessageKey={errors.time?.message ?? ''} />
            <Controller
              name="time"
              control={control}
              rules={{
                required: 'create-project.missing-time',
                validate: (value) => isValidTime(value, getDate(), timezoneRef.current),
              }}
              render={({ onChange, value }) => (
                <input
                  type="time"
                  onChange={(data) => {
                    timeRef.current = data.currentTarget.value;
                    onChange(data);
                    changeTimezone();
                  }}
                  className={'input timepicker' + (errors.time ? ' is-danger' : '')}
                  id="timepicker"
                  aria-label="start time"
                  name="timepicker"
                  value={value}
                ></input>
              )}
            />
            <Controller
              control={control}
              name="timeZone"
              className="mb-3"
              rules={{ required: true, validate: (value) => isValidTimeZone(value) }}
              render={({ onChange, value }) => (
                <Dropdown
                  value={value || getTimezoneName(getDate(), timeRef.current, value)}
                  options={getTimezones()}
                  onChange={(data) => {
                    timezoneRef.current = data?.value;
                    onChange(data?.value);
                  }}
                  placeholderKey="create-project.timezone-placeholder"
                  errorType={errors.timeZone}
                  errorMessageKey="create-project.missing-timezone"
                />
              )}
            />
            <Controller
              control={control}
              name="duration"
              className="mb-3"
              rules={{ required: true, min: durationInMinutes[0].value }}
              render={({ onChange, value }) => (
                <Dropdown
                  value={value || '60'}
                  options={durationInMinutes}
                  onChange={(data) => onChange(data?.value)}
                  placeholderKey="create-project.duration-placeholder"
                  errorType={errors.duration}
                  errorMessageKey="create-project.missing-duration"
                />
              )}
            />
            <Controller
              control={control}
              name="resolution"
              className="mb-3"
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Dropdown
                  value={value || 1080}
                  options={[
                    { value: '720', text: '720p' },
                    { value: '1080', text: '1080p' },
                  ]}
                  onChange={(data) => onChange(data?.value)}
                  placeholderKey="create-project.resolution-placeholder"
                  errorType={errors.resolution}
                  errorMessageKey="create-project.missing-resolution"
                />
              )}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column shutoff-notice">
            <p>{t('create-project.auto-shutoff-1')}</p>
            <br />
            <p>{t('create-project.auto-shutoff-2')}</p>
          </div>
          {
            <>
              <div className="column">
                <h3 className="ReactModal__Content__Body__SubTitle">
                  {t('create-project.duplicate.copy-title')}
                </h3>
                <Controller
                  control={control}
                  name="copyStreamKey"
                  render={({ onChange }) => (
                    <label>
                      <input
                        type="checkbox"
                        defaultChecked={defaultValues.copyStreamKey}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                      <span>{t('create-project.duplicate.copy-streamkey')}</span>
                    </label>
                  )}
                />
                {props.hasStreamDestinations ? (
                  <Controller
                    control={control}
                    name="copyStreamDestinations"
                    render={({ onChange }) => (
                      <label>
                        <input
                          type="checkbox"
                          defaultChecked={defaultValues.copyStreamDestinations}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                        <span>{t('create-project.duplicate.copy-stream-destinations')}</span>
                      </label>
                    )}
                  />
                ) : (
                  ''
                )}
                {props.hasCaptioning ? (
                  <>
                    <Controller
                      control={control}
                      name="copyCaptioners"
                      render={({ onChange }) => (
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked={defaultValues.copyCaptioners}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                          <span>{t('create-project.duplicate.copy-captioners')}</span>
                        </label>
                      )}
                    />
                    <Controller
                      control={control}
                      name="copyCaptioningFormat"
                      render={({ onChange }) => (
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked={defaultValues.copyCaptioningFormat}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                          <span>{t('create-project.duplicate.copy-captioning-format')}</span>
                        </label>
                      )}
                    />
                  </>
                ) : (
                  ''
                )}
                {props.hasSigning ? (
                  <>
                    <Controller
                      control={control}
                      name="copySigners"
                      render={({ onChange }) => (
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked={defaultValues.copySigners}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                          <span>{t('create-project.duplicate.copy-signers')}</span>
                        </label>
                      )}
                    />
                    <Controller
                      control={control}
                      name="copySignLanguageFormat"
                      render={({ onChange }) => (
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked={defaultValues.copySignLanguageFormat}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                          <span>{t('create-project.duplicate.copy-sign-language-format')}</span>
                        </label>
                      )}
                    />
                  </>
                ) : (
                  ''
                )}
                {props.hasStreamSettings ? (
                  <Controller
                    control={control}
                    name="copyStreamSettings"
                    render={({ onChange }) => (
                      <label>
                        <input
                          type="checkbox"
                          defaultChecked={defaultValues.copyStreamSettings}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                        <span>{t('create-project.duplicate.copy-stream-settings')}</span>
                      </label>
                    )}
                  />
                ) : (
                  ''
                )}
                {props.hasCaptioning ? (
                  <Controller
                    control={control}
                    name="copyStreamTextEventName"
                    render={({ onChange }) => (
                      <label>
                        <input
                          type="checkbox"
                          defaultChecked={defaultValues.copyStreamTextEventName}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                        <span>{t('create-project.duplicate.copy-streamtext-event-name')}</span>
                      </label>
                    )}
                  />
                ) : (
                  ''
                )}
              </div>
            </>
          }
        </div>
        {!loadingCreate ? (
          <Button
            translationKey="create-project.submit"
            variant="primary"
            color="blue"
            onClick={() => null}
            type="submit"
            disabled={loadingCreate}
          />
        ) : (
          <LoadingSpinner />
        )}
      </form>
    </BaseModal>
  );
};
