import React from 'react';

import { useTranslation } from 'react-i18next';
import { ProjectUser, UserTypes } from '../../../../types/ProjectUser';
import Row from '../../../Layout/Row/Row';
import BaseProjectSummaryCardBlock from './BaseProjectSummaryCardBlock';

import './BaseProjectSummaryCardBlock.scss';

type Props = {
  heading: string;
  projectUser: ProjectUser;
};

const ProjectSummaryCardBlockProjectUser = ({ heading, projectUser }: Props) => {
  const { t } = useTranslation();

  return (
    <BaseProjectSummaryCardBlock
      heading={heading === UserTypes.SIGNER ? t('labels.signer-known-as') : heading}
    >
      <Row
        left={t('project.project-user.name')}
        right={projectUser?.name}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
      <Row
        left={t('project.project-user.email')}
        right={projectUser?.emailAddress}
        columnClass="project-summary-card-column"
        leftClass="project-summary-card-row-title"
        rightClass="project-summary-card-row-content"
      />
    </BaseProjectSummaryCardBlock>
  );
};

export default ProjectSummaryCardBlockProjectUser;
