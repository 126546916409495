import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import './BaseModal.scss';

type Props = {
  isOpen: boolean;
  contentLabel?: string;
  handleCloseModal: () => void;
  ariaHideApp: boolean;
  title: string;
};

export const BaseModal: React.FC<Props> = ({
  isOpen,
  contentLabel,
  handleCloseModal,
  ariaHideApp,
  title,
  children,
}) => {
  const { t } = useTranslation();
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '6px',
      overflow: 'visible',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={contentLabel}
      onRequestClose={handleCloseModal}
      appElement={document.getElementById('root') || undefined}
      ariaHideApp={ariaHideApp}
      style={customStyles}
    >
      <div className="ReactModal__Content__Title">
        <h2>{t(title)}</h2>
      </div>
      <div className="ReactModal__Content__Body">{children}</div>
    </Modal>
  );
};
