import React from 'react';
import './BaseProjectSummaryCardBlock.scss';

type BaseProjectSummaryCardBlockProps = {
  heading: string;
};

const BaseProjectSummaryCardBlock: React.FC<BaseProjectSummaryCardBlockProps> = ({
  heading,
  children,
}) => {
  return (
    <div className="project-summary-card-block">
      <div className="project-summary-card-block-heading">{heading}</div>
      <div className="project-summary-card-block-body">
        <div className="is-fullwidth">{children}</div>
      </div>
    </div>
  );
};

export default BaseProjectSummaryCardBlock;
