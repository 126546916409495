import { useState, useCallback } from 'react';
import { RemoteStream } from '../types/RemoteStream';

export default function useRemoteStreams() {
  const [remoteStreams, setRemoteStreams] = useState<RemoteStream[]>([]);

  const addRemoteStream = useCallback((stream: MediaStream, peerId: string) => {
    setRemoteStreams((remoteStreams) => {
      if (!stream || !peerId) return [...remoteStreams];
      if (remoteStreams.some((remote) => remote.peerId === peerId)) return [...remoteStreams];
      return [...remoteStreams, { peerId: peerId, stream: stream }];
    });
  }, []);

  const removeRemoteStream = useCallback((peerId: string) => {
    setRemoteStreams((remoteStreams) => {
      const index = remoteStreams.findIndex((remote) => remote.peerId === peerId);
      if (index < 0) return [...remoteStreams];
      remoteStreams.splice(index, 1);
      return [...remoteStreams];
    });
  }, []);

  const removeAllRemoteStreams = () => {
    setRemoteStreams([]);
  };

  return { remoteStreams, addRemoteStream, removeRemoteStream, removeAllRemoteStreams };
}
