import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectDetailsModal } from '../../../Modals/ProjectDetailsModal/ProjectDetailsModal';
import BaseProjectCard from '../BaseProjectCard/BaseProjectCard';

import './NewProjectCard.scss';

const NewProjectCard = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <BaseProjectCard isDim={true}>
      <button className="new-project-card-button" onClick={() => setIsModalOpen(true)}>
        <span className="new-project-card-text">
          + {t('project-overview.cards.create-new-project')}
        </span>
      </button>

      {isModalOpen && (
        <ProjectDetailsModal
          isOpen={isModalOpen}
          contentLabel="Create Project"
          handleCloseModal={() => setIsModalOpen(false)}
        />
      )}
    </BaseProjectCard>
  );
};

export default NewProjectCard;
