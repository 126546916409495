import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectSummary } from '../../../../types/ProjectSummary';
import { Button } from '../../../FormComponents/Buttons/Button';
import BaseProjectCard from '../BaseProjectCard/BaseProjectCard';
import {
  getDateDifferenceFromNow,
  getFriendlyDuration,
  getFriendlyDate,
  getFriendlyElapsedDuration,
  getTimezoneName,
} from '../../../../helpers/dateFormatHelper/dateFormatHelper';
import moment from 'moment';
import { CardType, CardTypes } from '../../../../types/CardType';
import history from '../../../../tools/history';

import './ProjectCard.scss';
import Row from '../../../Layout/Row/Row';

type ProjectCardProps = {
  cardType: CardType;
  project: ProjectSummary | null;
  onShowProjectSummary: (projectId: string) => void;
};

const ProjectCard = ({ cardType, project, onShowProjectSummary }: ProjectCardProps) => {
  const { t } = useTranslation();

  const friendlyStartDate = getFriendlyDate(project?.startDateTimeLocal);
  const friendlyStreamedDate = !project?.elapsedDuration
    ? t('project.not-streamed')
    : getFriendlyDate(project?.broadcastStartedOnLocal);
  const friendlyStartsDuration = getFriendlyDuration(
    moment.duration(getDateDifferenceFromNow(project?.startDateTimeUtc)).asMinutes(),
  );
  const friendlyDuration = getFriendlyDuration(project?.duration ?? 0);
  const friendlyElapsedTime = !project?.elapsedDuration
    ? t('project.not-elapsed')
    : getFriendlyElapsedDuration(project?.elapsedDuration || '');

  const viewProjectSummaryCard = (projectId: string) => {
    onShowProjectSummary(projectId);
  };

  if (!project) {
    return null;
  }

  const openProducerPage = (projectId: string | undefined) => {
    if (projectId) {
      history.push('/producer/' + projectId);
    }
  };

  return (
    <BaseProjectCard>
      <div className="project-card-contents">
        <h3 className="project-card-heading">{project?.name}</h3>
        <div className="project-card-details">
          <Row
            left={t('project.scheduled-for')}
            right={friendlyStartDate}
            columnClass="project-card-column"
            leftClass="project-card-row-title"
            rightClass="project-card-row-content"
          />
          {cardType === CardTypes.ACTIVE ? (
            <>
              <Row
                left={t('project.time-zone')}
                right={getTimezoneName(
                  project?.startDateTimeLocal,
                  project?.startTimeLocal,
                  project?.timeZone,
                )}
                columnClass="project-card-column"
                leftClass="project-card-row-title"
                rightClass="project-card-row-content"
              />
              <Row
                left={t('project.start-time')}
                right={friendlyStartsDuration}
                columnClass="project-card-column"
                leftClass="project-card-row-title"
                rightClass="project-card-row-content"
              />
              <Row
                left={t('project.duration')}
                right={friendlyDuration}
                columnClass="project-card-column"
                leftClass="project-card-row-title"
                rightClass="project-card-row-content"
              />
            </>
          ) : (
            <>
              <Row
                left={t('project.streamed')}
                right={friendlyStreamedDate}
                columnClass="project-card-column"
                leftClass="project-card-row-title"
                rightClass="project-card-row-content"
              />
              <Row
                left={t('project.elapsed-duration')}
                right={friendlyElapsedTime}
                columnClass="project-card-column"
                leftClass="project-card-row-title"
                rightClass="project-card-row-content"
              />
            </>
          )}
        </div>
        {cardType === CardTypes.ACTIVE ? (
          <Button
            translationKey="button.open"
            variant="secondary"
            color="blue"
            additionalClassName="btn-no-margin"
            onClick={() => openProducerPage(project?.id)}
            type="button"
          />
        ) : (
          <Button
            translationKey="button.view-summary"
            variant="secondary"
            color="blue"
            additionalClassName="btn-no-margin btn-is-full"
            onClick={() => viewProjectSummaryCard(project.id)}
            type="button"
          />
        )}
      </div>
    </BaseProjectCard>
  );
};

export default ProjectCard;
