import env from '../../../Env';
import React, { useEffect, useState } from 'react';

import './MainSectionLiveVideo.scss';
import { Layout } from '../../../types/Layout';

type MainSectionLiveVideoProps = {
  activeLayout?: Layout;
  triggerRefreshLiveVideo: boolean;
};

const MainSectionLiveVideo = ({
  activeLayout,
  triggerRefreshLiveVideo,
}: MainSectionLiveVideoProps) => {
  const [reloadIframe, setReloadIframe] = useState<number>(0);

  useEffect(() => {
    if (triggerRefreshLiveVideo) {
      setReloadIframe((r) => r + 1);
    }
  }, [triggerRefreshLiveVideo]);

  return (
    <iframe
      key={reloadIframe}
      title="Livestream Video"
      width="100%"
      height="100%"
      src={`/libs/antmedia/play.html?name=${activeLayout?.id}&mute=false&antserver=${env.antMediaServer}`}
      // Fallback Use AntMedia directly:
      // src={`https://${env.antMediaServer}/LiveApp/play.html?name=${activeLayout?.id}&mute=false`}
      allowFullScreen
    ></iframe>
  );
};

export default MainSectionLiveVideo;
