import React, { useState } from 'react';
import env from '../../../Env';
import { useMutate } from 'restful-react';
import { TextInput } from '../../FormComponents/TextInput/TextInput';
import { BaseModal } from '../BaseModal/BaseModal';
import './InviteProjectUserModal.scss';
import { Button } from '../../FormComponents/Buttons/Button';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import useProjectDetails from '../../../hooks/useProjectDetails';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { ProjectUser, ProjectUserCreationDto, UserTypes } from '../../../types/ProjectUser';
import { ValidationError } from '../../FormComponents/ValidationError/ValidationError';
import _ from 'lodash';
import { Layout } from '../../../types/Layout';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

type Props = {
  isOpen: boolean;
  contentLabel: string;
  selectedProjectUser: ProjectUserCreationDto;
  userType: UserTypes;
  handleCloseModal: () => void;
  turnFeatureOn: () => void;
};

type CreateInviteProjectUserFormData = {
  name: string;
  emailAddress: string;
  userType: UserTypes;
};

type DeleteInvitedProjectUserFormData = {
  id: string;
  userType: UserTypes;
};

type InviteProjectUserData = {
  emailAddress: string;
  id: string;
  name: string;
  userType: UserTypes;
};

type SuccessfulInviteProjectUserData = {
  id: string;
  accessToken: string;
};

export const InviteProjectUserModal = (props: Props) => {
  const { t } = useTranslation();
  const { projectDetails, setProjectDetails } = useProjectDetails();
  const [copied, setCopied] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [validationKey, setValidationKey] = useState(
    'producer.control-panel.invites.invite-email-missing-value',
  );
  const [loadingCreate, setLoadingCreate] = useState(false);

  const defaultValues: CreateInviteProjectUserFormData = {
    name: props.selectedProjectUser.name,
    emailAddress: props.selectedProjectUser.emailAddress,
    userType: props.userType,
  };

  const { control, errors, register, handleSubmit } = useForm<CreateInviteProjectUserFormData>({
    defaultValues,
  });

  const copyToClipboard = (copyValue: string) => {
    navigator.clipboard.writeText(copyValue);
    setCopied(true);
    setTimeout(() => setCopied(false), 5000);
  };

  const removeProjectUserFromProjectDetails = (projectUserId: string) => {
    const newProjectDetails = _.cloneDeep(projectDetails);
    const itemIndex = newProjectDetails?.projectUsers
      ?.map((item) => item.id)
      .indexOf(projectUserId);

    if (itemIndex != null && itemIndex !== -1) {
      newProjectDetails?.projectUsers?.splice(itemIndex, 1);
    }

    if (projectDetails !== null && newProjectDetails !== null) {
      setProjectDetails(newProjectDetails);
    }
  };

  const addProjectUserToProjectDetails = (projectUser: ProjectUser) => {
    const newProjectDetails = _.cloneDeep(projectDetails);

    newProjectDetails?.projectUsers?.push(projectUser);
    if (projectUser.userType === UserTypes.CAPTIONER && newProjectDetails) {
      newProjectDetails.activeLayout = {
        ...newProjectDetails?.activeLayout,
        hasCaption: true,
      } as Layout;
    } else if (projectUser.userType === UserTypes.SIGNER && newProjectDetails) {
      newProjectDetails.activeLayout = {
        ...newProjectDetails?.activeLayout,
        hasSigner: true,
      } as Layout;
    }

    if (projectDetails !== null && newProjectDetails !== null) {
      setTimeout(() => {
        setProjectDetails(newProjectDetails);
      }, 10);
    }
  };

  const onSuccessfulCreate = (body: InviteProjectUserData, data: unknown) => {
    const typedData = data as {
      response: string;
      statusCode: number;
      result: SuccessfulInviteProjectUserData;
    };

    if (typedData.statusCode === 200) {
      const newEntry: ProjectUser = {
        id: typedData.result.id,
        name: body.name,
        emailAddress: body.emailAddress,
        userType: props.userType,
        accessToken: typedData.result.accessToken,
      };

      if (
        (props.userType === UserTypes.CAPTIONER &&
          projectDetails?.activeLayout?.hasCaption === false) ||
        (props.userType === UserTypes.SIGNER && projectDetails?.activeLayout?.hasSigner === false)
      ) {
        props.turnFeatureOn();
      }

      addProjectUserToProjectDetails(newEntry);

      // work around to avoid issue with - warning message: Can't perform a React state update on an unmounted component.
      setTimeout(function () {
        props.handleCloseModal();
      }, 1000);
    }
  };

  const onSuccessfulDelete = (body: DeleteInvitedProjectUserFormData, data: unknown) => {
    const typedData = data as { response: string; statusCode: number };
    if (typedData.statusCode === 200) {
      removeProjectUserFromProjectDetails(body.id);
      props.handleCloseModal();
    }
  };

  const { mutate: createInviteProjectUser } = useMutate<CreateInviteProjectUserFormData>({
    verb: 'POST',
    path: '/projects/' + projectDetails?.id + '/users',
    onMutate: onSuccessfulCreate,
  });

  const {
    mutate: deleteInvitedProjectUser,
    loading: loadingDelete,
    error: errorDelete,
  } = useMutate<DeleteInvitedProjectUserFormData>({
    verb: 'DELETE',
    path: '/projects/' + projectDetails?.id + '/users',
    onMutate: onSuccessfulDelete,
  });

  const onSubmit: SubmitHandler<CreateInviteProjectUserFormData> = (
    data: CreateInviteProjectUserFormData,
    event?: React.BaseSyntheticEvent,
  ) => {
    setLoadingCreate(true);
    event?.preventDefault();
    createInviteProjectUser({ ...data, id: props.selectedProjectUser.id }).catch((error) => {
      if (error.data.errors[0].indexOf('EmailAddressInuseException') > -1) {
        setValidationKey('producer.control-panel.invites.invite-email-in-use');
      } else {
        setValidationKey('producer.control-panel.invites.invite-email-not-in-streamtext');
      }

      setHasError(true);
      setLoadingCreate(false);

      const value: string = control.getValues().emailAddress;
      control.setValue('emailAddress', '');
      control.setValue('emailAddress', value, { shouldValidate: true });
    });
  };

  const onDelete = (data: DeleteInvitedProjectUserFormData) => {
    deleteInvitedProjectUser(data);
  };

  const isValidEmailAddress = (email: string) => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validateEmailAddress = (email: string) => (hasError ? false : isValidEmailAddress(email));

  if (errorDelete) {
    return <ErrorMessage message={t('producer.control-panel.invites.invite-delete')} />;
  }

  const getEmailValidationKey = (value: string) => {
    if (hasError) {
      return validationKey;
    }

    if (value.length <= 320) {
      return 'producer.control-panel.invites.invite-email-missing-value';
    }

    return 'producer.control-panel.invites.invite-email-max-length';
  };

  return (
    <BaseModal
      isOpen={props.isOpen}
      contentLabel={props.contentLabel}
      handleCloseModal={props.handleCloseModal}
      ariaHideApp={true}
      title={
        props.userType === UserTypes.CAPTIONER
          ? 'producer.control-panel.invites.invite-captioner-modal-title'
          : 'producer.control-panel.invites.invite-signer-modal-title'
      }
    >
      <form className="invite-project-user-modal" onSubmit={handleSubmit(onSubmit)}>
        <input
          name="userType"
          defaultValue={defaultValues.userType}
          type="hidden"
          ref={() => register({ name: 'userType' })}
        />
        <Controller
          name="name"
          control={control}
          rules={{ required: true, maxLength: 150 }}
          render={({ onChange, value }) => (
            <>
              <label>{t('producer.control-panel.invites.invite-name-title')}</label>
              <TextInput
                placeholder="producer.control-panel.invites.invite-name-placeholder"
                value={value}
                onChange={onChange}
                onAfterChange={null}
                errorType={errors.name}
                errorMessageKey={
                  value.length <= 150
                    ? 'producer.control-panel.invites.invite-name-missing-value'
                    : 'producer.control-panel.invites.invite-name-max-length'
                }
                readOnly={props.selectedProjectUser.id !== ''}
              />
            </>
          )}
        />
        <ValidationError
          errorType={errors.emailAddress}
          errorMessageKey={errors.emailAddress?.message ?? ''}
        />
        <Controller
          name="emailAddress"
          control={control}
          rules={{
            required: true,
            maxLength: 320,
            validate: (value) => validateEmailAddress(value),
          }}
          render={({ onChange, value }) => (
            <>
              <label>{t('producer.control-panel.invites.invite-email-title')}</label>
              <TextInput
                placeholder="producer.control-panel.invites.invite-email-placeholder"
                value={value}
                onChange={(e) => {
                  setHasError(false);
                  onChange(e);
                }}
                onAfterChange={null}
                errorType={errors.emailAddress}
                errorMessageKey={getEmailValidationKey(value)}
                readOnly={props.selectedProjectUser.id !== ''}
              />
            </>
          )}
        />
        <br />
        {props.selectedProjectUser.id === '' ? (
          <>
            {!loadingCreate ? (
              <Button
                translationKey="producer.control-panel.invites.send"
                variant="primary"
                color="blue"
                onClick={() => null}
                type="submit"
                disabled={loadingCreate}
              />
            ) : (
              <LoadingSpinner />
            )}
          </>
        ) : null}
      </form>
      {props.selectedProjectUser.id !== '' ? (
        <>
          {props.selectedProjectUser?.accessToken ? (
            <Button
              translationKey={
                copied
                  ? 'producer.control-panel.invites.copied'
                  : 'producer.control-panel.invites.copy'
              }
              variant="secondary"
              color={copied ? 'green' : 'blue'}
              onClick={() =>
                copyToClipboard(
                  env.authRedirect +
                    '/' +
                    props.userType +
                    '/' +
                    props.selectedProjectUser.accessToken,
                )
              }
              type="button"
              disabled={loadingDelete}
            />
          ) : null}
          {!loadingDelete ? (
            <Button
              translationKey="producer.control-panel.invites.delete"
              variant="secondary"
              color="blue"
              onClick={() =>
                onDelete({
                  id: props.selectedProjectUser.id,
                  userType: props.userType,
                })
              }
              type={undefined}
              disabled={loadingDelete}
            />
          ) : (
            <LoadingSpinner />
          )}
        </>
      ) : null}
    </BaseModal>
  );
};
