import React from 'react';
import { Messages } from '../../types/Message';
import { ProjectDetail } from '../../types/ProjectDetail';
import { UserType, UserTypes } from '../../types/ProjectUser';
import ProducerControlPanel from '../ProducerComponents/ProducerControlPanel/ProducerControlPanel';
import ProjectUserControlPanel from '../ProjectUserComponents/ProjectUserControlPanel/ProjectUserControlPanel';

import './ProjectControlPanel.scss';

type ProjectControlPanelProps = {
  userType: UserType;
  token: string;
  chatMessages: Messages;
  sendMessageToRoomCallback: (messageToSend: string) => void;
  isChatConnected: boolean;
  projectDetails?: ProjectDetail;
  sendReloadProjectDetails: (projectDetails: ProjectDetail) => void;
};

const ProjectControlPanel = (props: ProjectControlPanelProps) => {
  return (
    <div className="control-panel">
      {props.userType === UserTypes.PRODUCER ? (
        <ProducerControlPanel
          chatMessages={props.chatMessages}
          sendMessageToRoomCallback={props.sendMessageToRoomCallback}
          isChatConnected={props.isChatConnected}
          projectDetails={props.projectDetails}
          sendReloadProjectDetails={props.sendReloadProjectDetails}
        />
      ) : (
        <ProjectUserControlPanel
          token={props.token}
          chatMessages={props.chatMessages}
          sendMessageToRoomCallback={props.sendMessageToRoomCallback}
          isChatConnected={props.isChatConnected}
          userType={props.userType}
          projectDetails={props.projectDetails}
          sendReloadProjectDetails={props.sendReloadProjectDetails}
        />
      )}
    </div>
  );
};

export default ProjectControlPanel;
