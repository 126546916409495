import React from 'react';
import './ProjectUserElement.scss';

type ProjectUserElementProps = {
  colorId: number;
  name: string;
  description: string;
  userId: string;
  isUserActiveCallback: (userId: string) => boolean;
  isUserLiveCallback: (userId: string) => boolean;
};

const ProjectUserElement = ({
  colorId,
  name,
  description,
  userId,
  isUserActiveCallback,
  isUserLiveCallback,
}: ProjectUserElementProps) => {
  const colorArray = [
    '#00AA55',
    '#1BA39C',
    '#009FD4',
    '#6B8E23',
    '#D25852',
    '#382903',
    '#B381B3',
    '#00AA00',
    '#939393',
    '#AA8F00',
    '#28A228',
    '#D47500',
    '#D2527F',
    '#E76E3C',
    '#28A228',
    '#E74C3C',
    '#000000',
  ];

  const getProjectUserInitials = (userName: string) => {
    if (!userName) {
      return null;
    }
    return userName.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '');
  };

  const pickRandomColor = (userIndex: number): string => {
    if (userIndex > colorArray.length - 1) {
      userIndex = 0;
    }

    return colorArray[userIndex];
  };

  const createUserStyle = (isUserActive: boolean): React.CSSProperties | undefined => {
    const color: string = isUserActive ? pickRandomColor(colorId) : '#a4a4c1';

    const style: React.CSSProperties | object = {
      backgroundColor: color,
    };

    return style;
  };

  return (
    <div className="project-users-element">
      <div
        className={`project-users-element-icon${
          userId.indexOf('ai-writer') > -1 || isUserLiveCallback(userId) ? ' live' : ''
        }`}
        style={createUserStyle(
          userId.indexOf('ai-writer') > -1 ? true : isUserActiveCallback(userId),
        )}
      >
        {getProjectUserInitials(name)}
      </div>
      <div>
        <p className="project-users-element-name">{name}</p>
        <p className="project-users-element-type">{description}</p>
      </div>
    </div>
  );
};

export default ProjectUserElement;
