import React from 'react';
import { DefaultProjectDetail, ProjectDetailContext } from '../../types/ProjectDetail';
import { ProjectDetail } from '../../types/ProjectDetail';

const initialValues: ProjectDetailContext = {
  projectDetails: DefaultProjectDetail,
  setProjectDetails: (data: ProjectDetail) => {
    console.log(data);
  },

  updateActiveLayout: (property: string, value: string | number | boolean) => {
    console.log(`${property} - ${value}`);
  },

  updateAssetsFromSignalR: (hasCaptions: boolean, hasSigner: boolean, hasAiCaptions: boolean) => {
    console.log(hasCaptions, hasSigner, hasAiCaptions);
  },

  updateActiveLayoutWithMultiple: (properties: Record<string, string | number | boolean>) => {
    console.log(properties);
  },

  isAwaitingApiSaveToComplete: false,
};

const ProjectDetailsContext = React.createContext<ProjectDetailContext>(initialValues);

export default ProjectDetailsContext;
