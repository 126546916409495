import { Consumers } from './Consumer';
import { IceServer } from './IceServer';
import { Layout, Layouts } from './Layout';
import { Messages } from './Message';
import { ProjectUsers } from './ProjectUser';

export type ProjectDetail = {
  id: string;
  name: string;
  createdByUserId: string;
  createdByUserName: string;
  createdDate: string;
  createdDateLocal: string;
  timeZone: string;
  startDateTimeLocal: string;
  startTimeLocal: string;
  startDateTimeUtc: string;
  startTimeUtc: string;
  duration: number;
  broadcastStartedOnLocal: string;
  elapsedDuration: string;
  deletedOnUtc?: string;
  broadcastStartedOnUtc?: string;
  previewStartedOnUtc?: string;
  broadcastEndedOnUtc?: string;
  layouts?: Layouts;
  consumers?: Consumers;
  projectUsers?: ProjectUsers;
  messages?: Messages;
  activeLayout?: Layout;
  projectUserId?: string;
  projectUserName?: string;
  streamTextEventName?: string;
  iceServers?: IceServer[];
  customLogoUrl: string;
  customColourHex: string;
  resolution: number;
  signLanguageUrl: string;
  customBackgroundImageUrl: string;
  signLanguageQrCodeUrl: string;
  ingestKey: string;
  ingestUrl: string;
  paymentStatus: string;
};

export type ProjectDetailContext = {
  projectDetails: ProjectDetail | null;
  setProjectDetails: (data: ProjectDetail) => void;
  updateActiveLayout: (property: string, value: string | number | boolean) => void;
  updateActiveLayoutWithMultiple: (properties: Record<string, string | number | boolean>) => void;
  updateAssetsFromSignalR: (
    hasCaption: boolean,
    hasSigner: boolean,
    hasAiCaptions: boolean,
  ) => void;
  isAwaitingApiSaveToComplete: boolean;
};

export const DefaultProjectDetail: ProjectDetail = {
  id: '',
  name: '',
  createdByUserId: '',
  createdByUserName: '',
  createdDate: '',
  createdDateLocal: '',
  timeZone: '',
  startDateTimeLocal: '',
  startTimeLocal: '',
  startDateTimeUtc: '',
  startTimeUtc: '',
  duration: 0,
  broadcastStartedOnLocal: '',
  elapsedDuration: '',
  customLogoUrl: '',
  customColourHex: '#000000',
  resolution: 1080,
  signLanguageUrl: '',
  customBackgroundImageUrl: '',
  signLanguageQrCodeUrl: '',
  ingestKey: '',
  ingestUrl: '',
  paymentStatus: '',
};
